import { Message } from 'dg-web-shared/lib/Localized';
import { Localized } from '../../common/components/Localized';
import {
    CardItemBlock,
    PaymentMethodItemBlock,
} from '../root/components/ItemBlock';
import {
    CreditCardAlias,
    PaymentCardType,
} from 'dg-web-shared/model/PaymentAlias';
import { SelectedPaymentFlow } from '../../common/payment/Payment';
import { EPaymentWithSavedCCAlias } from './EPaymentWithSavedCCAlias';
import { SlideInPortalId } from '../root/components/PortalSlidein';
import { css } from '@emotion/css';
import { getBridge } from '../../Native';
import { ModalErrorBox } from '../../ui/modals/Confirmable';
import { useState } from 'react';
import { IconBig } from 'dg-web-shared/tb-ui/icons/IconBig.tsx';

function Method({
    title,
    icon,
    onClick,
}: {
    title: Message | string;
    icon?: string;
    onClick: () => void;
}) {
    if (typeof title === 'string') {
        title = {
            de: title,
            fr: title,
            it: title,
            en: title,
        };
    }
    return (
        <PaymentMethodItemBlock
            title={
                <span>
                    <Localized {...title} />
                </span>
            }
            onClick={onClick}
            icon={icon}
            iconIsBig
        />
    );
}

export function BankTransfer({ onClick }: { onClick: () => void }) {
    return (
        <Method
            title={{
                de: 'Bank-/Postüberweisung',
                fr: 'Virement bancaire/postal',
                it: 'Bonifico bancario/postale',
                en: 'Bank/post transfer',
            }}
            onClick={onClick}
        />
    );
}

export function OnlinePaymentSelection({
    currentAlias,
    onClick,
}: {
    currentAlias: CreditCardAlias | null;
    onClick: (p: SelectedPaymentFlow) => void;
}) {
    const canUseTwint = !isTwintSDKAvailable();
    const [openNeedsUpdateModal, setOpenNeedsUpdateModal] = useState(false);
    return (
        <>
            {currentAlias !== null && (
                <EPaymentWithSavedCCAlias
                    alias={currentAlias}
                    portal={SlideInPortalId.USER_ACCOUNT}
                    onClick={(p: SelectedPaymentFlow) => onClick(p)}
                />
            )}
            {currentAlias?.paymentMethod !== PaymentCardType.TWI && (
                <Twint
                    onClick={() => {
                        if (canUseTwint) {
                            onClick(SelectedPaymentFlow.TWINT);
                        } else {
                            setOpenNeedsUpdateModal(true);
                        }
                    }}
                />
            )}
            <Cards onClick={() => onClick(SelectedPaymentFlow.CREDIT_CARDS)} />
            {currentAlias?.paymentMethod !== PaymentCardType.PFC && (
                <Postfinance
                    onClick={() => onClick(SelectedPaymentFlow.POSTFINANCE)}
                />
            )}
            {openNeedsUpdateModal && (
                <PaymentCategoryTwintWithSDKErrorModal
                    onClose={() => setOpenNeedsUpdateModal(false)}
                />
            )}
        </>
    );
}

export function Cards({ onClick }: { onClick: () => void }) {
    return (
        <CardItemBlock
            title={
                <span>
                    <Localized de="Karten" fr="Cartes" it="Carte" en="Cards" />
                </span>
            }
            icons={
                <>
                    <div className={css({ marginRight: '5px' })}>
                        <IconBig icon={'vis'} />
                    </div>
                    <div className={css({ marginRight: '5px' })}>
                        <IconBig icon={'eca'} />
                    </div>
                    <IconBig icon={'amx'} />
                </>
            }
            onClick={onClick}
        />
    );
}

export function Twint({ onClick }: { onClick: () => void }) {
    return (
        <CardItemBlock
            title={<span>TWINT</span>}
            icons={<IconBig icon={'twi'} />}
            onClick={onClick}
        />
    );
}

export function Postfinance({ onClick }: { onClick: () => void }) {
    return (
        <CardItemBlock
            title={<span>Postfinance</span>}
            icons={<IconBig icon={'pfc'} />}
            onClick={onClick}
        />
    );
}

function isTwintSDKAvailable(): boolean {
    const bridge = getBridge();
    return (
        bridge !== null && (Boolean(bridge.twint) || Boolean(bridge.twintUoF))
    );
}

function PaymentCategoryTwintWithSDKErrorModal({
    onClose,
}: {
    onClose: () => void;
}) {
    return (
        <ModalErrorBox
            titleCaption={
                <Localized
                    de="Neue Version erforderlich"
                    fr="Nouvelle version nécessaire"
                    it="Nuova versione necessaria"
                    en="New version required"
                />
            }
            confirmCallback={onClose}
        >
            <div>
                <p>
                    <Localized
                        de="Aufgrund einer technischen Änderung ist die aktuell installierte Parkingpay-App nicht mehr in der Lage, den Wechsel zur TWINT-App korrekt durchzuführen."
                        fr="En raison d'un changement technique, l'app Parkingpay actuellement installée n'est plus en mesure de gérer correctement le passage à l'app TWINT."
                        it="A causa di un cambiamento tecnico, l’app Parkingpay attualmente installata non è più in grado di gestire correttamente il passaggio all’app TWINT."
                        en="Due to a technical change, the currently installed Parkingpay app is no longer able to handle the switch to the TWINT app correctly."
                    />
                </p>
                <p>
                    <Localized
                        de="Um TWINT als Zahlungsmittel nutzen zu können, "
                        fr="Pour pouvoir utiliser TWINT comme moyen de paiement, "
                        it="Per poter utilizzare TWINT come mezzo di pagamento "
                        en="In order to use TWINT as a means of payment, the "
                    />
                    <span className={css({ fontWeight: 'bold' })}>
                        <Localized
                            de="muss die Parkingpay-App aktualisiert werden"
                            fr="l'app Parkingpay doit être mise à jour"
                            it="è necessario aggiornare l’app Parkingpay"
                            en="Parkingpay app must be updated"
                        />
                    </span>
                    .
                </p>
            </div>
        </ModalErrorBox>
    );
}
