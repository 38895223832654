import { css } from '@emotion/css';

import { Updater, useStore, useUpdate } from 'dg-web-shared/lib/Flux';
import { nl2br } from 'dg-web-shared/lib/ReactHelpers';
import { BasicButton } from 'dg-web-shared/tb-ui/buttons/BasicButton.tsx';
import { LabeledText } from '../../ui/typo/LabeledText.tsx';
import { IndentedListParkingPay } from '../../common/components/IndentedListParkingPay';
import * as LayoutActions from '../../layout/actions/LayoutActions';
import { mobileNavigateAccount } from '../../layout/actions/LayoutActions';
import * as LayoutState from '../../layout/state/LayoutState';
import { ResponsiveProperties } from '../../layout/utils/ResponsiveProperties';
import * as ParkCreateActions from '../../park-create/actions/ParkCreateActions';
import * as EntitySelectionState from '../../park-create/state/EntitySelectionState';
import { logAction } from '../../utils/ActionLog';
import { Formatter } from '../../utils/Date';
import * as TransactionsListState from '../state/TransactionsListState';
import { PermitDetail } from '../state/TransactionsListState';
import { ButtonRow } from '../../park-create/components/license-plate/InfoBoxes';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter';
import { getPermitReceiptPdf } from '../../api/PermitHttp';
import { LicensePlateEntity } from './PemitEntities';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { LicensePlateDetailsSlidein } from './PermitLicensePlateDetails';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import {
    ButtonText,
    InlineAlertBox,
    InlineInfoBox,
    ModalQuestionBox,
} from '../../ui/modals/Confirmable';
import { VehicleAdd } from '../../account/vehicles/VehicleAdd';
import { IntradayValidityWeekProfileDisplay } from 'dg-web-shared/common/components/intraday-validity-week-profile-display/IntradayValidityWeekProfileDisplay';
import ReactMarkdown from 'react-markdown';
import { IndentedListData } from 'dg-web-shared/common/components/IndentedList';
import { usePermitPayloadContext } from '../../park-create/components/permit/PermitPayloadContext';
import { DateTime } from 'luxon';
import { UpdateVehicle } from '../../account/vehicles/VehicleState';
import { useVehicleListOpen } from '../../hooks/OpenVehicleList';
import { Localized } from '../../common/components/Localized';
import { EmDash } from 'dg-web-shared/lib/Punctuation';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';
import { SingleSelection } from '../../common/components/SingleSelection.tsx';

const renewPermit = (
    update: Updater,
    p: TransactionsListState.PermitDetail.Permit,
    setPermitPayloadFromDate: (date: DateTime) => void,
) => {
    update(store => {
        const zone =
            p.permitZones.length > 0 ? p.permitZones[0] : p.offstreetZones[0];

        ParkCreateActions.setCity(store, zone.zipCode);
        ParkCreateActions.setParkOption(store, zone.uniqueId);
        ParkCreateActions.parkVariantSelectPermit(store, true);

        EntitySelectionState.Selection.Permit.stateWrite(store, {
            selectedEntityIds: p.licensePlates.map(lp => lp.id),
        });

        setPermitPayloadFromDate(
            DateTime.max(
                p.isRenewableExact
                    ? p.validTo.plus({ minutes: 1 })
                    : p.validTo.plus({ days: 1 }).startOf('day'),
                DateTime.now(),
            ),
        );

        const responsiveProps = new ResponsiveProperties({
            layout: new LayoutState.StateSlice(store).state,
        });

        if (responsiveProps.mobile) {
            LayoutActions.mobileNavigatePark(store);
            TransactionsListState.Detail.stateWrite(store, { permitId: null });
        }

        return 'renew-licenseplate-permit';
    }, p);
};

const AdditionalInfo = ({ permit }: { permit: PermitDetail.Permit }) => {
    return (permit.additionalInfo && permit.additionalInfo.length > 0) ||
        !permit.permitIsChangeable ? (
        <LabeledText
            label={{
                de: 'Zusätzliche Informationen',
                fr: 'Informations complémentaires',
                it: 'Informazioni supplementari',
                en: 'Additional information',
            }}
        >
            {permit.additionalInfo && permit.additionalInfo.length > 0 && (
                <>
                    {nl2br(permit.additionalInfo)}
                    <br />
                </>
            )}
            {!permit.permitIsChangeable && (
                <Localized {...permit.operatorEditPolicyText} />
            )}
        </LabeledText>
    ) : null;
};

interface PermitZone {
    city: string;
    zipCode: string;
    name: string;
    extZoneId: number | null;
}

const mergePermitZones = (
    permit: TransactionsListState.PermitDetail.Permit,
): PermitZone[] =>
    permit.permitZones
        .map(
            pz =>
                ({
                    city: pz.city,
                    zipCode: pz.zipCode,
                    name: pz.name,
                    extZoneId: pz.extZoneId,
                }) as PermitZone,
        )
        .concat(
            permit.offstreetZones.map(oz => ({
                city: oz.city,
                zipCode: oz.zipCode,
                name: oz.name,
                extZoneId: null,
            })),
        );

function groupZones(
    permit: TransactionsListState.PermitDetail.Permit,
): IndentedListData {
    const mergedZones = mergePermitZones(permit);
    const groupedZones: IndentedListData = {};

    if (mergedZones.length === 0) {
        return {};
    }

    for (const zone of mergedZones) {
        const key = `${zone.zipCode} ${zone.city}`;
        const entries = groupedZones[key];
        const value = `${zone.extZoneId ? zone.extZoneId : ''} ${zone.name}`;

        if (entries) {
            entries.push(value);
        } else {
            groupedZones[key] = [value];
        }
    }

    return groupedZones;
}

function PermitZonesList({
    permit,
}: {
    permit: PermitDetail.Permit;
}): JSX.Element | null {
    return permit.permitZones.length === 0 &&
        permit.offstreetZones.length === 0 ? null : (
        <IndentedListParkingPay
            title={
                permit.permitZones.length === 1
                    ? {
                          de: 'Zone',
                          fr: 'Zone',
                          it: 'Zona',
                          en: 'Zone',
                      }
                    : {
                          de: 'Zonen',
                          fr: 'Zones',
                          it: 'Zone',
                          en: 'Zones',
                      }
            }
            entries={groupZones(permit)}
        />
    );
}

function Permit({
    permit,
    renewalIsOkInCurrentContext,
}: {
    permit: TransactionsListState.PermitDetail.Permit;
    renewalIsOkInCurrentContext: boolean;
}) {
    const update = useUpdate();
    const [missingLpOpen, setMissingLpOpen] = useState(false);
    const [addVehicleOpen, setAddVehicleOpen] = useState(false);
    const { setPayload, setSelectedPermitTypeId } = usePermitPayloadContext();
    const missingLp = permit.missingLpsForRenewal[0];

    function setPermitFromDateForRenewal(date: DateTime) {
        setSelectedPermitTypeId(permit.permitType.id);
        setPayload(p => ({
            ...p,
            fromDate: date,
            preventAutoOpen: true,
        }));
    }

    return (
        <div>
            <div className={css({ padding: '16px 24px' })}>
                {permit.minimalBalanceWarning?.showMinimalBalanceWarning && (
                    <MinimalBalanceWarningBox
                        minimalBalanceWarning={permit.minimalBalanceWarning}
                    />
                )}
                {permit.showMissingVehicleWarning && (
                    <VehicleMissingWarningBox />
                )}
                {permit.showMissingBadgeWarning &&
                    (permit.badgeRequired ? (
                        <BadgeMissingWarningBox />
                    ) : (
                        <BadgeMissingInfoBox />
                    ))}
                <LabeledText
                    label={{
                        de: 'Bewilligung',
                        fr: 'Autorisation',
                        it: 'Autorizzazione',
                        en: 'Permit',
                    }}
                >
                    {permit.permitType.description}
                </LabeledText>
                {permit.explicitCheckinInfo && (
                    <ExplicitCheckinInfo
                        explicitCheckinInfo={permit.explicitCheckinInfo}
                    />
                )}
                <AdditionalInfo permit={permit} />
                {permit.licensePlates.length > 0 && (
                    <LicensePlateList permit={permit} />
                )}
                {permit.badges.length > 0 && (
                    <LabeledText
                        label={
                            permit.badges.length === 1
                                ? {
                                      de: 'Badge',
                                      fr: 'Badge',
                                      it: 'Badge',
                                      en: 'Badge',
                                  }
                                : {
                                      de: 'Badges',
                                      fr: 'Badges',
                                      it: 'Badges',
                                      en: 'Badges',
                                  }
                        }
                    >
                        {permit.badges.map(b => b.labelNr).join(', ')}
                    </LabeledText>
                )}
                <LabeledText
                    label={{
                        de: 'Beginn',
                        fr: 'Début',
                        it: 'Inizio',
                        en: 'Begin',
                    }}
                >
                    {permit.intradayValidityWeekProfile === null
                        ? Formatter.dayMonthYearHourMinute(permit.validFrom)
                        : Formatter.dayMonthYear(permit.validFrom)}
                </LabeledText>
                <LabeledText
                    label={{
                        de: 'Ende',
                        fr: 'Fin',
                        it: 'Fine',
                        en: 'End',
                    }}
                >
                    {permit.intradayValidityWeekProfile === null
                        ? Formatter.dayMonthYearHourMinute(permit.validTo)
                        : Formatter.dayMonthYear(permit.validTo)}

                    {renewalIsOkInCurrentContext && permit.isRenewable && (
                        <div className={css({ float: 'right' })}>
                            <BasicButton
                                negative={true}
                                label={
                                    <Localized
                                        de="Erneuern"
                                        fr="Renouveler"
                                        it="Rinnova"
                                        en="Renew"
                                    />
                                }
                                onClick={(): void => {
                                    if (
                                        permit.missingLpsForRenewal.length > 0
                                    ) {
                                        setMissingLpOpen(true);
                                    } else {
                                        update(store =>
                                            logAction(
                                                store,
                                                'renew-permit',
                                                permit.id,
                                            ),
                                        );
                                        renewPermit(
                                            update,
                                            permit,
                                            setPermitFromDateForRenewal,
                                        );
                                    }
                                }}
                            />
                        </div>
                    )}
                </LabeledText>

                {missingLpOpen && (
                    <AddMissingLpModal
                        missingLpsForRenewal={permit.missingLpsForRenewal}
                        setMissingLpOpen={setMissingLpOpen}
                        setAddVehicleOpen={setAddVehicleOpen}
                    />
                )}

                <VehicleAdd
                    portal={SlideInPortalId.USER_ACCOUNT}
                    open={addVehicleOpen}
                    title={
                        <Localized
                            de="Fahrzeug hinzufügen"
                            fr="Ajouter véhicule"
                            it="Aggiungi veicolo"
                            en="Add vehicle"
                        />
                    }
                    onClose={() => {
                        update(store => {
                            UpdateVehicle.reset(store);
                            return 'reset-vehicle';
                        });
                        setAddVehicleOpen(false);
                    }}
                    onSuccess={() => {
                        setAddVehicleOpen(false);
                        update(store =>
                            TransactionsListState.PermitDetail.refetchSameContext(
                                store,
                                true,
                            ),
                        );
                    }}
                    country={missingLp?.country}
                    type={missingLp?.type}
                    licensePlateNr={missingLp?.licensePlateNr}
                />

                {permit.intradayValidityWeekProfile && (
                    <LabeledText
                        label={{
                            de: 'Tägliche Gültigkeit',
                            fr: 'Validité journalière',
                            it: 'Validità giornaliera',
                            en: 'Daily validity',
                        }}
                    >
                        <IntradayValidityWeekProfileDisplay
                            localizedComponent={Localized}
                            weekProfile={permit.intradayValidityWeekProfile}
                        />
                    </LabeledText>
                )}
                <LabeledText
                    label={{
                        de: 'Preis',
                        fr: 'Prix',
                        it: 'Prezzo',
                        en: 'Price',
                    }}
                >
                    {numberToPrice(permit.price)}
                </LabeledText>
                {PermitZonesList({ permit })}
                <LabeledText
                    label={{
                        de: 'Bewilligungs-Nr.',
                        fr: 'N° autorisation',
                        it: 'Nr. autorizzazione',
                        en: 'Permit number',
                    }}
                >
                    {permit.id}
                </LabeledText>
                <LabeledText
                    label={{
                        de: 'Betreiber',
                        fr: 'Exploitant',
                        it: 'Gestore',
                        en: 'Operator',
                    }}
                >
                    {permit.operatorName}

                    {permit.operatorContactDataMarkdown &&
                        permit.permitIsChangeable && (
                            <ReactMarkdown
                                className={css({
                                    whiteSpace: 'pre-wrap',
                                })}
                            >
                                {permit.operatorContactDataMarkdown}
                            </ReactMarkdown>
                        )}
                </LabeledText>
                {permit.permitIsChangeable && (
                    <LabeledText label="">
                        <Localized {...permit.operatorEditPolicyText} />
                    </LabeledText>
                )}
                {permit.paymentChannel ===
                    TransactionsListState.PermitDetail.PaymentChannel
                        .PARKINGPAY && (
                    <ButtonRow>
                        <a
                            target="_blank"
                            href={getPermitReceiptPdf({
                                id: permit.id,
                                key: permit.urlKey,
                            })}
                            rel="noreferrer"
                        >
                            <BasicButton
                                negative={true}
                                label={
                                    <Localized
                                        de="Beleg anzeigen"
                                        fr="Afficher document"
                                        it="Mostra documento"
                                        en="Show document"
                                    />
                                }
                                onClick={() => {
                                    /* do nothing */
                                }}
                            />
                        </a>
                    </ButtonRow>
                )}
            </div>
        </div>
    );
}

const AddMissingLpModal = ({
    missingLpsForRenewal,
    setMissingLpOpen,
    setAddVehicleOpen,
}: {
    missingLpsForRenewal: TransactionsListState.LicensePlateDTO[];
    setMissingLpOpen: (value: boolean) => void;
    setAddVehicleOpen: (value: boolean) => void;
}) => {
    const missingLpString = missingLpsForRenewal
        .map(missingLp => missingLp.licensePlateNr)
        .join(', ');
    return (
        <ModalQuestionBox
            titleCaption={
                missingLpsForRenewal.length === 1 ? (
                    <Localized
                        de="Neues Fahrzeug"
                        fr="Nouveau véhicule"
                        it="Nuovo veicolo"
                        en="New vehicle"
                    />
                ) : (
                    <Localized
                        de="Neue Fahrzeuge"
                        fr="Nouveaux véhicules"
                        it="Nuovi veicoli"
                        en="New vehicles"
                    />
                )
            }
            confirmCaption={ButtonText.ADD}
            confirmCallback={() => {
                setMissingLpOpen(false);
                setAddVehicleOpen(true);
            }}
            cancelCaption={ButtonText.CANCEL}
            cancelCallback={() => {
                setMissingLpOpen(false);
            }}
        >
            <p>
                {missingLpsForRenewal.length === 1 ? (
                    <Localized
                        de={`Um diese Bewilligung zu erneuern, müssen Sie das Fahrzeug ${missingLpString} zuerst zu Ihrem Konto hinzufügen.`}
                        fr={`Pour renouveler cette autorisation, vous devez d'abord ajouter le véhicule ${missingLpString} à votre compte.`}
                        it={`Per poter rinnovare quest'autorizzazione deve prima aggiungere il veicolo ${missingLpString} nel suo conto.`}
                        en={`In order to renew this permit you must first add the vehicle ${missingLpString} to your account.`}
                    />
                ) : (
                    <Localized
                        de={`Um diese Bewilligung zu erneuern, müssen Sie die Fahrzeuge ${missingLpString} zuerst zu Ihrem Konto hinzufügen.`}
                        fr={`Pour renouveler cette autorisation, vous devez d'abord ajouter les véhicules ${missingLpString} à votre compte.`}
                        it={`Per poter rinnovare quest'autorizzazione deve prima aggiungere i veicoli ${missingLpString} nel suo conto.`}
                        en={`In order to renew this permit you must first add the vehicles ${missingLpString} to your account.`}
                    />
                )}
            </p>
        </ModalQuestionBox>
    );
};

const ExplicitCheckinInfo = ({
    explicitCheckinInfo,
}: {
    explicitCheckinInfo: PermitDetail.ExplicitCheckinInfo;
}) => {
    return (
        <>
            <LabeledText
                label={{
                    de: 'Einschränkungen',
                    fr: 'Restrictions',
                    it: 'Restrizioni',
                    en: 'Restrictions',
                }}
            >
                <Localized
                    de="Diese Bewilligung ist nur dann gültig, wenn sie aktiviert wird."
                    fr="Cette autorisation n'est valable que si elle est activée."
                    it="Questa autorizzazione è valida solo quando viene attivata."
                    en="This permit is only valid if it is activated."
                />
            </LabeledText>
            {explicitCheckinInfo.usages && (
                <LabeledText
                    label={{
                        de: 'Aktivierungen',
                        fr: 'Activations',
                        it: `Attivazioni`,
                        en: 'Activations',
                    }}
                >
                    {explicitCheckinInfo.usages.usedCount}{' '}
                    {explicitCheckinInfo.usages.usedCount === 1 ? (
                        <Localized
                            de="verbraucht"
                            fr="utilisée"
                            it="utilizzata"
                            en="used"
                        />
                    ) : (
                        <Localized
                            de="verbrauchte"
                            fr="utilisées"
                            it="utilizzate"
                            en="used"
                        />
                    )}
                    {' (max. '}
                    {explicitCheckinInfo.usages.usageLimit}
                    {')'}
                </LabeledText>
            )}
        </>
    );
};

function LicensePlateList({ permit }: { permit: PermitDetail.Permit }) {
    const { storeState, update } = useStore(s => ({
        isMobile: new ResponsiveProperties({
            layout: new LayoutState.StateSlice(s).state,
        }).mobile,
    }));
    const { setListOpen } = useVehicleListOpen();
    if (permit.explicitCheckinInfo && permit.isValid) {
        const activeLps = permit.licensePlates.filter(
            lp => lp.explicitCheckin?.running,
        );
        const inactiveLps = permit.licensePlates.filter(
            lp => !lp.explicitCheckin?.running,
        );
        return (
            <>
                <LabeledText
                    label={{
                        de: 'Aktive Fahrzeuge',
                        fr: 'Véhicules actifs',
                        it: 'Veicoli attivi',
                        en: 'Active vehicles',
                    }}
                    rightText={
                        permit.explicitCheckinInfo.maxConcurrentCheckins
                            ? `${activeLps.length} / ${permit.explicitCheckinInfo.maxConcurrentCheckins}`
                            : undefined
                    }
                >
                    {activeLps.length === 0
                        ? EmDash
                        : activeLps.map(lp => {
                              return (
                                  <LicensePlateSingleSelection
                                      key={lp.id}
                                      licensePlate={lp}
                                      permit={permit}
                                  />
                              );
                          })}
                </LabeledText>
                <LabeledText
                    label={{
                        de: 'Inaktive Fahrzeuge',
                        fr: 'Véhicules inactifs',
                        it: 'Veicoli inattivi',
                        en: 'Inactive vehicles',
                    }}
                >
                    {inactiveLps.length === 0
                        ? EmDash
                        : inactiveLps.map(lp => {
                              return (
                                  <LicensePlateSingleSelection
                                      key={lp.id}
                                      licensePlate={lp}
                                      permit={permit}
                                  />
                              );
                          })}
                </LabeledText>
            </>
        );
    } else {
        return (
            <LabeledText
                label={{
                    de: 'Fahrzeuge',
                    fr: 'Véhicules',
                    it: 'Veicoli',
                    en: 'Vehicles',
                }}
            >
                {permit.licensePlates.map(lp => {
                    return (
                        <SingleSelection
                            key={lp.id}
                            labelText={''}
                            onClick={() => {
                                if (storeState.isMobile) {
                                    update(mobileNavigateAccount);
                                }
                                setListOpen(true);
                            }}
                            readOnly={
                                !lp.isMissingBadgeButRequired &&
                                !lp.isMissingVehicleButRequired
                            }
                            selection={
                                <LicensePlateEntity
                                    licensePlate={lp}
                                    permit={permit}
                                />
                            }
                            context={InputContext.regular}
                        />
                    );
                })}
            </LabeledText>
        );
    }
}

function LicensePlateSingleSelection({
    licensePlate,
    permit,
}: {
    licensePlate: PermitDetail.PermitDetailLicensePlate;
    permit: PermitDetail.Permit;
}) {
    const [licensePlateDetailsOpen, setLicensePlateDetailsOpen] =
        useState(false);
    return (
        <>
            <SingleSelection
                labelText={''}
                onClick={() => setLicensePlateDetailsOpen(true)}
                readOnly={!licensePlate.explicitCheckin}
                selection={
                    <LicensePlateEntity
                        licensePlate={licensePlate}
                        permit={permit}
                    />
                }
                context={InputContext.regular}
            />
            <LicensePlateDetailsSlidein
                open={licensePlateDetailsOpen}
                licensePlate={licensePlate}
                permitId={permit.id}
                portal={SlideInPortalId.TRANSACTION_LIST}
                title={
                    <Localized
                        de="Fahrzeug"
                        fr="Véhicules"
                        it="Veicolo"
                        en="Vehicle"
                    />
                }
                onClose={() => {
                    setLicensePlateDetailsOpen(false);
                }}
            />
        </>
    );
}

export function PermitDetailFetching({
    permitDetail,
    renewalIsOkInCurrentContext,
}: {
    permitDetail: TransactionsListState.PermitDetail.State;
    renewalIsOkInCurrentContext: boolean;
}) {
    return permitDetail.pending || !permitDetail.data ? (
        <Spinner />
    ) : (
        <Permit
            permit={permitDetail.data}
            renewalIsOkInCurrentContext={renewalIsOkInCurrentContext}
        />
    );
}

function MinimalBalanceWarningBox({
    minimalBalanceWarning,
}: {
    minimalBalanceWarning: TransactionsListState.PermitDetail.MinimalBalanceWarning;
}) {
    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Missing payment mean"
                />
            }
        >
            <p>
                <Localized
                    de="Ohne gültiges Zahlungsmittel können Sie mit dieser Bewilligung nicht parkieren."
                    fr="Vous ne pouvez pas stationner avec cette autorisation sans moyen de paiement valable."
                    it="Non può parcheggiare con questa autorizzazione senza un mezzo di pagamento valido."
                    en="You cannot park with this permit without a valid means of payment."
                />
            </p>
            {minimalBalanceWarning.isPrepaid && (
                <p>
                    <Localized
                        de={`Sie müssen mindestens CHF ${minimalBalanceWarning.minimalEntryAmount} auf das Konto laden oder ein Zahlungsmittel hinterlegen.`}
                        fr={`Vous devez charger au moins ${minimalBalanceWarning.minimalEntryAmount} CHF sur le compte ou enregistrer un moyen de paiement.`}
                        it={`Deve caricare almeno CHF ${minimalBalanceWarning.minimalEntryAmount} sul conto oppure registrare un mezzo di pagamento.`}
                        en={`You must load at least CHF ${minimalBalanceWarning.minimalEntryAmount} on the account or register a means of payment.`}
                    />
                </p>
            )}
            <p>
                <Localized
                    de="Das Zahlungsmittel wird nur verwendet, wenn der Parkvorgang über die Gültigkeit dieser Bewilligung hinausgeht."
                    fr="Le moyen de paiement n'est utilisé que si le stationnement dépasse la validité de cette autorisation."
                    it="Il mezzo di pagamento viene utilizzato solo nel caso in cui la sosta superi la validità di questa autorizzazione."
                    en="The means of payment will only be used if the parking transaction exceeds the validity of this permit."
                />
            </p>
        </InlineAlertBox>
    );
}

function VehicleMissingWarningBox() {
    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Fahrzeug fehlt"
                    fr="Véhicule manquant"
                    it="Veicolo mancante"
                    en="Vehicle missing"
                />
            }
        >
            <p>
                <Localized
                    de="Damit die Bewilligung richtig funktioniert, müssen die Fahrzeuge auch in Ihrem Parkingpay-Konto registriert sein."
                    fr="Pour que l'autorisation fonctionne correctement, les véhicules doivent également être enregistrés dans votre compte Parkingpay."
                    it="Affinché l'autorizzazione funzioni correttamente, i veicoli devono anche essere registrati nel suo conto Parkingpay."
                    en="For the permit to work properly, the vehicles must also be registered in your Parkingpay account."
                />
            </p>
            <p>
                <Localized
                    de="Klicken Sie unten auf das entsprechende Fahrzeug, um sich zu registrieren."
                    fr="Cliquez ci-dessous sur le véhicule correspondant pour l'enregistrer."
                    it="Clicchi qui sotto sul relativo veicolo per registrarlo."
                    en="Click on the corresponding vehicle below to register it."
                />
            </p>
        </InlineAlertBox>
    );
}

function BadgeMissingWarningBox() {
    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Badge fehlt"
                    fr="Badge manquant"
                    it="Badge mancante"
                    en="Badge missing"
                />
            }
        >
            <p>
                <Localized
                    de="Damit die Bewilligung richtig funktioniert, muss jedem Fahrzeug ein Badge zugewiesen werden."
                    fr="Pour que l'autorisation fonctionne correctement, un badge doit être attribué à chaque véhicule."
                    it="Affinché l'autorizzazione funzioni correttamente, ad ogni veicolo deve essere assegnato un badge."
                    en="For the permit to work properly, each vehicle must be assigned a badge."
                />
            </p>
            <p>
                <Localized
                    de="Klicken Sie unten auf das entsprechende Fahrzeug, um einen Badge hinzuzufügen."
                    fr="Cliquez ci-dessous sur le véhicule correspondant pour ajouter un badge."
                    it="Clicchi qui sotto sul relativo veicolo per aggiungere un badge."
                    en="Click on the corresponding vehicle below to register it."
                />
            </p>
        </InlineAlertBox>
    );
}

function BadgeMissingInfoBox() {
    return (
        <InlineInfoBox
            titleCaption={
                <Localized
                    de="Badge als Backup"
                    fr="Badge comme alternative"
                    it="Badge come alternativa"
                    en="Badge as a backup"
                />
            }
        >
            <p>
                <Localized
                    de="Falls die Kennzeichenerkennung nicht funktionieren sollte, haben Sie die Möglichkeit mit dem Badge sorgenlos ein- und auszufahren."
                    fr="Si la reconnaissance des plaques d'immatriculation ne devait pas fonctionner, vous auriez la possibilité d'entrer et de sortir sans souci avec le badge."
                    it="Se il riconoscimento della targa non dovesse funzionare, avrebbe la possibilità di entrare e uscire senza problemi con il badge."
                    en="If the license plate recognition should not work, you have the option to drive in and out carefree with the badge."
                />
            </p>
            <p>
                <Localized
                    de="Klicken Sie unten auf das entsprechende Fahrzeug, um einen Badge hinzuzufügen."
                    fr="Cliquez ci-dessous sur le véhicule correspondant pour ajouter un badge."
                    it="Clicchi qui sotto sul relativo veicolo per aggiungere un badge."
                    en="Click on the corresponding vehicle below to add a badge."
                />
            </p>
        </InlineInfoBox>
    );
}
