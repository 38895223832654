import * as superagent from 'superagent';
import * as Flux from './Flux';
import * as HttpResponse from './HttpResponse';
import { Response, StatusCode } from './HttpResponse';
import { Maybe } from './MaybeV2';

type ServerData<D> = Maybe<D | D[]>;

export interface BaseAbstractLegacyServerState {
    pending: boolean;
    statusCode: StatusCode;
}

export interface AbstractLegacyServerState<D>
    extends BaseAbstractLegacyServerState {
    shouldFetch: boolean;
    data: ServerData<D>;
    attempts: number;
    request: Maybe<superagent.Request<object, object>> | null;
    lastSuccessfulFetch: number;
}

const refreshIdsStore: { [idx: string]: NodeJS.Timeout[] } = {};

export abstract class AbstractLegacyServerStateSlice<
    S extends AbstractLegacyServerState<D>,
    D,
> extends Flux.StateSlice<S> {
    constructor(store: Flux.Store) {
        super(store);
        this.initializeRefreshIds();
    }

    get refreshIds(): NodeJS.Timeout[] {
        return refreshIdsStore[this.key()] || [];
    }

    set refreshIds(ids: NodeJS.Timeout[]) {
        refreshIdsStore[this.key()] = ids;
    }

    abstract getEmptyData(): ServerData<D>;

    abstract getInitialState(): S;

    reset(): void {
        if (this.state.request) {
            this.state.request.abort();
        }
        this.clearTimeouts();
        super.reset();
    }

    writeRequest(res: Response): void {
        this.clearTimeouts();
        if (res.pending) {
            this.writePending(res.request);
        } else if (res.statusCode.cls.success) {
            this.writeSuccess(res);
            if (this.getRefreshIntervalSecs() !== 0) {
                this.refreshIds.push(
                    setTimeout(() => {
                        const timeSinceLastFetch =
                            new Date().getTime() -
                            this.state.lastSuccessfulFetch;
                        if (
                            timeSinceLastFetch >
                                this.getRefreshIntervalSecs() &&
                            !this.state.pending
                        ) {
                            this.set((s: S): S => {
                                s.shouldFetch = true;
                                return s;
                            });
                        }
                        // access state to trigger refetch
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        this.state;
                    }, this.getRefreshIntervalSecs() * 1000),
                );
            }
        } else {
            const s = res.statusCode;
            this.writeError(res, s.timeout);
        }
    }

    protected writePending(
        request: Maybe<superagent.Request<object, object>>,
    ): void {
        this.set((s: S): S => {
            if (s.pending && s.request) {
                s.request.abort();
            }
            s.pending = true;
            s.shouldFetch = false;
            s.statusCode = new HttpResponse.StatusCode(null);
            s.request = request;
            return s;
        });
    }

    protected writeSuccess(res: HttpResponse.Response): void {
        this.set((s: S): S => {
            s.pending = false;
            s.shouldFetch = false;
            s.attempts = 0;
            s.statusCode = res.statusCode;
            s.lastSuccessfulFetch = new Date().getTime();
            return s;
        });
    }

    protected writeError(res: HttpResponse.Response, retry: boolean): void {
        this.set((s: S): S => {
            s.pending = false;
            s.shouldFetch = retry ? s.attempts <= 3 : false;
            s.attempts += 1;
            s.data = this.getEmptyData();
            s.statusCode = res.statusCode;
            return s;
        });
    }

    protected getRefreshIntervalSecs(): number {
        return 0;
    }

    private initializeRefreshIds(): void {
        if (!refreshIdsStore[this.key()]) {
            this.refreshIds = [];
        }
    }

    private clearTimeouts(): void {
        if (this.refreshIds.length > 0) {
            this.refreshIds.forEach(id => clearTimeout(id));
            this.refreshIds = [];
        }
    }
}
