export const enum QrScanActionType {
    SCAN_ZONE_URL = 'scan-zone-url',
    SCAN_PERMIT_URL = 'scan-permit-url',
    SCAN_TICKET_URL = 'scan-ticket-url',
    SCAN_RECEIPT_URL = 'scan-receipt-url',
    SCAN_UNKNOWN = 'scan-unknown',
    PARSING_FAILED = 'parsing-failed',
}

const allowedPathPatterns = [
    {
        pattern: /^\/z\/[A-Z2-7]+$/,
        action: QrScanActionType.SCAN_ZONE_URL,
    },
    {
        pattern: /^\/p\/[A-Z2-7]+$/,
        action: QrScanActionType.SCAN_PERMIT_URL,
    },
    {
        pattern: /^\/t\/[A-Za-z0-9-_=]+$/,
        action: QrScanActionType.SCAN_TICKET_URL,
    },
    {
        pattern: /^\/ui-api\/pay-station\/payment\/[A-Fa-f0-9-]$/,
        action: QrScanActionType.SCAN_RECEIPT_URL,
    },
];

type ParsingFailed = {
    action: QrScanActionType.PARSING_FAILED;
};

type QrScanUrlAction = {
    url: URL;
    action: QrScanActionType;
};

type QrScanAction = ParsingFailed | QrScanUrlAction;

export function findMatchingUrlAction(message: string): QrScanAction {
    let url: URL;
    try {
        url = new URL(message);
    } catch {
        return {
            action: QrScanActionType.PARSING_FAILED,
        };
    }
    const matchingAction = allowedPathPatterns.find(
        ({ pattern }) =>
            isValidParkingpayHost(url.host) &&
            url.pathname.match(pattern) != null,
    )?.action;

    if (matchingAction) {
        return {
            url: url,
            action: matchingAction,
        };
    }
    return {
        url: url,
        action: QrScanActionType.SCAN_UNKNOWN,
    };
}

function isValidParkingpayHost(host: string) {
    const prodValidHostsPattern = /^(www\.)?parkingpay\.ch$/;
    if (window.location.host.match(prodValidHostsPattern) != null) {
        return host.match(prodValidHostsPattern) !== null;
    } else {
        return (
            host.match(
                /^(www\.)?parkingpay\.ch$|^demo\.parkingpay\.ch$|^customer\.test\.digitalparking\.ch$|^customer\.([\w-]+)\.digitalparking\.dev$/,
            ) != null
        );
    }
}
