import { useEffect, useState } from 'react';
import {
    generateState,
    Store,
    useStore,
    useUpdate,
} from 'dg-web-shared/lib/Flux';
import { ModalErrorBox, ModalSuccessBox } from '../../ui/modals/Confirmable';
import { Localized } from './Localized';
import { PaymentProviderType, ResponseParameters } from '../payment/Payment';
import { logAction } from '../../utils/ActionLog';
import * as PaymentTypeState from '../state/PaymentTypeState';
import { SavedCreditCardState } from '../../account/shared/SavedCreditCardAliasState';
import { UiPaymentOrigin } from 'dg-web-shared/common/utils/TwintPairingParkingpay';
import * as Url from 'dg-web-shared/lib/Url';
import * as LayoutActions from '../../layout/actions/LayoutActions';
import {
    navigateToPermitPurchaseConfirmation,
    refreshStateSlicesOnSuccessfulPermitPurchase,
} from '../../park-create/actions/PermitActions';
import { useNavigate } from 'react-router-dom';
import { twintAbortRequest } from '../../account/root/components/TwintStateComponent';
import {
    abortTwintTransaction,
    PendingTwintPairing,
} from 'dg-web-shared/common/utils/TwintPairing';
import { useParams } from 'react-router';
import { TicketRoutes } from '../../park-create/ticket/TicketRoutes';
import TwintOrderMethodType = PendingTwintPairing.TwintOrderMethodType;

export enum PaymentStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
    CANCELED = 'canceled',
}

export namespace PaymentCallbackState {
    export interface State {
        permitId: number | null;
        status: PaymentStatus | null;
        pmethod: string | null;
        uiPaymentOrigin: UiPaymentOrigin | null;
        providerType: PaymentProviderType | null;
        reference: string | null;
        ticketString: string | null;
    }

    const generated = generateState<State>('payment-callback-state', {
        permitId: null,
        status: null,
        pmethod: null,
        uiPaymentOrigin: null,
        providerType: null,
        reference: null,
        ticketString: null,
    });

    export const get = generated.get;
    export const reset = generated.reset;
    export const stateWrite = generated.stateWrite;
    export const setCallbackState = (
        store: Store,
        params: ResponseParameters,
    ): string => {
        const state = {
            permitId: params.permitId || null,
            status: params.status,
            pmethod: params.pmethod,
            uiPaymentOrigin: params.uiPaymentOrigin,
            providerType: params.providerType || PaymentProviderType.DATATRANS,
            reference: params.reference || null,
            ticketString: params.ticketString || null,
        };
        logAction(store, 'payment-callback', state);
        return generated.stateWrite(store, state);
    };
}

export function DatatransCallback() {
    const rawParams = window.location.search.slice(1);
    const params = Url.getQueryParameters<ResponseParameters>(rawParams);
    const navigate = useNavigate();
    const { store } = useStore(() => null);

    useEffect(() => {
        navigate('/');
    }, []);

    PaymentCallbackState.setCallbackState(store, params);
    const ePaymentCallback = PaymentCallbackState.get(store);

    const originatesFromUserAccountView =
        ePaymentCallback.uiPaymentOrigin === UiPaymentOrigin.TOPUP_FROM_ACCOUNT;

    if (originatesFromUserAccountView) {
        LayoutActions.mobileNavigateAccount(store);
    } else if (ePaymentCallback.permitId) {
        refreshStateSlicesOnSuccessfulPermitPurchase(store);
        navigateToPermitPurchaseConfirmation(
            store,
            ePaymentCallback.permitId,
            true,
        );
    }

    return null;
}

function useTwintParams(): {
    mode: TwintOrderMethodType;
    twintPaymentAttemptId: number | null;
} {
    const params = useParams();

    const mode = getModeFromParam(params.mode);
    const twintPaymentAttemptId = params.twintPaymentAttemptId
        ? parseInt(params.twintPaymentAttemptId, 10)
        : null;

    return { mode, twintPaymentAttemptId };
}

export function TwintPairingCallback() {
    const { mode, twintPaymentAttemptId } = useTwintParams();
    const navigate = useNavigate();
    const update = useUpdate();

    useEffect(() => {
        update(store => {
            PendingTwintPairing.set(store, () => ({
                pairingToken: null,
                paymentAttemptId: twintPaymentAttemptId,
                clientState: null,
                mode: mode,
            }));
            return 'set-twint-token';
        });

        navigate('/');
    }, [mode, twintPaymentAttemptId]);

    return null;
}

export function TwintAbortCallback() {
    const { mode, twintPaymentAttemptId } = useTwintParams();
    const navigate = useNavigate();
    const { store } = useStore(() => null);

    useEffect(() => {
        PaymentCallbackState.setCallbackState(store, {
            permitId: null,
            pmethod: null,
            status: null,
            uiPaymentOrigin: null,
            mode,
            twintPaymentAttemptId,
        });

        abortTwintTransaction(
            twintAbortRequest(null, twintPaymentAttemptId, mode),
        );

        navigate('/');
    }, [mode, twintPaymentAttemptId]);

    return null;
}

function getModeFromParam(
    modeParam: string | undefined,
): PendingTwintPairing.TwintOrderMethodType {
    switch (modeParam?.toLowerCase()) {
        case 'order':
            return PendingTwintPairing.TwintOrderMethodType.ORDER;
        case 'combined':
            return PendingTwintPairing.TwintOrderMethodType.COMBINED;
        case 'uof':
            return PendingTwintPairing.TwintOrderMethodType.UOF;
        default:
            throw new Error('Mode param was not set in twint abort callback');
    }
}

export function PaymentCallback() {
    const navigate = useNavigate();
    const { storeState, update } = useStore(store => ({
        paymentCallback: PaymentCallbackState.get(store),
    }));
    const [successClosed, setSuccessClosed] = useState(false);
    const pcCb = storeState.paymentCallback;

    function onClose() {
        setSuccessClosed(true);
        update(PaymentCallbackState.reset);
    }

    useEffect(() => {
        if (
            pcCb.uiPaymentOrigin === UiPaymentOrigin.TOPUP_FROM_TICKET ||
            pcCb.uiPaymentOrigin === UiPaymentOrigin.STORE_ALIAS_FROM_TICKET
        ) {
            if (pcCb.status === PaymentStatus.SUCCESS) {
                if (
                    pcCb.uiPaymentOrigin === UiPaymentOrigin.TOPUP_FROM_TICKET
                ) {
                    if (pcCb.ticketString) {
                        navigate(
                            `/ticket/${pcCb.ticketString}/${TicketRoutes.topupActivationSuccessful}`,
                        );
                    }
                } else {
                    if (pcCb.ticketString) {
                        navigate(
                            `/ticket/${pcCb.ticketString}/${TicketRoutes.aliasActivationSuccessful}`,
                        );
                    }
                }
            } else {
                if (pcCb.ticketString) {
                    navigate(
                        `/ticket/${pcCb.ticketString}/${TicketRoutes.activationFailed}`,
                    );
                }
            }
            onClose();
        }
        if (
            pcCb.uiPaymentOrigin === UiPaymentOrigin.ACTIVATE_ALIAS_FROM_TICKET
        ) {
            if (pcCb.ticketString) {
                navigate(
                    `/ticket/${pcCb.ticketString}/${TicketRoutes.aliasActivationSuccessful}`,
                );
            }
            onClose();
        }
    }, [pcCb.status, pcCb.uiPaymentOrigin, pcCb.ticketString]);

    useEffect(() => {
        if (successClosed) {
            update(SavedCreditCardState.refetch);
            update(s => {
                new PaymentTypeState.StateSlice(s).reset();
                logAction(s, 'set-payment-method', 'DIRECT');
                return 'log-and-reset-payment-type-state';
            });
        }
    }, [successClosed]);

    if (successClosed) {
        return null;
    }

    switch (pcCb.status) {
        case PaymentStatus.PENDING:
            return null; // this was for wallee
        case PaymentStatus.SUCCESS:
            switch (pcCb.uiPaymentOrigin) {
                case UiPaymentOrigin.TOPUP_FROM_PERMIT:
                    return null;
                case UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT:
                case UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN:
                    return <StoreAliasSuccess onClose={onClose} />;
                case UiPaymentOrigin.TOPUP_FROM_TICKET:
                case UiPaymentOrigin.STORE_ALIAS_FROM_TICKET:
                case UiPaymentOrigin.ACTIVATE_ALIAS_FROM_TICKET:
                    return null;
                case UiPaymentOrigin.TOPUP_FROM_ACCOUNT:
                case UiPaymentOrigin.TOPUP_FROM_CHECKIN:
                    return <TopUpSuccess onClose={onClose} />;
                case UiPaymentOrigin.ACTIVATE_ALIAS_FROM_TOPUP:
                    return <SwitchToDirectPayment onClose={onClose} />;
                default:
                    return null;
            }
        case PaymentStatus.CANCELED:
        case PaymentStatus.FAILED:
            switch (pcCb.uiPaymentOrigin) {
                case UiPaymentOrigin.STORE_ALIAS_FROM_ACCOUNT:
                case UiPaymentOrigin.STORE_ALIAS_FROM_CHECKIN:
                    return (
                        <StoreAliasError
                            depositCallback={pcCb}
                            onClose={onClose}
                        />
                    );
                case UiPaymentOrigin.STORE_ALIAS_FROM_TICKET:
                case UiPaymentOrigin.TOPUP_FROM_TICKET:
                    return null;
                case UiPaymentOrigin.TOPUP_FROM_ACCOUNT:
                case UiPaymentOrigin.TOPUP_FROM_CHECKIN:
                case UiPaymentOrigin.TOPUP_FROM_PERMIT:
                    return (
                        <TopUpError depositCallback={pcCb} onClose={onClose} />
                    );
                default:
                    return null;
            }
        default:
            return null;
    }
}

function StoreAliasSuccess(p: { onClose: () => void }) {
    return (
        <ModalSuccessBox
            titleCaption={
                <Localized
                    de="Zahlungsmittel angenommen"
                    fr="Moyen de paiement accepté"
                    it="Mezzo di pagamento accettato"
                    en="Means of payment accepted"
                />
            }
            confirmCallback={p.onClose}
        >
            <p>
                <Localized
                    de="Die Karte wurde erfolgreich im Konto hinterlegt."
                    fr="La carte a été enregistrée avec succès dans le compte."
                    it="La carta è stata registrata con successo nel conto."
                    en="The card was successfully registered in the account."
                />
            </p>
            <p>
                <Localized
                    de="Sie können nun Parkgebühren digital bezahlen."
                    fr="Vous pouvez désormais payer les taxes de stationnement par voie numérique."
                    it="Ora può pagare le tasse di parcheggio in modo digitale."
                    en="You can now pay your parking fees digitally."
                />
            </p>
        </ModalSuccessBox>
    );
}

function TopUpSuccess(p: { onClose: () => void }) {
    return (
        <ModalSuccessBox
            titleCaption={
                <Localized
                    de="Zahlung abgeschlossen"
                    fr="Paiement effectué"
                    it="Pagamento eseguito"
                    en="Payment executed"
                />
            }
            confirmCallback={p.onClose}
        >
            <p>
                <Localized
                    de="Die Zahlung war erfolgreich."
                    fr="Le paiement en ligne a réussi."
                    it="Il pagamento è stato eseguito con successo."
                    en="The payment was successful."
                />
            </p>
            <p>
                <Localized
                    de="Ihr Parkingpay-Konto wurde geladen."
                    fr="Votre compte Parkingpay a été alimenté."
                    it="Il vostro conto Parkingpay è stato ricaricato."
                    en="Your Parkingpay account has been topped up."
                />
            </p>
        </ModalSuccessBox>
    );
}

function showCreditCardError(p: ErrorProps): boolean {
    return (
        p.depositCallback.pmethod === 'VIS' ||
        p.depositCallback.pmethod === 'ECA' ||
        p.depositCallback.pmethod === 'AMX'
    );
}

function StoreAliasError(p: ErrorProps) {
    return (
        <ModalErrorBox
            titleCaption={
                p.depositCallback.status === PaymentStatus.FAILED ? (
                    <Localized
                        de="Zahlungsmittel abgelehnt"
                        fr="Moyen de paiement refusé"
                        it="Mezzo di pagamento rifiutato"
                        en="Means of payment rejected"
                    />
                ) : (
                    <Localized
                        de="Vorgang abgebrochen"
                        fr="Procédure annulée"
                        it="Operazione annullata"
                        en="Process cancelled"
                    />
                )
            }
            confirmCallback={p.onClose}
        >
            {p.depositCallback.status === PaymentStatus.FAILED ? (
                <p>
                    <ErrorBodyFailed {...p} />
                </p>
            ) : (
                <>
                    <p>
                        <Localized
                            de="Der Vorgang wurde abgebrochen."
                            fr="L'opération a été annulée."
                            it="L'operazione è stata annullata."
                            en="The process was canceled."
                        />
                    </p>
                    <p>
                        <Localized
                            de="Ihr Zahlungsmittel wurde nicht hinzugefügt."
                            fr="Votre moyenne de paiement n'a pas été ajouté."
                            it="Il suo mezzo di pagamento non è stato aggiunto."
                            en="Your means of payment has not been added."
                        />
                    </p>
                </>
            )}
        </ModalErrorBox>
    );
}

function TopUpError(p: ErrorProps) {
    return (
        <ModalErrorBox
            titleCaption={
                p.depositCallback.status === PaymentStatus.FAILED ? (
                    <Localized
                        de="Zahlung abgelehnt"
                        fr="Paiment refusé"
                        it="Pagamento rifiutato"
                        en="Payment rejected"
                    />
                ) : (
                    <Localized
                        de="Zahlung abgebrochen"
                        fr="Paiement annulé"
                        it="Pagamento annullato"
                        en="Payment cancelled"
                    />
                )
            }
            confirmCallback={p.onClose}
        >
            {p.depositCallback.status === PaymentStatus.FAILED ? (
                <p>
                    <ErrorBodyFailed {...p} />
                </p>
            ) : (
                <TopUpCanceledBody {...p} />
            )}
        </ModalErrorBox>
    );
}

function TopUpCanceledBody(p: ErrorProps) {
    const intro = (
        <Localized
            de="Die Zahlung wurde abgebrochen."
            fr="Le paiement a été annulé."
            it="Il pagamento è stato annullato."
            en="The payment has been cancelled."
        />
    );
    if (
        p.depositCallback.uiPaymentOrigin === UiPaymentOrigin.TOPUP_FROM_PERMIT
    ) {
        return (
            <>
                <p>{intro}</p>
                <p>
                    <Localized
                        de="Es wurde keine Bewilligung gekauft und Ihr Zahlungsmittel wurde nicht belastet."
                        fr="L'autorisation n'a pas été achetée et vostre moyenne de paiement n'a pas été débité."
                        it="L'autorizzazione non è stata acquistata e il vostro mezzo di pagamento non è stato addebitato."
                        en="The permit has not been purchased and your means of payment has not been charged."
                    />
                </p>
            </>
        );
    } else {
        return (
            <>
                <p>{intro}</p>
                <p>
                    <Localized
                        de="Ihr Zahlungsmittel wurde nicht belastet."
                        fr="Votre moyenne de paiement n'a pas été débité."
                        it="Il suo mezzo di pagamento non è stato addebitato."
                        en="Your means of payment has not been charged."
                    />
                </p>
            </>
        );
    }
}

function SwitchToDirectPayment({ onClose }: { onClose: () => void }) {
    return (
        <ModalSuccessBox
            titleCaption={
                <Localized
                    de="Einzelzahlung aktiviert"
                    fr="Paiement individuel activé"
                    it="Pagamento singolo attivato"
                    en="Individual payment activated"
                />
            }
            confirmCallback={onClose}
        >
            <>
                <p>
                    <Localized
                        de="Die Einzelzahlung wurde erfolgreich aktiviert."
                        fr="Le paiement individuel a été activé avec succès."
                        it="Il pagamento singolo è stato attivato con successo."
                        en="The individual payment was successfully activated."
                    />
                </p>
                <p>{AliasActivatedMessage}</p>
            </>
        </ModalSuccessBox>
    );
}
export const AliasActivatedMessage = (
    <Localized
        de="Ab sofort ist es nicht mehr notwendig, das Konto zu laden."
        fr="Désormais, il ne sera plus nécessaire de recharger le compte."
        it="D'ora in poi non sarà più necessario ricaricare il conto."
        en="From now on, it will no longer be necessary to top up the account."
    />
);

export const CardPaymentFailedMessage = () => (
    <Localized
        de={
            <>
                Die Zahlung wurde vom Herausgeber Ihrer Karte abgelehnt.
                Mögliche Gründe:
                <ul>
                    <li>
                        Sie haben das Ablaufdatum oder den CVV-Code falsch
                        eingegeben
                    </li>
                    <li>
                        Die Prüfung vom 3-D Secure (Passwort, TAN oder App) war
                        nicht erfolgreich
                    </li>
                    <li>Ihre Kreditkarte ist gesperrt</li>
                </ul>
                Bitte versuchen Sie es nochmals mit korrekten Angaben oder
                kontaktieren Sie bitte den Herausgeber Ihrer Karte.
            </>
        }
        fr={
            <>
                Le paiement a été refusé par l’émetteur de votre carte. Raisons
                possibles :
                <ul>
                    <li>
                        Vous avez introduit une mauvaise date d’expiration ou un
                        mauvais code CVC
                    </li>
                    <li>
                        Le contrôle de 3-D Secure (mot de passe, TAN ou appli)
                        n’a pas réussi
                    </li>
                    <li>Votre carte est bloquée</li>
                </ul>
                Merci de réessayer avec des données correctes, ou de contacter
                l’émetteur de votre carte.
            </>
        }
        it={
            <>
                Il pagamento è stato rifiutato dall&apos;emittente della vostra
                carta. Possibili motivi:
                <ul>
                    <li>
                        la data di scadenza o il codice CVV inseriti non sono
                        validi;
                    </li>
                    <li>
                        la verifica 3-D Secure (password, TAN o App) non è
                        andata a buon fine;
                    </li>
                    <li>la sua carta di credito è bloccata.</li>
                </ul>
                Vogliate riprovare inserendo le informazioni corrette oppure
                contattate l&apos;emittente della vostra carta.
            </>
        }
        en={
            <>
                The payment has been refused by the issuer of your card.
                Possible reasons:
                <ul>
                    <li>
                        the expiry date or the CVV code entered aren&apos;t
                        valid;
                    </li>
                    <li>
                        3-D Secure verification (password, TAN or App) has
                        failed;
                    </li>
                    <li>your credit card is blocked.</li>
                </ul>
                Please try again by entering the correct information or contact
                the issuer of your card.
            </>
        }
    />
);

/*
KK: Anstelle von Zahlung vom Herausgeber Ihrer Karte abgelehnt: Die Kreditkarte wurde vom Herausgeber der Karte abgelehnt
Sonstiges: Das Zahlungsmittel wurde vom Herausgeber abgelehnt
*/
function ErrorBodyFailed(p: ErrorProps) {
    if (showCreditCardError(p)) {
        return <CardPaymentFailedMessage />;
    } else {
        return (
            <Localized
                de={
                    <div>
                        <p>
                            Die Belastung Ihres Zahlungsmittels wurde vom
                            Herausgeber abgelehnt.
                        </p>
                        <p>
                            Bitte versuchen Sie es nochmals mit korrekten
                            Angaben oder kontaktieren Sie bitte den Herausgeber.
                        </p>
                    </div>
                }
                fr={
                    <div>
                        <p>
                            Le débit de votre moyen de paiement a été refusé par
                            l’émetteur.
                        </p>
                        <p>
                            Merci de réessayer avec des données corrects ou de
                            contacter l’émetteur.
                        </p>
                    </div>
                }
                it={
                    <div>
                        <p>
                            L&apos;addebito del vostro mezzo di pagamento è
                            stato rifiutato dall&apos;emittente.
                        </p>
                        <p>
                            Vogliate riprovare inserendo le informazioni
                            corrette oppure contattate l&apos;emittente.
                        </p>
                    </div>
                }
                en={
                    <div>
                        <p>
                            The charge for your means of payment was refused by
                            the issuer.
                        </p>
                        <p>
                            Please try again by entering the correct information
                            or contact the issuer.
                        </p>
                    </div>
                }
            />
        );
    }
}

export interface ErrorProps {
    depositCallback: PaymentCallbackState.State;
    onClose: () => void;
}
