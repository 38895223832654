import { cloneDeep } from 'lodash';

import { ifDefined, isDefined, Maybe } from './MaybeV2';
import { shallowEqual } from './Object';
import React, { useContext, useRef, useState } from 'react';

interface ChangeCallback {
    (): void;
}

interface AfterUpdateHandler {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (store: Store, name: string, args: Maybe<any>): void;
}

export class Store {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private state: { [idx: string]: any };
    private callbacks: ChangeCallback[];
    private afterUpdateHandler: Maybe<AfterUpdateHandler>;

    constructor(afterUpdateHandler?: Maybe<AfterUpdateHandler>) {
        this.callbacks = [];
        this.state = {};
        this.update = this.update.bind(this);
        this.legacyUpdater = this.legacyUpdater.bind(this);
        this.afterUpdateHandler = afterUpdateHandler;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutate(key: string, value: any): void {
        this.state[key] = value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(key: string): any {
        return this.state[key];
    }

    addChangeListener(callback: ChangeCallback): void {
        this.callbacks.push(callback);
    }

    removeChangeListener(callback: ChangeCallback): void {
        const index = this.callbacks.indexOf(callback);
        if (index > -1) {
            this.callbacks.splice(index, 1);
        }
    }

    update<V>(update: Update<V>, args?: V): void {
        const name = update(this, args);
        this.emitChange();
        ifDefined(this.afterUpdateHandler, (h: AfterUpdateHandler) =>
            h(this, name, args),
        );
    }

    legacyUpdater<V>(update: LegacyUpdate<V>, args?: V): void {
        this.update((store: Store, args?: V) => {
            update(args)(store);
            return 'LEGACY_UPDATE';
        }, args);
    }

    emitChange(): void {
        this.callbacks.forEach((cb: ChangeCallback) => cb());
    }
}

export interface LegacyUpdate<V> {
    (args?: V): Write;
}

export interface Updater {
    <V>(update: Update<V>, args?: V): void;
}

export interface UpdateProp {
    update: Updater;
}

export interface Update<V> {
    (store: Store, args?: V): string;
}

export interface Write {
    (store: Store): void;
}

export interface ContainerProps {
    key?: React.Key;
    allState: Store;
    preStateSetCallback?: () => void;
}

export function useUpdate() {
    const store = useContext(StoreContext);
    if (!store) {
        throw new Error(
            'using select state without allState injected into Context!! adjust main.ts',
        );
    }
    return store.update;
}

/**
 * @deprecated
 */
export function useStore<S>(selector: (store: Store) => S) {
    const store = useContext(StoreContext);

    /* we need to store the selector to make the current one callable from the memoized callback */
    const selectorRef = useRef(selector);
    selectorRef.current = selector;

    if (!store) {
        throw new Error(
            'using select state without allState injected into Context!! adjust main.ts',
        );
    }

    const [storeState, setState] = useState(selector(store));

    /*
    generate a memoized callback that allows clean adding and removing to the change listener
    */
    const refreshState = React.useCallback(
        () => {
            setState(oldState => {
                const newState = selectorRef.current(store);
                if (!shallowEqual(newState, oldState)) {
                    return newState;
                } else {
                    /* returning old state will not refresh state */
                    return oldState;
                }
            });
        },
        [] /* we never need to refresh this. current state selector gets passed in via ref */,
    );

    /*
    equivalent  of component will mount and will unmount
    */
    React.useLayoutEffect(
        () => {
            store.addChangeListener(refreshState);
            return () => {
                store.removeChangeListener(refreshState);
            };
        },
        [
            refreshState,
        ] /*just in case the refresh callback somehow changes anyways */,
    );
    return { storeState, update: store.update, store };
}

export abstract class ContainerWithProps<
    P extends ContainerProps,
    S,
> extends React.Component<P, S> {
    constructor(props: P, beforeFirstGetStoreState?: () => void) {
        super(props);
        if (
            beforeFirstGetStoreState &&
            typeof beforeFirstGetStoreState === 'function'
        ) {
            beforeFirstGetStoreState();
        }
        // bind on action
        this.refreshState = this.refreshState.bind(this);
        this.state = this.getStoreState();
    }

    abstract stateSelector(): S;

    refreshState(): void {
        this.setState(this.getStoreState());
    }

    componentDidUpdate(prevProps: P, _prevState: S): void {
        /* to allow the state selector to be dependent of props, we must
            refresh the state if props were passed in
        */
        if (!shallowEqual(this.props, prevProps)) {
            this.refreshState();
        }
    }

    shouldComponentUpdate(nextProps: P, nextState: S): boolean {
        if (this.state == null) {
            return true;
        }
        return !(
            shallowEqual(this.state, nextState) &&
            shallowEqual(this.props, nextProps)
        );
    }

    componentDidMount(): void {
        this.props.allState.addChangeListener(this.refreshState);
    }

    componentWillUnmount(): void {
        this.props.allState.removeChangeListener(this.refreshState);
    }

    protected update<V>(update: Update<V>, args?: V): void {
        this.props.allState.update(update, args);
    }

    private getStoreState(): S {
        return this.stateSelector();
    }
}

export type SelectStateProps<P, S> = P & S & { update: Updater };

/**
 * @deprecated
 */
export function selectState<P extends object, S>(
    selector: (store: Store, props: P) => S,
    Comp: React.ComponentType<SelectStateProps<P, S>>,
    lifecycle?: {
        didMount?: (store: Store, props: P) => void;
        willUnmount?: (store: Store, props: P) => void;
    },
): React.ComponentType<P> {
    class InternalContainer extends ContainerWithProps<
        P & { allState: Store },
        S
    > {
        stateSelector(): S {
            return selector(this.props.allState, this.getPropsForComponent());
        }

        getPropsForComponent(): P {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { ...p } = this.props as any;
            delete p.allState;
            return p;
        }

        render() {
            const update = this.props.allState.update;
            return (
                <Comp
                    {...this.getPropsForComponent()}
                    update={update}
                    {...this.state}
                />
            );
        }
    }

    class ContextWrapper extends React.Component<P & { allState: Store }> {
        componentDidMount() {
            if (lifecycle && lifecycle.didMount) {
                lifecycle.didMount(this.props.allState, this.props);
            }
        }

        componentWillUnmount() {
            if (lifecycle && lifecycle.willUnmount) {
                lifecycle.willUnmount(this.props.allState, this.props);
            }
        }

        render() {
            // This seems to be not valid in newer TypeScript Versions but I have no idea how to fix it
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return <InternalContainer {...this.props} />;
        }
    }

    // eslint-disable-next-line react/display-name
    return (p: P) => {
        return (
            <StoreContext.Consumer>
                {store => {
                    if (!store) {
                        throw new Error(
                            'using select state without allState injected into Context!! adjust main.ts',
                        );
                    }
                    return <ContextWrapper allState={store} {...p} />;
                }}
            </StoreContext.Consumer>
        );
    };
}

export type LocalSetState<S> = (stateWrite: Partial<S>) => void;
export type LocalStateProps<S> = { state: S; setState: LocalSetState<S> };

type StateFun<P, S> = (props: P) => S;

export function localState<P extends object, S extends object = object>(
    initialState: S | StateFun<P, S>,
    Comp: React.ComponentType<P & LocalStateProps<S>>,
) {
    // eslint-disable-next-line react/display-name
    return class extends React.Component<P, S> {
        constructor(props: P) {
            super(props);
            this.state =
                typeof initialState === 'function'
                    ? (initialState as StateFun<P, S>)(props)
                    : initialState;
        }

        render() {
            return (
                <Comp
                    {...this.props}
                    state={this.state}
                    setState={(s: Partial<S>) => this.setState(s as S)}
                />
            );
        }
    };
}

/*
 * @deprecated use useStore hook instead
 */
export abstract class Container<S> extends ContainerWithProps<
    ContainerProps,
    S
> {}

export const ElementNamer = (component: string) => (block?: string) =>
    `${component}${block ? '-' : ''}${block ? block : ''}`;

export const ElementNamerGenerator = (file: string) => (component?: string) =>
    ElementNamer(`${file}${component ? component : ''}`);

/**
 * @deprecated
 */
export abstract class StateSlice<S> {
    private store: Store;
    private sideEffectsCalling: boolean;

    constructor(store: Store) {
        this.sideEffectsCalling = false;
        this.store = store;
    }

    get state(): S {
        this.initializeStore();
        // prevent recursion when accessing this.state in sideEffects
        if (!this.sideEffectsCalling) {
            this.sideEffectsCalling = true;
            this.sideEffects(this.store);
            this.sideEffectsCalling = false;
        }
        return this.store.get(this.key());
    }

    abstract key(): string;

    abstract getInitialState(store?: Store): S;

    abstract sideEffects(store?: Store): void;

    set(trans: (s: S) => S): void {
        this.initializeStore();
        this.mutate(trans(Object.assign({}, this.store.get(this.key()))));
    }

    reset(): void {
        this.initializeStore();
        this.mutate(this.getInitialState(this.store));
    }

    private initializeStore(): void {
        if (!this.store.get(this.key())) {
            this.mutate(this.getInitialState(this.store));
        }
    }

    private mutate(s: S): void {
        if (isDefined(s)) {
            Object.freeze(s);
            this.store.mutate(this.key(), s);
        } else {
            throw new Error(`${this.key} state tried to mutate undefined`);
        }
    }
}

interface StateTransaction<S> {
    (s: S): S;
}

export interface SliceGenFuns<S> {
    get: (store: Store) => S;
    set: (store: Store, trans: StateTransaction<S>) => void;
    stateWrite: (store: Store, state: Partial<S>) => string;
    reset: (store: Store) => string;
    use: () => [S, (state: Partial<S>) => void, () => void];
}

/**
 * @deprecated
 */
export const generateState = <S extends object>(
    key: string,
    initialState: S | (() => S),
    sideEffects?: Maybe<(store: Store, state: S) => void>,
): SliceGenFuns<S> => {
    class GeneratedStateSlice extends StateSlice<S> {
        getInitialState(): S {
            if (typeof initialState === 'function') {
                return (initialState as () => S)();
            } else {
                return cloneDeep(initialState);
            }
        }

        key(): string {
            return key;
        }

        sideEffects(store: Store): void {
            if (isDefined(sideEffects)) {
                sideEffects(store, this.state);
            }
        }
    }

    const get = (store: Store): S => {
        return new GeneratedStateSlice(store).state;
    };
    const reset = (store: Store): string => {
        new GeneratedStateSlice(store).reset();
        return key + '-reset';
    };
    const stateWrite = (store: Store, state?: Partial<S>): string => {
        new GeneratedStateSlice(store).set(
            (s: S): S => Object.assign(s, state as S),
        );
        return key + '-stateWrite';
    };
    return {
        get,
        set: (store: Store, trans: StateTransaction<S>): void =>
            new GeneratedStateSlice(store).set(trans),
        reset,
        stateWrite,
        use: () => {
            const { storeState, update } = useStore(s => ({ state: get(s) }));
            return [
                storeState.state,
                (p: Partial<S>) => update(stateWrite, p),
                () => update(reset),
            ];
        },
    };
};

export function withUpdater<P, S>(
    Comp:
        | (new () => React.Component<P & S & UpdateProp, S>)
        | ((p: P & UpdateProp) => JSX.Element),
): React.FunctionComponent<P> {
    // eslint-disable-next-line react/display-name
    return (props: P) => {
        return (
            <StoreContext.Consumer>
                {store => {
                    if (!store) {
                        throw new Error(
                            'using select state without allState injected into Context!! adjust main.ts',
                        );
                    }
                    return <Comp update={store.update} {...props} />;
                }}
            </StoreContext.Consumer>
        );
    };
}

export const StoreContext = React.createContext<Store | undefined>(undefined);
