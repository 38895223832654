import { DateTime } from 'luxon';
import React from 'react';

interface IntervalRenderState {
    intervalTimeStamp: DateTime;
}

function intervalRenderer<P>(
    shouldRenderNewInterval: (lastIntervalTimestamp: DateTime) => boolean,
    Comp: React.ComponentType<IntervalRenderState & P>,
    interval = 16,
): React.ComponentType<P> {
    return class IntervalRenderer extends React.Component<
        P,
        IntervalRenderState
    > {
        _intervalId: NodeJS.Timeout | null = null;

        constructor(props: P) {
            super(props);
            this.state = { intervalTimeStamp: DateTime.local() };
        }

        componentDidMount() {
            if (this._intervalId == null) {
                this._intervalId = setInterval(() => {
                    if (shouldRenderNewInterval(this.state.intervalTimeStamp)) {
                        this.setState({
                            intervalTimeStamp: DateTime.local(),
                        });
                    }
                }, interval);
            }
        }

        componentWillUnmount() {
            if (this._intervalId != null) {
                clearInterval(this._intervalId);
                this._intervalId = null;
            }
        }

        render() {
            return <Comp {...this.state} {...this.props} />;
        }
    };
}

export function everyMilisIntervalRenderer<P>(
    milis: number,
    Comp: React.ComponentType<IntervalRenderState & P>,
) {
    return intervalRenderer((lastIntervalTimestamp: DateTime) => {
        return (
            Math.abs(
                lastIntervalTimestamp.diffNow('milliseconds').milliseconds,
            ) > milis
        );
    }, Comp);
}

export function everyNewMinuteIntervalRenderer<P>(
    Comp: React.ComponentType<IntervalRenderState & P>,
) {
    return intervalRenderer((lastIntervalTimestamp: DateTime) => {
        return (
            !lastIntervalTimestamp.hasSame(DateTime.local(), 'minute') ||
            !lastIntervalTimestamp.hasSame(DateTime.local(), 'hour')
        );
    }, Comp);
}
