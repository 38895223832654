import {
    portalSlideIn,
    SlideInPortalId,
} from '../account/root/components/PortalSlidein';

import { SlideInProps } from '../ui/slidein/SlideIn';

export const PortalSlidein = portalSlideIn<object>(p => {
    return <div>{p.children}</div>;
});

export const ParkCreatePortalSlideIn = (p: SlideInProps) => {
    return (
        <PortalSlidein portal={SlideInPortalId.PARK_CREATE} {...p}>
            {p.children}
        </PortalSlidein>
    );
};
