import * as Sentry from '@sentry/browser';

/**
 * updates an extra in the Sentry scope, note that any other existing extra will be kept
 * @param extraName
 * @param extraValue
 */
export function setSentryScope(extraName: string, extraValue: unknown) {
    Sentry.getCurrentScope().setContext(extraName, { value: extraValue });
}
