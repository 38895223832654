import { useEffect } from 'react';
import * as Flux from 'dg-web-shared/lib/Flux';
import { Translation } from 'dg-web-shared/lib/Text';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { vehicleTexts } from '../../../account/vehicles/VehicleTexts';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as LicensePlateState from '../../../common/state/LicensePlateState';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';
import {
    licensePlateTexts,
    permitEntitySelectionTexts,
} from '../../i18n/ParkCreateTexts';
import * as EntitySelectionState from '../../state/EntitySelectionState';
import { SelectionMode } from '../../state/EntitySelectionState';
import * as PermitPurchaseState from '../../state/permit/PermitPurchaseState';
import { TypeData } from '../../state/permit/PermitPurchaseState';
import {
    Customer,
    EntityData,
    LicensePlate,
} from '../../state/shared/EntityData';
import { ParkCreateAddVehicleState, ParkCreateMode } from '../ParkCreate';
import {
    WhitelistRequest,
    WhitelistRequestPending,
} from '../permit/WhitelistRequest';
import { BadgeSelectionSlideIn } from './BadgeSelectionSlideIn';
import { BadgeEntityComp, LicensePlateEntityComp } from './Entities';
import { Entity, EntitySelectionDropin } from './EntitySelectionDropin';
import { FlowNotYetCompleted, NeedsClearance, WrongType } from './InfoBoxes';
import { PermitTypeState } from '../permit/Permit';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ArrowPosition, InlineAlertBox } from '../../../ui/modals/Confirmable';
import { SingleSelection } from '../../../common/components/SingleSelection.tsx';

export interface PermitEntitySelectionTexts {
    noLpsTitle: Translation;
    noLpsBody: () => JSX.Element;
    noBadgesTitle: Translation;
    noBadgesBody: () => JSX.Element;
}

function InfosOrNextElement({
    typeData,
    selectedEntityIds,
    refetchTypeData,
    language,
    operatorName,
    operatorContactDetails,
    licensePlates,
    setUnableToPurchase,
    children,
}: {
    typeData: TypeData.Type;
    selectedEntityIds: EntityData[];
    refetchTypeData: () => void;
    language: string;
    operatorName: string;
    operatorContactDetails: string | null;
    licensePlates: LicensePlateState.SavedLicensePlate[];
    setUnableToPurchase: (unableToPurchase: boolean) => void;
    children: React.ReactNode;
}) {
    const selectedLicensePlates: EntityData[] = selectedEntityIds.map(
        e => e as LicensePlate,
    );

    const lpsWithClearanceRequestNeeded = selectedLicensePlates
        .filter(
            e =>
                e.entityType === 'LICENSE_PLATE' &&
                e.mode === 'clearanceRequest',
        )
        .map(lp => lp as LicensePlate);

    const customersWithClearanceRequestNeeded = selectedLicensePlates
        .filter(
            e => e.entityType === 'CUSTOMER' && e.mode === 'clearanceRequest',
        )
        .map(c => c as Customer);

    const lpsWithWrongType = selectedLicensePlates.filter(
        e => e.mode === 'typeNotAllowed',
    );

    const hasClearanceRequestFlowPending =
        selectedLicensePlates.filter(
            lp => lp.mode === 'clearanceRequestFlowPending',
        ).length > 0;

    const hasClearanceRequestPending =
        selectedLicensePlates.filter(
            lp => lp.mode === 'clearanceRequestPending',
        ).length > 0;

    const entitiesWithMissingClearances = selectedEntityIds.filter(
        e => e.mode === 'noClearance',
    );
    const hasMissingClearances = entitiesWithMissingClearances.length > 0;
    const hasLpsWithWrongType = lpsWithWrongType.length > 0;

    const clearanceRequestNeeded =
        lpsWithClearanceRequestNeeded.length > 0 ||
        customersWithClearanceRequestNeeded.length > 0;

    const purchaseChecks = [
        hasClearanceRequestFlowPending,
        hasClearanceRequestPending,
        hasMissingClearances,
        clearanceRequestNeeded,
        hasLpsWithWrongType,
    ];

    useEffect(() => {
        const canPurchase = !purchaseChecks.some(
            purchaseCheck => purchaseCheck,
        );
        if (canPurchase) {
            setUnableToPurchase(false);
        } else {
            setUnableToPurchase(true);
        }
    }, [purchaseChecks]);

    const hasBadges = !typeData.entityData.some(
        entity => entity.entityType === 'BADGE',
    );

    if (typeData.isOffstreet && hasBadges) {
        return (
            <InlineAlertBox
                arrowPosition={ArrowPosition.left}
                titleCaption={permitEntitySelectionTexts[
                    language
                ].noBadgesTitle()}
            >
                {permitEntitySelectionTexts[language].noBadgesBody()}
            </InlineAlertBox>
        );
    }

    if (!typeData.isOffstreet && licensePlates.length === 0) {
        return (
            <InlineAlertBox
                arrowPosition={ArrowPosition.left}
                titleCaption={permitEntitySelectionTexts[language].noLpsTitle()}
            >
                {permitEntitySelectionTexts[language].noLpsBody()}
            </InlineAlertBox>
        );
    }

    if (selectedEntityIds.length === 0) {
        return null;
    }

    if (lpsWithWrongType.length > 0) {
        return (
            <WrongType
                language={language}
                lps={lpsWithWrongType as LicensePlate[]}
                permit
            />
        );
    }

    if (clearanceRequestNeeded) {
        return (
            <WhitelistRequest
                lps={lpsWithClearanceRequestNeeded}
                customers={customersWithClearanceRequestNeeded}
                operatorName={operatorName}
                operatorContactDetails={operatorContactDetails}
                permitTypeId={typeData.permitTypeId}
                refetchTypeData={refetchTypeData}
                isTransaction={false}
                clearanceRequestCustomFields={
                    typeData.clearanceRequestCustomFields
                }
            />
        );
    }

    if (hasClearanceRequestFlowPending) {
        return <FlowNotYetCompleted />;
    }

    if (hasClearanceRequestPending) {
        return (
            <WhitelistRequestPending
                operatorName={operatorName}
                operatorContactDetails={operatorContactDetails}
            />
        );
    }

    if (hasMissingClearances) {
        return (
            <NeedsClearance
                lps={entitiesWithMissingClearances}
                permit
                operatorName={operatorName}
                operatorContactDetails={operatorContactDetails}
            />
        );
    }

    return <div>{children}</div>;
}

interface SelectionProps {
    entities: EntityData[];
}

const Selection = (p: SelectionProps) => {
    return (
        <div>
            {p.entities.map(e => {
                if (e.entityType === 'LICENSE_PLATE') {
                    return (
                        <LicensePlateEntityComp licensePlate={e} key={e.id} />
                    );
                } else if (e.entityType === 'BADGE') {
                    return (
                        <BadgeEntityComp
                            {...e}
                            remarks={e.remarks || null}
                            type={e.typeId}
                            key={`entitySelection-${e.id}-${e.vehicleId}`}
                        />
                    );
                }
            })}
        </div>
    );
};

export function PermitEntitySelection({
    language,
    typeData,
    selectedEntityIds,
    update,
    onEntitySelectionSlideInOpen,
    refetchTypeData,
    licensePlates,
    setUnableToPurchase,
    children,
}: {
    language: string;
    typeData: TypeData.Type;
    selectedEntityIds: number[];
    update: Flux.Updater;
    onEntitySelectionSlideInOpen: () => void;
    refetchTypeData: () => void;
    licensePlates: LicensePlateState.SavedLicensePlate[];
    setUnableToPurchase: (unableToPurchase: boolean) => void;
    children: React.ReactNode;
}) {
    const selectedEntities = typeData.entityData.filter(
        lp => selectedEntityIds.indexOf(lp.id) > -1,
    );
    const customerEntityOnly =
        typeData.entityData.filter(v => v.entityType !== 'CUSTOMER').length ===
        0;
    const hasLps = !typeData.isOffstreet && !customerEntityOnly;
    const texts = licensePlateTexts[language];
    const openEntitySelection = (store: Flux.Store) => {
        EntitySelectionState.Dropin.Permit.stateWrite(store, {
            open: true,
        });
        ParkCreateAddVehicleState.reset(store);
        return 'openEntitySelection';
    };

    return (
        <div>
            {customerEntityOnly ? null : (
                <SingleSelection
                    onClick={
                        hasLps && typeData.entityData.length === 0
                            ? () => {
                                  update(store =>
                                      ParkCreateAddVehicleState.stateWrite(
                                          store,
                                          {
                                              addVehicle: true,
                                          },
                                      ),
                                  );
                              }
                            : () => {
                                  update(openEntitySelection);
                                  onEntitySelectionSlideInOpen();
                              }
                    }
                    selection={
                        selectedEntities.length > 0 ? (
                            <Selection entities={selectedEntities} />
                        ) : null
                    }
                    context={InputContext.inverted}
                    rightText={
                        selectedEntities.length > 0
                            ? `${
                                  selectedEntityIds.filter(
                                      (val, idx, self) =>
                                          self.indexOf(val) === idx,
                                  ).length
                              }/${typeData.maxEntities}`
                            : null
                    }
                    labelText={hasLps ? texts.Vehicle() : texts.Badge()}
                />
            )}
            <InfosOrNextElement
                typeData={typeData}
                operatorName={typeData.operatorName}
                operatorContactDetails={typeData.operatorContactDetails}
                selectedEntityIds={selectedEntities}
                refetchTypeData={refetchTypeData}
                language={language}
                licensePlates={licensePlates}
                setUnableToPurchase={setUnableToPurchase}
            >
                {children}
            </InfosOrNextElement>
        </div>
    );
}

export const resetStates = (store: Flux.Store) => {
    PermitPurchaseState.resetAllSlices(store);
};

export const setSingleLicensePlate = (store: Flux.Store, id: number) => {
    EntitySelectionState.Dropin.Permit.stateWrite(store, { open: false });
    EntitySelectionState.Selection.Permit.stateWrite(store, {
        selectedEntityIds: [id],
    });
    ParkCreateAddVehicleState.reset(store);
    resetStates(store);
    return 'setSingleLicensePlate';
};

interface PermitEntitySelectionDropinProps {
    selectedLicensePlates: number[];
    permitTypeState: PermitTypeState;
    currentLogin: CurrentLoginState.State;
    open: boolean;
    onClose: () => void;
    update: Flux.Updater;
    onSelect: (id: number) => void;
    onVehicleAddSuccess: () => void;
}

export function PermitEntitySelectionDropin({
    selectedLicensePlates,
    permitTypeState,
    currentLogin,
    open,
    onClose,
    onSelect,
    update,
    onVehicleAddSuccess,
}: PermitEntitySelectionDropinProps) {
    const language = useParkingpayLanguage();
    if (
        permitTypeState.permitTypeData.status === RequestStatus.ERROR ||
        permitTypeState.permitTypeData.data === null
    ) {
        return null;
    }

    const selectionMode: SelectionMode =
        permitTypeState.permitTypeData.data.maxEntities > 1
            ? 'multiple'
            : 'single';

    const props = {
        language,
        // filter out type customer as we don't have an overlapping selectable entity...
        entities: permitTypeState.permitTypeData.data.entityData
            .filter(v => v.entityType !== 'CUSTOMER')
            .map(e => e as Entity),
        update,
        onClose,
        onSelect,
        selectedEntityIds: selectedLicensePlates,
        selectionMode: selectionMode,
        disableUnselected:
            selectionMode === 'multiple' &&
            selectedLicensePlates.length >=
                permitTypeState.permitTypeData.data.maxEntities,
        maxEntities: permitTypeState.permitTypeData.data.maxEntities,
        canAddNewEntities: currentLogin.data
            ? currentLogin.data.loginRole === CurrentLoginState.LoginRole.ADMIN
            : false,
    };

    return (
        <div>
            <BadgeSelectionSlideIn
                {...props}
                portal={SlideInPortalId.PARK_CREATE}
                open={permitTypeState.permitTypeData.data.isOffstreet && open}
                title={vehicleTexts[language].badge()}
                onClose={onClose}
                mode={ParkCreateMode.badge}
                permitTypeState={permitTypeState}
                hideClose
            />
            {!permitTypeState.permitTypeData.data.isOffstreet && (
                <EntitySelectionDropin
                    {...props}
                    open={open}
                    onClose={onClose}
                    mode={ParkCreateMode.permit}
                    onVehicleAddSuccess={onVehicleAddSuccess}
                    permitTypeState={permitTypeState}
                />
            )}
        </div>
    );
}
