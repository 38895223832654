import { UserAccountSlideIn } from '../root/components/PortalSlidein';
import { Localized } from '../../common/components/Localized';
import { DropdownSlideInContent } from '../root/components/DropdownSlideIn';
import { useStore } from 'dg-web-shared/lib/Flux';
import * as MetaServerState from '../meta/state/MetaServerState';
import * as LoginsState from '../logins/state/LoginsState';
import { FieldItemBlock } from '../root/components/ItemBlock';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { UpdateVehicle, Vehicle } from './VehicleState';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { SingleSelection } from '../../common/components/SingleSelection.tsx';

export function VehicleVisibility(p: {
    loginId: number | null;
    setLoginId?: (loginId: number | null) => void;
    vehicle: Vehicle | null;
}) {
    const { storeState, update } = useStore(s => ({
        meta: new MetaServerState.StateSlice(s).state,
        logins: new LoginsState.StateSlice(s).state,
        selectedVehicle: UpdateVehicle.get(s),
    }));

    const [showEdit, setShowEdit] = useState(false);
    const [loginId, setLoginId] = useState(p.loginId);

    const matchingLogin = storeState.logins.data.find(
        login => login.id === loginId,
    );
    const loginDescription = matchingLogin
        ? `${matchingLogin.firstName} ${matchingLogin.lastName}`
        : '-';

    if (
        storeState.meta.data.nonAdminTransactionsVisible ||
        storeState.meta.data.customerAccountType !== CustomerAccountType.COMPANY
    ) {
        return null;
    }

    const vehicle = p.vehicle;
    const isNew = vehicle === null;

    const dropdownSlideInContent = (
        <>
            <DropdownSlideInContent
                selected={loginId}
                options={storeState.logins.data
                    .sort((a, b) =>
                        (a.lastName || '').localeCompare(b.lastName || ''),
                    )
                    .map(login => ({
                        id: login.id,
                        display: (
                            <LoginOptionDisplay
                                text={`${login.firstName} ${login.lastName}`}
                            />
                        ),
                    }))}
                topOptions={[
                    {
                        id: null,
                        display: <LoginOptionDisplay text={AllLogins} />,
                    },
                ]}
                onSelected={loginId => {
                    if (isNew && p.setLoginId) {
                        p.setLoginId(loginId);
                        setShowEdit(false);
                    }
                    setLoginId(loginId);
                    if (!isNew) {
                        update(store =>
                            UpdateVehicle.put(
                                store,
                                vehicle!,
                                {
                                    ...p.vehicle!,
                                    loginId: loginId,
                                },
                                () => setShowEdit(false),
                            ),
                        );
                    }
                }}
            />
        </>
    );
    const reset = () => {
        setShowEdit(false);
        setLoginId(p.loginId);
    };
    return (
        <>
            {isNew ? (
                <SingleSelection
                    labelText={visibilityText}
                    selection={loginDescription}
                    onClick={() => setShowEdit(true)}
                    context={InputContext.form}
                    readOnly={false}
                />
            ) : (
                <FieldItemBlock
                    onClick={() => setShowEdit(true)}
                    label={<Localized {...visibilityText} />}
                    content={loginDescription}
                />
            )}
            <UserAccountSlideIn
                open={showEdit}
                onClose={reset}
                title={<Localized {...visibilityText} />}
            >
                <div
                    className={css({
                        ...Typo.robotoRegular,
                        color: Colors.action_f,
                        fontSize: '16px',
                        lineHeight: '22px',
                        padding: '24px',
                    })}
                >
                    <Localized
                        de={
                            <>
                                <p>
                                    Durch die Zuweisung eines bestimmten Fahrers
                                    zu diesem Fahrzeug sind das Fahrzeug selbst
                                    und seine Transaktionen nur für den Fahrer
                                    und die Administratoren in der App sichtbar.
                                </p>
                                <p>
                                    Um einen neuen Fahrer zu der untenstehenden
                                    Liste hinzuzufügen, registrieren Sie einen
                                    neuen Benutzer.
                                </p>
                            </>
                        }
                        fr={
                            <>
                                <p>
                                    En assignant un conducteur spécifique à ce
                                    véhicule, le véhicule lui-même et ses
                                    transactions seront visibles dans
                                    l&#39;application pour le conducteur et les
                                    administrateurs uniquement.
                                </p>
                                <p>
                                    Pour ajouter un nouveau conducteur à la
                                    liste ci-dessous, enregistrez un nouvel
                                    utilisateur.
                                </p>
                            </>
                        }
                        it={
                            <>
                                <p>
                                    Assegnando un conducente specifico a questo
                                    veicolo, il veicolo stesso e le relative
                                    transazioni saranno visibili nell&#39;app
                                    solo per il conducente e per gli
                                    amministratori.
                                </p>
                                <p>
                                    Per aggiungere un nuovo conducente alla
                                    lista qui sotto, registrare un nuovo utente.
                                </p>
                            </>
                        }
                        en={
                            <>
                                <p>
                                    By assigning a specific driver to this
                                    vehicle, the vehicle itself and its
                                    transactions will be visible in the app for
                                    the driver and administrators only.
                                </p>
                                <p>
                                    To add a new driver to the list below,
                                    register a new user.
                                </p>
                            </>
                        }
                    />
                </div>
                <div
                    className={css({
                        padding: '0 24px',
                    })}
                >
                    {dropdownSlideInContent}
                </div>
            </UserAccountSlideIn>
        </>
    );
}

const visibilityText = {
    de: 'Fahrer',
    fr: 'Chauffeur',
    it: 'Conducente',
    en: 'Driver',
};

const AllLogins = <Localized de="Alle" it="Tutti" fr="Tous" en="Everyone" />;

function LoginOptionDisplay(p: { text: React.ReactNode }) {
    return (
        <div
            className={css({
                padding: '0 24px',
            })}
        >
            {p.text}
        </div>
    );
}
