import React from 'react';
import { SlideIn, SlideInProps } from '../../../ui/slidein/SlideIn';
import * as ReactDOM from 'react-dom';

const SLIDEIN_ANIMATION_TIME = 300;

export enum SlideInPortalId {
    USER_ACCOUNT = 'account-level2-slidein',
    PARK_CREATE = 'ParkCreateDropins',
    TRANSACTION_LIST = 'transaction-list-slidein',
    UNAUTH_LANDING_PAGE = 'unauth-landing-page',
    FULL_SCREEN_MODAL = 'fullScreenModal',
}

export interface PortalSlideInProps extends SlideInProps {
    portal: SlideInPortalId;
}

type Props<P> = P & PortalSlideInProps;

export function portalSlideIn<P extends object = object>(
    Comp: React.ComponentType<Props<P>>,
): React.ComponentType<Props<P>> {
    const component: React.ComponentType<Props<P>> =
        class extends React.Component<
            Props<P>,
            Props<P> & { shouldRender: boolean }
        > {
            constructor(props: Props<P>) {
                super(props);
                this.state = Object.assign({}, props, {
                    shouldRender: props.open,
                });
            }

            UNSAFE_componentWillReceiveProps(nextProps: Props<P>) {
                if (nextProps.open) {
                    this.setState(
                        Object.assign({}, nextProps, {
                            shouldRender: true,
                        }),
                    );
                }

                if (
                    !nextProps.open &&
                    this.state.open &&
                    this.state.shouldRender
                ) {
                    /*
                typing got stricter in the set state method. because state is generic (maybe this should change?)
                open or shouldRender cannot be inferred anymore since TypeScript 3.1
                */

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.setState<any>({ open: false });
                    setTimeout(() => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        this.setState<any>({ shouldRender: false });
                    }, SLIDEIN_ANIMATION_TIME);
                }
            }

            render(): JSX.Element | null {
                const htmlElement = document.getElementById(
                    this.props.portal,
                ) as HTMLElement;
                if (htmlElement === null) {
                    return null;
                }
                return this.state.shouldRender
                    ? ReactDOM.createPortal(
                          <SelfOpeningSlideIn
                              {...this.state}
                              contentCssClass={this.props.contentCssClass}
                              level={this.props.level}
                          >
                              <Comp {...this.state} />
                          </SelfOpeningSlideIn>,
                          htmlElement,
                      )
                    : null;
            }
        };
    component.displayName = 'portalSlideIn';
    return component;
}

interface SelfOpeningSlideInState {
    open: boolean;
}

class SelfOpeningSlideIn extends React.Component<
    SlideInProps & { level?: number },
    SelfOpeningSlideInState
> {
    state: SelfOpeningSlideInState = { open: false };

    componentDidMount() {
        if (this.props.open) {
            setTimeout(() => {
                this.setState({ open: true });
            }, 10);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: SlideInProps) {
        this.setState({ open: nextProps.open });
    }

    render() {
        return <SlideIn {...this.props} open={this.state.open} />;
    }
}
const UserAccountSlideInWrapper = portalSlideIn(p => <>{p.children}</>);

export const UserAccountSlideIn = (p: SlideInProps) => (
    <UserAccountSlideInWrapper {...p} portal={SlideInPortalId.USER_ACCOUNT} />
);
