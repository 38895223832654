export interface Location {
    latitude: number;
    longitude: number;
    accuracy?: number;
}

export interface GeocodedZipcodes {
    [idx: string]: Location;
}

export namespace Geolocation {
    const defaultGeolocationOptions = {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0,
    } as PositionOptions;

    export const hasGeolocation = () => 'geolocation' in navigator;

    export const clearWatch = (id: number) =>
        navigator.geolocation.clearWatch(id);

    export const watchPosition = (
        success: PositionCallback = _pos => null,
        errorCallback?: PositionErrorCallback,
    ) => {
        if (!navigator.geolocation || !navigator.geolocation.watchPosition) {
            if (errorCallback) {
                errorCallback({
                    code: 2,
                    message: 'no gelocation API',
                } as GeolocationPositionError);
            }
            return -1;
        }
        return navigator.geolocation.watchPosition(
            pos => success(pos),
            err => {
                if (errorCallback) {
                    errorCallback(err);
                }
            },
            defaultGeolocationOptions,
        );
    };

    export const getCurrentPosition = (
        success: PositionCallback = _pos => null,
        errorCallback?: PositionErrorCallback,
    ) => {
        if (
            !navigator.geolocation ||
            !navigator.geolocation.getCurrentPosition
        ) {
            if (errorCallback) {
                errorCallback({
                    code: 2,
                    message: 'no gelocation API',
                } as GeolocationPositionError);
            }
            return -1;
        }
        navigator.geolocation.getCurrentPosition(
            pos => success(pos),
            err => {
                if (errorCallback) {
                    errorCallback(err);
                }
            },
            defaultGeolocationOptions,
        );
    };

    const deg2rad = (v: number): number => (v * Math.PI) / 180;

    // calculates distance using equirectangular projection
    // s. http://www.movable-type.co.uk/scripts/latlong.html
    // R is earth radius in m
    export const distance = (a: Location, b: Location) => {
        const R = 6371e3;
        const lat1 = deg2rad(a.latitude);
        const lat2 = deg2rad(b.latitude);
        const long1 = deg2rad(a.longitude);
        const long2 = deg2rad(b.longitude);
        const y = lat2 - lat1;
        const x = (long2 - long1) * Math.cos((lat1 + lat2) / 2);
        return Math.sqrt(x * x + y * y) * R;
    };
}
