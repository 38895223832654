import * as superagent from 'superagent';
import { portalSlideIn } from '../../account/root/components/PortalSlidein';
import { css } from '@emotion/css';
import { LabeledText } from '../../ui/typo/LabeledText.tsx';
import { PermitDetail } from '../state/TransactionsListState';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats';
import { DateTime } from 'luxon';
import { SlideInForm } from '../../account/root/components/SlideInForm';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    ButtonText,
    ModalErrorBox,
    ModalQuestionBox,
} from '../../ui/modals/Confirmable';
import { Localized } from '../../common/components/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import { useUpdate } from 'dg-web-shared/lib/Flux';

import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import crossPictureSvg from '../../../assets/cross.svg';
import tickPictureSvg from '../../../assets/tick.svg';

export const LicensePlateDetailsSlidein = portalSlideIn<{
    licensePlate: PermitDetail.PermitDetailLicensePlate;
    permitId: number;
}>(LicensePlateDetails);

function StatusPicture(p: { running: boolean }) {
    return (
        <div
            className={css({
                display: 'flex',
                justifyContent: 'center',
                margin: '20px 0px 20px 0px',
            })}
        >
            <img
                src={p.running ? tickPictureSvg : crossPictureSvg}
                width="80px"
                height="80px"
                color="#468033"
            />
        </div>
    );
}

function LicensePlateDetails(props: {
    licensePlate?: PermitDetail.PermitDetailLicensePlate;
    permitId: number;
    onClose: () => void;
}) {
    const [activableDeactivableModalOpen, setActivableDeactivableModalOpen] =
        useState(false);
    const [notActivableModalOpen, setNotActivableModalOpen] = useState(false);
    const update = useUpdate();
    const [state, writeOperation] = useServerWrite<
        { permitId: number; licensePlateId: number },
        object
    >({
        req: (args: { permitId: number; licensePlateId: number }) =>
            superagent
                .post(
                    `/ui-api/customer-account/transactions-list/permit-detail/${
                        args.permitId
                    }/${isActive ? 'checkout' : 'checkin'}`,
                )
                .send({ licensePlateId: args.licensePlateId }),
        onResponse: () => {
            update(store => PermitDetail.refetchSameContext(store, true));
        },
    });

    if (props.licensePlate?.explicitCheckin == null) {
        return null;
    }
    const isActive = props.licensePlate.explicitCheckin.running;
    const isActivable =
        !props.licensePlate.explicitCheckin.running &&
        props.licensePlate.explicitCheckin.checkinPossible;
    const isDeactivable =
        props.licensePlate.explicitCheckin.running &&
        props.licensePlate.explicitCheckin.checkoutPossible;
    const lpNumber = props.licensePlate.licensePlateNr;

    return (
        <>
            {state.status === RequestStatus.PENDING && <OverlaySpinner />}

            <SlideInForm
                secondaryButton={{
                    label: (
                        <Localized
                            de="Zurück"
                            fr="Retour"
                            it="Indietro"
                            en="Back"
                        />
                    ),
                    disabled: state.status === RequestStatus.PENDING,
                    onClick: props.onClose,
                }}
                primaryButton={
                    !isActive || isDeactivable
                        ? {
                              label: (
                                  <>
                                      {!isActive ? (
                                          <Localized
                                              de="Aktivieren"
                                              fr="Activer"
                                              it="Attiva"
                                              en="Activate"
                                          />
                                      ) : (
                                          <Localized
                                              de="Deaktivieren"
                                              fr="Désactiver"
                                              it="Disattiva"
                                              en="Deactivate"
                                          />
                                      )}
                                      {state.status === RequestStatus.PENDING &&
                                          '...'}
                                  </>
                              ),
                              disabled: state.status === RequestStatus.PENDING,
                              onClick: () => {
                                  if (!isActive && !isActivable) {
                                      setNotActivableModalOpen(true);
                                  } else {
                                      setActivableDeactivableModalOpen(true);
                                  }
                              },
                          }
                        : undefined
                }
            >
                <StatusPicture running={isActive} />
                <div
                    className={css({
                        ...Typo.robotoRegular,
                        color: isActive ? Colors.grassGreen : Colors.action_w,
                        textAlign: 'center',
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '22px',
                        margin: '0px 20px 30px 20px',
                    })}
                >
                    {isActive ? (
                        <Localized
                            de={
                                <p>
                                    Die Bewilligung ist für dieses Fahrzeug bis
                                    zum Ablauf der Aktivierung gültig.
                                </p>
                            }
                            fr={
                                <p>
                                    L&apos;autorisation est valable pour ce
                                    véhicule jusqu&apos;à échéance de
                                    l&apos;activation.
                                </p>
                            }
                            it={
                                <p>
                                    L’autorizzazione è valida per questo veicolo
                                    fino alla scadenza dell&apos;attivazione.
                                </p>
                            }
                            en={
                                <p>
                                    The permit is valid for this vehicle until
                                    the expiration of the activation.
                                </p>
                            }
                        />
                    ) : (
                        <Localized
                            de={
                                <p>
                                    Die Bewilligung ist für dieses Fahrzeug
                                    nicht gültig, da es nicht aktiviert wurde.
                                </p>
                            }
                            fr={
                                <p>
                                    L&apos;autorisation n&apos;est pas valable
                                    pour ce véhicule, car il n&apos;a pas été
                                    activé.
                                </p>
                            }
                            it={
                                <p>
                                    L&apos;autorizzazione non è valida per
                                    questo veicolo, in quanto non è stato
                                    attivato.
                                </p>
                            }
                            en={
                                <p>
                                    The permit is not valid for this vehicle, as
                                    it has not been activated.
                                </p>
                            }
                        />
                    )}
                </div>
                <LabeledText
                    label={{
                        de: 'Status',
                        fr: 'Statut',
                        it: 'Stato',
                        en: 'State',
                    }}
                >
                    {isActive ? (
                        <Localized
                            de="Aktiv"
                            fr="Actif"
                            it="Attivo"
                            en="Active"
                        />
                    ) : (
                        <Localized
                            de="Inaktiv"
                            fr="Inactif"
                            it="Inattivo"
                            en="Inactive"
                        />
                    )}
                </LabeledText>
                <LabeledText
                    label={{
                        de: 'Bezeichnung',
                        fr: 'Déscription',
                        it: 'Descrizione',
                        en: 'Description',
                    }}
                >
                    {props.licensePlate.remarks} (
                    {props.licensePlate.licensePlateNr})
                </LabeledText>
                {props.licensePlate.explicitCheckin.running && (
                    <>
                        <LabeledText
                            label={{
                                de: 'Aktivierung',
                                fr: 'Activation',
                                it: 'Attivazione',
                                en: 'Activation',
                            }}
                        >
                            {DateTime.fromISO(
                                props.licensePlate.explicitCheckin.runningFrom,
                            ).toFormat(LuxonDateStringFormats.DATE_HOUR_MINUTE)}
                        </LabeledText>
                        <LabeledText
                            label={{
                                de: 'Aktivierungsablauf',
                                fr: 'Échéance activation',
                                it: 'Scadenza attivazione',
                                en: 'Activation expiration',
                            }}
                        >
                            {DateTime.fromISO(
                                props.licensePlate.explicitCheckin.runningUntil,
                            ).toFormat(LuxonDateStringFormats.DATE_HOUR_MINUTE)}
                        </LabeledText>
                    </>
                )}
                {props.licensePlate.pastActivations.length > 0 && (
                    <LabeledText
                        label={{
                            de: 'Letzte 5 Aktivierungen',
                            fr: 'Dernières 5 activations',
                            it: 'Ultime 5 attivazioni',
                            en: 'Last 5 activations',
                        }}
                    >
                        {props.licensePlate.pastActivations.map(a => {
                            const validFrom = DateTime.fromISO(a.validFrom);
                            const validTo = DateTime.fromISO(a.validTo);
                            const longForm =
                                validFrom.toISODate() !== validTo.toISODate();
                            return (
                                <div key={a.contractId}>
                                    {validFrom.toFormat(
                                        LuxonDateStringFormats.DATE_HOUR_MINUTE,
                                    )}
                                    &nbsp;&nbsp;&#10132;&nbsp;&nbsp;
                                    {longForm
                                        ? validTo.toFormat(
                                              LuxonDateStringFormats.DATE_HOUR_MINUTE,
                                          )
                                        : validTo.toFormat(
                                              LuxonDateStringFormats.HOUR_MINUTE,
                                          )}
                                    <br />
                                </div>
                            );
                        })}
                    </LabeledText>
                )}
            </SlideInForm>
            {activableDeactivableModalOpen && (
                <ModalQuestionBox
                    titleCaption={
                        !isActive ? (
                            <Localized
                                de="Aktivierung"
                                fr="Activation"
                                it="Attivazione"
                                en="Activation"
                            />
                        ) : (
                            <Localized
                                de="Deaktivierung"
                                fr="Désactivation"
                                it="Disattivazione"
                                en="Deactivation"
                            />
                        )
                    }
                    cancelCallback={() =>
                        setActivableDeactivableModalOpen(false)
                    }
                    confirmCallback={() => {
                        writeOperation({
                            permitId: props.permitId,
                            licensePlateId: props.licensePlate!.id,
                        });
                        setActivableDeactivableModalOpen(false);
                    }}
                >
                    {!isActive ? (
                        <p>
                            <Localized
                                de={`Wollen Sie wirklich das Kennzeichen ${lpNumber} bei dieser Bewilligung aktivieren?`}
                                fr={`Voulez-vous vraiment activer l'immatriculation ${lpNumber} pour cette autorisation?`}
                                it={`Vuole veramente attivare la targa ${lpNumber} per quest'autorizzazione?`}
                                en={`Do you really want to activate the ${lpNumber} for this permit?`}
                            />
                        </p>
                    ) : (
                        <p>
                            <Localized
                                de={`Wollen Sie wirklich das Kennzeichen ${lpNumber} bei dieser Bewilligung deaktivieren?`}
                                fr={`Voulez-vous vraiment désactiver l'immatriculation ${lpNumber} pour cette autorisation?`}
                                it={`Vuole veramente disattivare la targa ${lpNumber} per quest'autorizzazione?`}
                                en={`Do you really want to deactivate the ${lpNumber} for this permit?`}
                            />
                        </p>
                    )}
                </ModalQuestionBox>
            )}
            {notActivableModalOpen && (
                <ModalErrorBox
                    titleCaption={
                        <Localized
                            de="Aktivierung nicht möglich"
                            fr="Activation pas possible"
                            it="Attivazione non possibile"
                            en="Activation not possible"
                        />
                    }
                    confirmCallback={() => setNotActivableModalOpen(false)}
                    confirmCaption={ButtonText.CLOSE}
                >
                    <p>
                        <Localized
                            de="Dieses Fahrzeug kann nicht aktiviert werden, da die maximale Anzahl der gleichzeitig aktiven Fahrzeuge bereits erreicht ist."
                            fr="Ce véhicule ne peut pas être activé, car le nombre maximum de véhicules simultanément actifs a déjà été atteint."
                            it="Questo veicolo non può essere attivato, in quanto il numero massimo di veicoli attivi contemporaneamente è già stato raggiunto."
                            en="This vehicle cannot be activated, as the maximum number of simultaneously active vehicles has already been reached."
                        />
                    </p>
                </ModalErrorBox>
            )}
        </>
    );
}

const OverlaySpinner = () => (
    <div
        className={css({
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: 0.8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255,0.9)',
        })}
    >
        <Spinner />
    </div>
);
