import * as Flux from 'dg-web-shared/lib/Flux';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers';
import { Translation } from 'dg-web-shared/lib/Text';
import * as SettingsState from '../../common/state/SettingsState';
import * as AccountSetupState from '../state/AccountSetupState';
import { AccountSetupBlock, Para, SummaryTitle } from './AccountSetupBlock';
import { navigationTextsFor } from '../i18n/NavigationTexts';
import { summaryTexts } from '../i18n/SummaryTexts';
import { AddressCountrySlidein } from './AccountSetupSlideins';
import * as LicensePlateCountriesState from '../../common/state/LicensePlateCountriesState';
import * as AddressCountriesState from '../../common/state/AddressCountriesState';
import { LicensePlateEntityComp } from '../../park-create/components/license-plate/Entities';
import * as BadgeTypesState from '../../common/state/BadgeTypesState';
import * as AsyncRequest from '../../AsyncRequest';
import * as Http from '../../api/Http';
import { logAction } from '../../utils/ActionLog';
import * as ParkOptionListState from '../../park-create/state/ParkOptionListState';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars';

export interface AccountSetupSummaryTexts {
    summaryTitle: Translation;
    emailBlockLabel: Translation;
    addressBlockLabel: Translation;
    vehicleBlockLaber: Translation;
    badgeBlockLabel: Translation;
    badgeOrdered: Translation;
}

const texts = (s: { settings: SettingsState.State }) =>
    summaryTexts[s.settings.language];

interface AccountSetupSummaryState {
    settings: SettingsState.State;
    currentLogin: CurrentLoginState.State;
    accountSetupConfiguration: AccountSetupState.Configuration.State;
    accountSetupAddress: AccountSetupState.Address.State;
    accountSetupBadge: AccountSetupState.Badge.State;
    accountSetupLicensePlate: AccountSetupState.LicensePlate.State;
    licensePlateCountries: LicensePlateCountriesState.State;
    addressCountries: AddressCountriesState.State;
    submitResponse: AccountSetupState.SubmitResponse.State;
    badgeTypes: BadgeTypesState.List.State;
    parkOptionSelection: ParkOptionListState.Selection.State;
}

function LineOrNull(props: { text?: string | null }) {
    return props.text ? (
        <span>
            {props.text}
            <br />
        </span>
    ) : null;
}

const submit = AsyncRequest.requestV2(
    Http.postAccountSetup,
    (store: Flux.Store, res: AsyncRequest.Response): string => {
        AccountSetupState.SubmitResponse.setResponse(store, res);
        if (res.statusCode.cls.success) {
            const optionSelection = ParkOptionListState.Selection.get(store);
            logAction(store, 'accountSetup-finish', {
                selectedOption: optionSelection.selectedParkOptionId,
            });
            window.location.href = '/';
        }
        return 'AccountSetup-submit';
    },
);

export const AccountSetupSummary = Flux.selectState<
    object,
    AccountSetupSummaryState
>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
        currentLogin: CurrentLoginState.get(store),
        accountSetupConfiguration: AccountSetupState.Configuration.get(store),
        accountSetupAddress: AccountSetupState.Address.get(store),
        accountSetupBadge: AccountSetupState.Badge.get(store),
        accountSetupLicensePlate: AccountSetupState.LicensePlate.get(store),
        licensePlateCountries: new LicensePlateCountriesState.StateSlice(store)
            .state,
        addressCountries: new AddressCountriesState.StateSlice(store).state,
        badgeTypes: BadgeTypesState.List.get(store),
        submitResponse: AccountSetupState.SubmitResponse.get(store),
        parkOptionSelection: ParkOptionListState.Selection.get(store),
    }),
    p =>
        !p.currentLogin.data ? null : (
            <AccountSetupBlock
                title={navigationTextsFor(p).headerLabel()}
                nextLabel={
                    p.submitResponse.pending
                        ? navigationTextsFor(p).pending()
                        : navigationTextsFor(p).finish()
                }
                noArrow
                onNext={() =>
                    !p.submitResponse.pending &&
                    p.update<Http.SetupData>(submit, {
                        accountType: p.accountSetupConfiguration.accountType,
                        address: p.accountSetupAddress,
                        orderBadge:
                            p.accountSetupConfiguration.orderBadge || false,
                        existingBadge:
                            p.accountSetupBadge.badgeNr &&
                            p.accountSetupBadge.badgeType
                                ? p.accountSetupBadge
                                : null,
                        licensePlate: p.accountSetupLicensePlate,
                        selectedPermitTypeId: null,
                        selectedParkOptionId:
                            p.parkOptionSelection.selectedParkOptionId,
                    })
                }
                previousLabel={navigationTextsFor(p).previous()}
                onPrevious={() =>
                    p.update(store =>
                        AccountSetupState.Navigation.stateWrite(store, {
                            currentStep: 'AddLicensePlate',
                        }),
                    )
                }
            >
                <Para>{texts(p).summaryTitle()}</Para>

                <SummaryTitle>{texts(p).emailBlockLabel()}</SummaryTitle>
                <Para>{p.currentLogin.data.email}</Para>

                <SummaryTitle>{texts(p).addressBlockLabel()}</SummaryTitle>
                <Para>
                    <Conditional
                        c={
                            p.accountSetupConfiguration.accountType ===
                            CustomerAccountType.COMPANY
                        }
                    >
                        <div>
                            <LineOrNull text={p.accountSetupAddress.company1} />
                            <LineOrNull text={p.accountSetupAddress.company2} />
                            <LineOrNull
                                text={p.accountSetupAddress.companyUid}
                            />
                        </div>
                    </Conditional>
                    {`${p.accountSetupAddress.firstName} ${p.accountSetupAddress.lastName}`}
                    <br />
                    {p.accountSetupAddress.street}
                    <br />
                    {`${p.accountSetupAddress.zipCode} ${p.accountSetupAddress.city}`}
                    <br />
                    {AddressCountrySlidein.currentSelection(
                        p,
                        p.accountSetupAddress.country,
                    )}
                    <br />
                </Para>

                <div>
                    <SummaryTitle>{texts(p).vehicleBlockLaber()}</SummaryTitle>
                    <div className={css({ color: Colors.blue })}>
                        <LicensePlateEntityComp
                            licensePlate={{
                                licensePlateNr:
                                    p.accountSetupLicensePlate.licensePlateNr,
                                countryId:
                                    p.accountSetupLicensePlate
                                        .licensePlateCountryId,
                                type: p.accountSetupLicensePlate
                                    .licensePlateType,
                                remarks: p.accountSetupLicensePlate.remark,
                            }}
                        />
                    </div>
                </div>
            </AccountSetupBlock>
        ),
);
