import * as ReactDOM from 'react-dom';
import { css } from '@emotion/css';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../../account/root/components/PortalSlidein';
import { Colors } from 'dg-web-shared/ui/vars';
import {
    SlideInHeaderClose,
    SlideInHeaderTitle,
} from '../../ui/slidein/SlideIn';
import { DesktopBreakpointWidth } from './LandingPageVars';
import React from 'react';

export type PortalOrModalProps<P> = P &
    PortalSlideInProps & { viewportWidth: number };

export function portalOrModalSlideIn<P extends object>(
    Comp: React.ComponentType<PortalOrModalProps<P>>,
) {
    const PortalSlideIn = portalSlideIn(Comp);

    class Modal extends React.Component<PortalOrModalProps<P>> {
        render(): JSX.Element | null {
            const htmlElement = document.getElementById(
                this.props.portal,
            ) as HTMLElement;
            if (htmlElement === null) {
                return null;
            }

            const title = this.props.title;

            return this.props.open
                ? ReactDOM.createPortal(
                      <div
                          className={css({
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              display: 'flex',
                              flexGrow: 0,
                              flexShrink: 0,
                              alignItems: 'center',
                              justifyContent: 'center',
                          })}
                      >
                          <Clickable
                              element="div"
                              onClick={() => this.props.onClose()}
                              className={css({
                                  background: Colors.black,
                                  opacity: 0.6,
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                              })}
                          />
                          <div
                              className={css({
                                  position: 'relative',
                                  background: Colors.white,
                                  maxHeight: '80%',
                                  width: '600px',
                                  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
                                  display: 'flex',
                                  flexDirection: 'column',
                              })}
                          >
                              <div
                                  className={css({
                                      position: 'relative',
                                      height: '48px',
                                      flexShrink: 0,
                                      flexGrow: 0,
                                      background: Colors.blue,
                                      display: 'flex',
                                      alignItems: 'center',
                                  })}
                              >
                                  <SlideInHeaderTitle>
                                      {title}
                                  </SlideInHeaderTitle>
                                  <SlideInHeaderClose
                                      onClose={this.props.onClose}
                                  />
                              </div>

                              <div
                                  className={css({
                                      position: 'relative',
                                      overflowY: 'auto',
                                      overflowX: 'hidden',
                                      flexGrow: 1,
                                  })}
                              >
                                  <Comp {...this.props} />
                              </div>
                          </div>
                      </div>,
                      htmlElement,
                  )
                : null;
        }
    }

    // eslint-disable-next-line react/display-name
    return (p: PortalOrModalProps<P>) => {
        if (p.viewportWidth > DesktopBreakpointWidth) {
            return <Modal {...p} />;
        } else {
            return <PortalSlideIn {...p} />;
        }
    };
}
