import { css } from '@emotion/css';

import * as ReactDOM from 'react-dom';
import {
    getOrElse,
    isDefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2';
import { DivGenerator, HasChildren } from 'dg-web-shared/lib/ReactHelpers';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24';
import { Colors } from 'dg-web-shared/ui/vars';
import { defaultTransition } from 'dg-web-shared/tb-ui/transitions.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import React from 'react';
import { Icon16, Icon24 } from 'dg-web-shared/ui/icons/Icon';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';

interface SlideInContainerProps extends HasChildren {
    open: boolean;
    level?: number;
}

export const SlideInContainer = (p: SlideInContainerProps) => {
    const style = p.level && p.level > 0 ? { zIndex: p.level } : {};
    return (
        <div
            className={css([
                {
                    position: 'absolute',
                    background: Colors.white,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    ...paper(2),
                    ...defaultTransition('transform'),
                },
                !p.open && {
                    ...paper(0),
                    transform: 'translateX(100%)',
                },
            ])}
            style={style}
        >
            {p.children}
        </div>
    );
};

export const SlideInHeaderContainer = (p: {
    background?: 'darkBlue' | 'lightBlue' | undefined;
    children: React.ReactNode;
}) => (
    <div
        className={css({
            position: 'absolute',
            height: 48,
            top: 0,
            left: 0,
            right: 0,
            background:
                p.background === 'darkBlue' ? Colors.darkblue : Colors.blue,
            display: 'flex',
            alignItems: 'center',
        })}
    >
        {p.children}
    </div>
);
export const SlideInHeaderTitle = DivGenerator(
    css({
        ...Typo.heading4,
        flex: 1,
        color: Colors.action_b,
        userSelect: 'text',
        marginLeft: '24px',
    }),
);
export const SlideInContent = DivGenerator(
    css({
        position: 'absolute',
        top: '48px',
        bottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    }),
);
export const SlideInContentHeader = DivGenerator(
    css({
        display: 'flex',
        paddingLeft: '24px',
        height: '40px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        paddingRight: '1024px',
        marginRight: '-1000px',
    }),
);
export const SlideInSearchFieldContainer = DivGenerator(
    css({
        background: Colors.white,
        paddingTop: '8px',
        paddingRight: '24px',
        paddingLeft: '24px',
    }),
);

interface SlideInHeaderCloseProps {
    onClose: ClickHandler;
}

export const SlideInHeaderClose = (p: SlideInHeaderCloseProps) => (
    <Clickable
        element="div"
        className={css({ color: Colors.action_b, width: '48px' })}
        onClick={p.onClose}
    >
        <Icon24 icon={Icons24.clear_b} />
    </Clickable>
);

const SlideInHeader = (p: {
    title: React.ReactNode;
    onClose: ClickHandler;
    hideClose?: boolean;
    headerColor?: 'darkBlue' | 'lightBlue';
    headerButtons?: Maybe<JSX.Element>;
}) => (
    <SlideInHeaderContainer background={p.headerColor}>
        <SlideInHeaderTitle>{p.title}</SlideInHeaderTitle>
        {isDefined(p.headerButtons) && <div>{p.headerButtons}</div>}
        {!p.hideClose && <SlideInHeaderClose onClose={p.onClose} />}
    </SlideInHeaderContainer>
);

interface SlideContentColumnProps extends HasChildren {
    spacingLeft?: Maybe<boolean>;
    spacingRight?: Maybe<boolean>;
}

function getContentFlexColumnStyle(
    spacingLeft: boolean,
    spacingRight: boolean,
) {
    return css([
        spacingLeft && {
            marginLeft: '8px',
        },
        spacingRight && {
            marginRight: '8px',
        },
    ]);
}

interface SlideInContentFlexColumnProps extends SlideContentColumnProps {
    flex?: Maybe<number>;
}

export const SlideInContentFlexColumn = (p: SlideInContentFlexColumnProps) => (
    <div
        className={getContentFlexColumnStyle(
            getOrElse(p.spacingLeft, true),
            getOrElse(p.spacingRight, true),
        )}
        style={{ flex: getOrElse(p.flex, 1) }}
    >
        {p.children}
    </div>
);

interface SlideInContentFixedColumnProps extends SlideContentColumnProps {
    width: number;
}

export const SlideInContentFixedColumn = (
    p: SlideInContentFixedColumnProps,
) => (
    <div
        className={getContentFlexColumnStyle(
            getOrElse(p.spacingLeft, true),
            getOrElse(p.spacingRight, true),
        )}
        style={{ width: `${p.width}px` }}
    >
        {p.children}
    </div>
);

interface SlideInContentHeaderSortableItemProps {
    label: string | JSX.Element;
    active: boolean;
    sortAscending: boolean;
    onClick: () => void;
}

export const SlideInContentHeaderSortableItem = (
    p: SlideInContentHeaderSortableItemProps,
) => (
    <Clickable
        element="div"
        className={css({
            display: 'flex',
            alignItems: 'flex-end',
            color: p.active ? Colors.blue : Colors.rgba(Colors.blue, 0.6),
            borderBottom: `1px solid ${
                p.active ? Colors.blue : Colors.rgba(Colors.blue, 0.25)
            }`,
            minHeight: '40px',
        })}
        onClick={p.onClick}
    >
        <div
            className={css({
                ...Typo.listheader,
                paddingBottom: '2px',
                flex: 1,
            })}
        >
            {p.label}
        </div>
        {p.active ? (
            <div className={css({ paddingBottom: '2px', width: '16px' })}>
                <Icon16
                    icon={
                        p.sortAscending
                            ? Icons16.sort.arrowDown
                            : Icons16.sort.arrowUp
                    }
                />
            </div>
        ) : null}
    </Clickable>
);

interface SlideInContentBodyProps {
    updateScrollPosition?: Maybe<boolean>;
    selectedItemRef?: React.ReactInstance;
    children?: React.ReactNode;
    contentCssClass?: string;
}

interface SlideInContentBodyState {
    isMounted: boolean;
}

export class SlideInContentBody extends React.Component<
    SlideInContentBodyProps,
    SlideInContentBodyState
> {
    static defaultProps: SlideInContentBodyProps = {
        updateScrollPosition: false,
        selectedItemRef: undefined,
    };

    constructor(props: SlideInContentBodyProps) {
        super(props);
        this.state = { isMounted: false };
    }

    componentDidMount(): void {
        this.setState({ isMounted: true });
    }

    componentDidUpdate(prevProps: SlideInContentBodyProps): void {
        if (
            this.state.isMounted &&
            !prevProps.updateScrollPosition &&
            this.props.updateScrollPosition
        ) {
            this.updateScrollPosition();
        }
    }

    updateScrollPosition(): void {
        // ensure children are mounted before using findDOMNode
        if (React.Children.count(this.props.children)) {
            /* tslint:disable:no-string-literal */
            // eslint-disable-next-line react/no-string-refs
            const itemsContainer = this.refs['itemsContainer'];
            /* tslint:enable:no-string-literal */
            if (itemsContainer) {
                // eslint-disable-next-line react/no-find-dom-node
                const itemsContainerEl = ReactDOM.findDOMNode(
                    itemsContainer,
                ) as HTMLElement;
                const selectedItemEl = this.props.selectedItemRef
                    ? // eslint-disable-next-line react/no-find-dom-node
                      (ReactDOM.findDOMNode(
                          this.props.selectedItemRef,
                      ) as HTMLElement)
                    : null;
                if (itemsContainerEl !== null && selectedItemEl !== null) {
                    itemsContainerEl.scrollTop =
                        selectedItemEl.offsetTop -
                        itemsContainerEl.offsetHeight / 2 +
                        2 * selectedItemEl.offsetHeight;
                }
            }
        }
    }

    render() {
        return (
            <div
                className={
                    this.props.contentCssClass
                        ? this.props.contentCssClass
                        : css({
                              flex: 1,
                              overflowX: 'hidden',
                              overflowY: 'auto',
                              WebkitOverflowScrolling: 'touch',
                              background: Colors.white,
                              // tabindex will create a focus border
                              // on chrome and safari. this disables it
                              '&:focus': {
                                  outline: 'none',
                              },
                          })
                }
                // eslint-disable-next-line react/no-string-refs
                ref="itemsContainer"
                tabIndex={-1}
            >
                {this.props.children}
            </div>
        );
    }
}

interface SlideInContentItemProps extends HasChildren {
    onClick: () => void;
    selected: boolean;
    disabled?: boolean;
}

export class SlideInContentItem extends React.Component<SlideInContentItemProps> {
    render() {
        return (
            <Clickable
                element="div"
                className={css({
                    minHeight: '48px',
                    padding: '13px 24px',
                    display: 'flex',
                    color: this.props.selected
                        ? Colors.action_b
                        : Colors.action_w,
                    background: this.props.selected
                        ? Colors.lightblue
                        : Colors.white,
                })}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                {this.props.children}
            </Clickable>
        );
    }
}

interface SlideInContentItemTextProps extends HasChildren {
    textAlign?: Maybe<'left' | 'center' | 'right'>;
    isSmall?: boolean;
}

export const SlideInContentItemText = (p: SlideInContentItemTextProps) => (
    <div
        className={css([
            { ...Typo.body },
            p.isSmall && {
                ...Typo.caption,
                paddingTop: '3px',
            },
        ])}
        style={thenElse(p.textAlign, ta => ({ textAlign: ta }), {} as object)}
    >
        {p.children}
    </div>
);
export const SlideInContentItemTextSmall = (p: SlideInContentItemTextProps) => (
    <SlideInContentItemText isSmall={true} {...p} />
);

export interface SlideInProps {
    open: boolean;
    title: React.ReactNode;
    onClose: ClickHandler;
    hideClose?: boolean;
    headerColor?: 'darkBlue' | 'lightBlue';
    contentCssClass?: string;
    children?: React.ReactNode;
    headerButtons?: Maybe<JSX.Element>;
    selectedItemRef?: React.ReactInstance;
    contentHeader?: JSX.Element;
    overlay?: React.ReactNode;
    level?: number;
}

export const SlideIn = (p: SlideInProps) => (
    <SlideInContainer open={p.open} level={p.level}>
        <SlideInHeader {...p} />
        <SlideInContent>
            {p.contentHeader}
            <SlideInContentBody
                contentCssClass={p.contentCssClass}
                updateScrollPosition={isDefined(p.selectedItemRef)}
                selectedItemRef={p.selectedItemRef}
            >
                {p.children}
            </SlideInContentBody>
        </SlideInContent>
        {p.overlay}
    </SlideInContainer>
);
