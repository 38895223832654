import { useState } from 'react';
import { Localized } from '../../common/components/Localized';
import { css } from '@emotion/css';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import { Colors } from 'dg-web-shared/ui/vars';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { MaterialButton } from '../../ui/inputs/MaterialButton';
import {
    getFilteredParkingsByState,
    LprParking,
} from 'dg-web-shared/common/models/CustomerLprZoneSelection';
import { Icon } from 'dg-web-shared/ui/icons/Icon';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const LprParkingsSelectionSlideIn = portalSlideIn<
    {
        parkings: LprParking[];
        setNewlyActivatedZoneIds: React.Dispatch<
            React.SetStateAction<number[]>
        >;
        setNewlyDeactivatedZoneIds: React.Dispatch<
            React.SetStateAction<number[]>
        >;
        newlyActivatedZoneIds: number[];
        newlyDeactivatedZoneIds: number[];
    } & PortalSlideInProps
>(LprParkingsSelection);

function LprParkingsSelection({
    onClose,
    parkings,
    setNewlyActivatedZoneIds,
    setNewlyDeactivatedZoneIds,
    newlyActivatedZoneIds,
    newlyDeactivatedZoneIds,
}: {
    onClose: () => void;
    parkings: LprParking[];
    setNewlyActivatedZoneIds: React.Dispatch<React.SetStateAction<number[]>>;
    setNewlyDeactivatedZoneIds: React.Dispatch<React.SetStateAction<number[]>>;
    newlyActivatedZoneIds: number[];
    newlyDeactivatedZoneIds: number[];
}) {
    return (
        <ParkingsSelectionContainer>
            <>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                    })}
                >
                    <MaterialButton
                        primary
                        label={
                            <Localized
                                de="Zurück"
                                fr="Retour"
                                it="Indietro"
                                en="Back"
                            />
                        }
                        onClick={onClose}
                    />
                </div>
                <LprParkingsSelectionContent
                    parkings={parkings}
                    setNewlyActivatedZoneIds={setNewlyActivatedZoneIds}
                    setNewlyDeactivatedZoneIds={setNewlyDeactivatedZoneIds}
                    newlyActivatedZoneIds={newlyActivatedZoneIds}
                    newlyDeactivatedZoneIds={newlyDeactivatedZoneIds}
                />
            </>
        </ParkingsSelectionContainer>
    );
}

function LprParkingsSelectionContent({
    parkings,
    setNewlyActivatedZoneIds,
    setNewlyDeactivatedZoneIds,
    newlyActivatedZoneIds,
    newlyDeactivatedZoneIds,
}: {
    parkings: LprParking[];
    setNewlyActivatedZoneIds: React.Dispatch<React.SetStateAction<number[]>>;
    setNewlyDeactivatedZoneIds: React.Dispatch<React.SetStateAction<number[]>>;
    newlyActivatedZoneIds: number[];
    newlyDeactivatedZoneIds: number[];
}) {
    const [activeParkings, notAvailableAnymoreParkings, availableParkings] =
        getFilteredParkingsByState(parkings);
    return (
        <>
            {activeParkings.length > 0 && (
                <div>
                    <ParkingsSectionHeader>
                        <Localized
                            de="AKTIVE PARKHÄUSER"
                            fr="PARKINGS ACTIFS"
                            it="PARCHEGGI ATTIVI"
                            en="ACTIVE PARKINGS"
                        />
                    </ParkingsSectionHeader>
                    {activeParkings.map(parking => {
                        return (
                            <ParkingSelectionRow
                                key={parking.zoneId}
                                parking={parking}
                                selected={
                                    !newlyDeactivatedZoneIds.find(
                                        id => parking.zoneId === id,
                                    )
                                }
                                setSelectedParking={(zoneId, isActive) => {
                                    if (!isActive) {
                                        setNewlyDeactivatedZoneIds(zoneIds => [
                                            ...zoneIds,
                                            zoneId,
                                        ]);
                                    } else {
                                        setNewlyDeactivatedZoneIds(zoneIds =>
                                            zoneIds.filter(id => id !== zoneId),
                                        );
                                    }
                                }}
                            />
                        );
                    })}
                </div>
            )}
            <p>
                <Localized
                    de="Wenn ein Parking 6 Monate lang nicht mit Kennzeichenerkennung genutzt wird, wird es automatisch deaktiviert."
                    fr="Si un parking n'est pas utilisé avec la reconnaissance de plaque pendant 6 mois, il est automatiquement désactivé."
                    it="Se per 6 mesi un parcheggio non viene utilizzato con il riconoscimento della targa, viene automaticamente disattivato."
                    en="If a parking is not used with license plate recognition for 6 months, it is automatically deactivated."
                />
            </p>
            {notAvailableAnymoreParkings.length > 0 && (
                <div>
                    <ParkingsSectionHeader>
                        <Localized
                            de="NICHT MEHR VERFÜGBAR"
                            fr="PLUS DISPONIBLE"
                            it="NON PIÙ DISPONIBILE"
                            en="NO MORE AVAILABLE"
                        />
                    </ParkingsSectionHeader>
                    {notAvailableAnymoreParkings.map(parking => {
                        return (
                            <ParkingSelectionRow
                                key={parking.zoneId}
                                parking={parking}
                                selected={false}
                                setSelectedParking={null}
                            />
                        );
                    })}
                </div>
            )}
            <ParkingsSectionHeader>
                <Localized
                    de="VERFÜGBARE PARKHÄUSER"
                    fr="PARKINGS DISPONIBLES"
                    it="PARCHEGGI DISPONIBILI"
                    en="AVAILABLE PARKINGS"
                />
            </ParkingsSectionHeader>
            {availableParkings.map(parking => {
                return (
                    <ParkingSelectionRow
                        key={parking.zoneId}
                        parking={parking}
                        selected={Boolean(
                            newlyActivatedZoneIds.find(
                                id => parking.zoneId === id,
                            ),
                        )}
                        setSelectedParking={(zoneId, isActive) => {
                            if (isActive) {
                                setNewlyActivatedZoneIds(zoneIds => [
                                    ...zoneIds,
                                    zoneId,
                                ]);
                            } else {
                                setNewlyActivatedZoneIds(zoneIds =>
                                    zoneIds.filter(id => id !== zoneId),
                                );
                            }
                        }}
                    />
                );
            })}
        </>
    );
}

function ParkingSelectionRow({
    parking,
    selected,
    setSelectedParking,
}: {
    parking: LprParking;
    selected: boolean;
    setSelectedParking: ((zoneId: number, isActive: boolean) => void) | null;
}) {
    const [isSelected, setIsSelected] = useState(selected);
    return (
        <div
            className={css({
                display: 'flex',
                height: '40px',
                overflow: 'hidden',
                justifyContent: 'space-between',
                overflowWrap: 'break-word',
                width: '100%',
            })}
        >
            <InlineToggle
                parking={parking}
                selected={isSelected}
                onClick={() => {
                    const newState = !isSelected;
                    if (setSelectedParking) {
                        setSelectedParking(parking.zoneId, newState);
                    }
                    setIsSelected(newState);
                }}
                disabled={setSelectedParking == null}
            />
        </div>
    );
}

function InlineToggle({
    parking,
    selected,
    onClick,
    disabled,
}: {
    parking: LprParking;
    selected: boolean;
    onClick: () => void;
    disabled: boolean;
}) {
    return (
        <Clickable
            element="div"
            onClick={onClick}
            disabled={disabled}
            className={css({
                display: 'flex',
                alignItems: 'center',
                overflowWrap: 'break-word',
                width: '100%',
                opacity: disabled ? 0.4 : 1,
            })}
        >
            <ToggleIcon selected={selected} />
            <div
                className={css({
                    paddingLeft: '10px',
                    overflow: 'hidden',
                    flex: 1,
                })}
            >
                <div
                    className={css({
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        fontWeight: 'bold',
                    })}
                >
                    {parking.name}
                </div>
            </div>
            <div>{parking.city}</div>
        </Clickable>
    );
}

export function ToggleIcon({ selected }: { selected: boolean }) {
    return <Icon icon={selected ? 'checkbox' : 'checkboxOutline'} />;
}

function ParkingsSelectionContainer({ children }: { children: JSX.Element }) {
    return (
        <div
            className={css({
                backgroundColor: Colors.white,
                padding: '0 24px',
                color: Colors.darkblue,
                ...Typo.robotoMedium,
            })}
        >
            {children}
        </div>
    );
}

function ParkingsSectionHeader({ children }: { children: JSX.Element }) {
    return (
        <div
            className={css({
                marginTop: '10px',
            })}
        >
            <b>{children}</b>
            <hr className={css({ borderColor: Colors.darkblue })} />
        </div>
    );
}
