import { css, keyframes } from '@emotion/css';
import { DateTime, Duration } from 'luxon';

import { calculatePriceInChfFromCurve } from 'dg-web-shared/common/tariff-logic/PriceFromCurve';
import { Slope } from 'dg-web-shared/common/tariff-logic/Tariff';
import { selectState, Updater } from 'dg-web-shared/lib/Flux';
import { Language, Message } from 'dg-web-shared/lib/Localized';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import { numberToPrice } from 'dg-web-shared/lib/NumberFormatter';
import {
    absoluteMousePosition,
    absoluteTouchPosition,
    calculateAngleBetweenPoints,
    calculateRadialPosition,
    Point,
} from 'dg-web-shared/lib/WheelHelpers';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import * as LayoutState from '../../layout/state/LayoutState';
import { ResponsiveProperties } from '../../layout/utils/ResponsiveProperties';
import { Colors } from 'dg-web-shared/ui/vars';
import { durationTexts } from '../i18n/GeneralTexts';
import { parkDurationWheelTexts } from '../i18n/ParkDurationWheelTexts';
import { Localized } from './Localized';
import { CheckinDataState } from '../../park-create/components/zone-transaction/ZoneEnforcedCheckin';
import { ButtonText, ModalInfoBox } from '../../ui/modals/Confirmable';
import React, { useState } from 'react';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import infoButtonIconBits from '../../../assets/info_button_darkblue.svg';
import SurchargeData = CheckinDataState.SurchargeData;

export interface MovementResponse {
    coordinates: Point;
    pressed: boolean;
    radianChange: number;
}

export interface ParkDurationWheelTexts {
    endsAtDate: Message;
    endsAtTime: Message;
    price: Message;
    selectDuration: Message;
    maxParkTime: Message;
}

export enum WheelStyle {
    parkingpay = 'parkingpay',
    parkingpay_inverted = 'parkingpay_inverted',
}

export interface ParkDurationWheelProps {
    exactMinutesWithFraction: number | null;
    tariffCurve: Slope[];
    tariffName: string | null;
    maxMinutes: number;
    minMinutes: number;
    minChfStep: number;
    setMinutes: (minutes: number) => void;
    setMin: () => void;
    setMax: () => void;
    start: DateTime;
    currentTimeRef: DateTime;
    compactHeight: number;
    surchargeData: SurchargeData[];
    style: WheelStyle;
    isMaxDurationReducedByTwint?: boolean;
}

function calcData(p: ParkDurationWheelProps): CalculationResult | null {
    if (p.exactMinutesWithFraction == null || p.tariffCurve.length === 0) {
        return null;
    }
    const minutes = Math.ceil(p.exactMinutesWithFraction);
    const fixedSurcharge = p.surchargeData.find(
        value => value.type === 'amount',
    );
    const variableSurcharge = p.surchargeData.find(
        value => value.type === 'percent',
    );
    const price = calculatePriceInChfFromCurve(
        p.exactMinutesWithFraction,
        p.tariffCurve,
        p.minChfStep,
    );
    const variableSurchargePercent = variableSurcharge
        ? variableSurcharge.value
        : 0.0;
    const variableSurchargeAmount = price * variableSurchargePercent;

    const fixedSurchargeAmount =
        fixedSurcharge &&
        price > 0 &&
        price + variableSurchargeAmount + fixedSurcharge.value <
            20 + fixedSurcharge.value
            ? fixedSurcharge.value
            : 0.0;

    let combinedSurchargeAmount = 0.0;
    let finalPrice = price + variableSurchargeAmount;
    combinedSurchargeAmount += variableSurchargeAmount;

    finalPrice += fixedSurchargeAmount;
    combinedSurchargeAmount += fixedSurchargeAmount;

    return {
        minutes,
        price: finalPrice,
        priceWithoutSurcharge: finalPrice - combinedSurchargeAmount,
        fixedSurcharge: fixedSurchargeAmount,
        variableSurcharge: variableSurchargeAmount,
        variableSurchargePercent: variableSurchargePercent,
        surcharge: combinedSurchargeAmount,
    };
}

const BigFontStyles = (height?: number) =>
    css({
        ...Typo.robotoBold,
        fontSize: '32px',
        lineHeight: '1',
        [`@media(max-height: ${height || 0}px)`]: {
            fontSize: '24px',
        },
    });

/*
we reinvent labeled elements here. The global thing does not work with align right, flex, plus
a smaller width body than label (small price)
*/
const Label = (p: {
    children?: JSX.Element;
    rightAlign: boolean;
    style: WheelStyle;
}) => {
    return (
        <label
            className={css({
                ...Typo.robotoRegular,
                fontSize: '13px',
                color: Style.secondaryTextColor(p.style),
                lineHeight: '1.23',
                letterSpacing: '0.3px',
                display: 'block',
                textAlign: p.rightAlign ? 'right' : 'left',
                opacity: '0.7',
            })}
        >
            {p.children}
        </label>
    );
};

export const ParkDurationWheel = selectState<
    ParkDurationWheelProps,
    {
        layout: LayoutState.State;
    }
>(
    s => ({
        layout: new LayoutState.StateSlice(s).state,
    }),
    p => {
        const isMobile = new ResponsiveProperties(p).mobile;
        const data = calcData(p);

        return <WheelSelectionLayout data={data} isMobile={isMobile} {...p} />;
    },
);

type CalculationResult = {
    minutes: number;
    price: number;
    priceWithoutSurcharge: number;
    fixedSurcharge: number;
    variableSurcharge: number;
    variableSurchargePercent: number;
    surcharge: number;
};

type LayoutProps = ParkDurationWheelProps & {
    isMobile: boolean;
    data: CalculationResult | null;
    style: WheelStyle;
    update: Updater;
};

const WheelSelectionLayout = (p: LayoutProps) => {
    return (
        <div
            className={css({
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                position: 'relative',
                color: Style.primaryTextColor(p.style),
            })}
        >
            <EndAndPriceInfo {...p} />
            <div
                className={css({
                    margin: '22px auto 0',
                    width: '100%',
                    [`@media(max-height: ${p.compactHeight}px)`]: {
                        margin: '12px auto 0',
                    },
                    maxWidth: p.isMobile ? '50vh' : '33vh',
                })}
            >
                <Wheel
                    selectedMinutes={p.data ? p.data.minutes : null}
                    start={p.start}
                    maxReached={p.data ? p.data.minutes >= p.maxMinutes : false}
                    isMaxDurationReducedByTwint={p.isMaxDurationReducedByTwint}
                    minReached={p.data ? p.data.minutes <= p.minMinutes : false}
                    currentTimeRef={p.currentTimeRef}
                    setMin={() => p.setMin()}
                    setMax={() => p.setMax()}
                    onMove={d => {
                        if (!d) {
                            return;
                        }
                        const newMins =
                            (p.exactMinutesWithFraction || 0) +
                            (d.radianChange / (2 * Math.PI)) * 60;
                        const possibleMinutes = Math.max(
                            p.minMinutes,
                            Math.min(newMins, p.maxMinutes),
                        );
                        p.setMinutes(possibleMinutes);
                    }}
                    style={p.style}
                    update={p.update}
                />
            </div>
        </div>
    );
};

const EndAndPriceInfo = (p: {
    data: CalculationResult | null;
    start: DateTime;
    compactHeight: number | undefined;
    style: WheelStyle;
}) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div
            className={css({
                display: 'flex',
                justifyContent: 'space-between',
                height: '34px',
            })}
        >
            <End
                start={p.start}
                minutes={p.data ? p.data.minutes : null}
                compactHeight={p.compactHeight}
                style={p.style}
            />
            <div className={css({ width: '60%' })}>
                <Label style={p.style} rightAlign={true}>
                    <Localized {...parkDurationWheelTexts.price} />
                </Label>
                <div
                    className={css({
                        display: 'flex',
                        justifyContent: 'flex-end',
                    })}
                >
                    {p.data && p.data.surcharge > 0 && (
                        <a
                            className={css({
                                marginTop: '4px',
                                marginRight: '8px',
                                cursor: 'pointer',
                            })}
                        >
                            <img
                                src={infoButtonIconBits}
                                onClick={() => setShowDetails(true)}
                            />
                        </a>
                    )}
                    <div
                        className={css(BigFontStyles(p.compactHeight), {
                            textAlign: 'right',
                        })}
                    >
                        {!p.data ? '–.–' : numberToPrice(p.data.price, false)}
                    </div>
                </div>
            </div>
            {showDetails && (
                <ModalInfoBox
                    confirmCaption={ButtonText.CLOSE}
                    confirmCallback={setShowDetails.bind(null, false)}
                    titleCaption={
                        <Localized
                            de="Preisdetails"
                            fr="Détails du prix"
                            it="Dettagli prezzo"
                            en="Price details"
                        />
                    }
                >
                    {p.data && <CostDetails prices={p.data} />}
                </ModalInfoBox>
            )}
        </div>
    );
};

function CostDetails(p: { prices: CalculationResult }) {
    const variablePercentText = NumberFormatter.format(
        Language.DE,
        p.prices.variableSurchargePercent,
        '0.00%',
    );
    return (
        <div
            className={css({
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '15px 0px',
            })}
        >
            <table
                className={css({
                    width: '100%',
                })}
            >
                <tbody>
                    <tr>
                        <td className={css({ textAlign: 'left' })}>
                            <Localized
                                de="Parkgebühr"
                                fr="Taxe de stationnement"
                                it="Tassa di parcheggio"
                                en="Parking Fee"
                            />
                        </td>

                        <td
                            className={css({
                                textAlign: 'right',
                                verticalAlign: 'bottom',
                            })}
                        >
                            {NumberFormatter.numberToPrice(
                                p.prices.priceWithoutSurcharge,
                            )}
                        </td>
                    </tr>

                    {!!p.prices.variableSurcharge && (
                        <tr>
                            <td
                                className={css({
                                    textAlign: 'left',
                                    height: '25px',
                                    verticalAlign: 'bottom',
                                })}
                            >
                                <Localized
                                    de="App-Nutzung"
                                    fr="Usage appli"
                                    it="Utilizzo app"
                                    en="App usage"
                                />
                                {` (${variablePercentText})`}
                            </td>

                            <td
                                className={css({
                                    textAlign: 'right',
                                    verticalAlign: 'bottom',
                                })}
                            >
                                {NumberFormatter.numberToPrice(
                                    p.prices.variableSurcharge,
                                )}
                            </td>
                        </tr>
                    )}

                    {!!p.prices.fixedSurcharge && (
                        <tr>
                            <td
                                className={css({
                                    textAlign: 'left',
                                    height: '25px',
                                    verticalAlign: 'bottom',
                                })}
                            >
                                <Localized
                                    de="Einzelzahlung"
                                    fr="Paiement individuel"
                                    it="Pagamento singolo"
                                    en="Individual payment"
                                />
                            </td>

                            <td
                                className={css({
                                    textAlign: 'right',
                                    verticalAlign: 'bottom',
                                })}
                            >
                                {NumberFormatter.numberToPrice(
                                    p.prices.fixedSurcharge,
                                )}
                            </td>
                        </tr>
                    )}

                    <tr className={css({ fontWeight: 600 })}>
                        <td
                            className={css({
                                textAlign: 'left',
                                verticalAlign: 'bottom',
                                minWidth: '180px',
                                height: '32px',
                            })}
                        >
                            <Localized
                                de="Total"
                                fr="Total"
                                it="Totale"
                                en="Total"
                            />
                        </td>

                        <td
                            className={css({
                                textAlign: 'right',
                                verticalAlign: 'bottom',
                            })}
                        >
                            {NumberFormatter.numberToPrice(
                                p.prices.priceWithoutSurcharge +
                                    p.prices.surcharge,
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function End(p: {
    start: DateTime;
    minutes: number | null;
    compactHeight: number | undefined;
    style: WheelStyle;
}) {
    const end = p.start.plus({ minutes: p.minutes || 0 });
    const now = DateTime.local();
    const timeStr = end.toLocaleString(DateTime.TIME_24_SIMPLE);

    const dateStr =
        end.hasSame(now, 'day') &&
        end.hasSame(now, 'month') &&
        end.hasSame(now, 'year')
            ? null
            : end.toFormat(LuxonDateStringFormats.DATE);

    return (
        <div>
            <Label style={p.style} rightAlign={false}>
                <Localized
                    {...(dateStr
                        ? parkDurationWheelTexts.endsAtDate
                        : parkDurationWheelTexts.endsAtTime)}
                />
            </Label>
            <div className={BigFontStyles(p.compactHeight)}>
                {!p.minutes ? (
                    '–:–'
                ) : (
                    <div>
                        <div>{dateStr ? dateStr : timeStr}</div>
                        {dateStr && (
                            <div
                                className={css({
                                    fontSize: '14px',
                                    letterSpacing: '0.3px',
                                })}
                            >
                                {timeStr}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

interface WheelProps {
    children?: JSX.Element;
    selectedMinutes: number | null;
    start: DateTime;
    currentTimeRef: DateTime;
    maxReached: boolean;
    isMaxDurationReducedByTwint?: boolean;
    minReached: boolean;
    onMove: (response: MovementResponse | null) => void;
    onMoveEnd?: (response: MovementResponse | null) => void;
    setMin: () => void;
    setMax: () => void;
    style: WheelStyle;
    update: Updater;
}

interface Move {
    epoch: number;
    rad: number;
}

interface WheelState {
    recentlyMounted: boolean;
    wasTouched: boolean;
    lastMoves: Move[];
    currentPositionRadian: number;
    showTwintTimeLimitInfo: boolean;
}

export class Wheel extends React.Component<WheelProps, WheelState> {
    public state: WheelState = {
        wasTouched: false,
        recentlyMounted: true,
        lastMoves: [],
        currentPositionRadian: 0,
        showTwintTimeLimitInfo: false,
    };

    private container: HTMLDivElement | null | undefined;

    private coordinates: Point | null | undefined;

    public constructor(props: WheelProps) {
        super(props);
    }

    private get radius(): number {
        const containerRect = this.container!.getBoundingClientRect();
        return containerRect.width;
    }

    private get center(): Point {
        const containerRect = this.container!.getBoundingClientRect();
        return { x: containerRect.width / 2, y: containerRect.height / 2 };
    }

    public componentDidMount() {
        this.coordinates = null;
        setTimeout(() => {
            this.setState({ recentlyMounted: false });
        }, 1750);
    }

    public render() {
        const duration = minutesToDurationString(
            this.props.start,
            this.props.currentTimeRef,
            this.props.selectedMinutes,
        );
        return (
            <div
                className={css({
                    position: 'relative',
                    width: '100%',
                    overflow: 'hidden',
                    touchAction: 'none',
                })}
            >
                <div
                    className={css({
                        position: 'relative',
                        margin: '0 15px',
                    })}
                    ref={el => (this.container = el)}
                >
                    <WheelCircle
                        rotateRadian={this.state.currentPositionRadian}
                        onTouchStart={this.handleTouchStart}
                        wasTouched={this.state.wasTouched}
                        recentlyMounted={this.state.recentlyMounted}
                        onMouseDown={this.handleMouseDown}
                        arrowType={
                            this.props.maxReached
                                ? ArrowType.counterClockwise
                                : ArrowType.clockwise
                        }
                        style={this.props.style}
                    />
                    <div
                        className={css({
                            ...Typo.robotoBold,
                            color: Style.secondaryTextColor(this.props.style),
                            fontSize: '16px',
                            letterSpacing: '0.3px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            maxWidth: '60%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            padding: '3px',
                            borderRadius: duration ? '3px' : 0,
                        })}
                    >
                        {!this.props.selectedMinutes ? (
                            <Localized
                                {...parkDurationWheelTexts.selectDuration}
                            />
                        ) : (
                            <>
                                {duration} <br />
                                {this.props.maxReached && (
                                    <>
                                        <Localized
                                            {...parkDurationWheelTexts.maxParkTime}
                                        />
                                        <br />
                                        {this.props
                                            .isMaxDurationReducedByTwint && (
                                            <img
                                                className={css({
                                                    paddingTop: '6px',
                                                    cursor: 'pointer',
                                                })}
                                                src={infoButtonIconBits}
                                                onClick={() =>
                                                    this.setState({
                                                        showTwintTimeLimitInfo:
                                                            true,
                                                    })
                                                }
                                            />
                                        )}
                                        {this.state.showTwintTimeLimitInfo && (
                                            <ModalInfoBox
                                                confirmCaption={ButtonText.OK}
                                                confirmCallback={() =>
                                                    this.setState({
                                                        showTwintTimeLimitInfo:
                                                            false,
                                                    })
                                                }
                                                titleCaption={
                                                    <Localized
                                                        de="TWINT Einschränkung"
                                                        fr="Limitation TWINT"
                                                        it="Limitazione TWINT"
                                                        en="TWINT limitation"
                                                    />
                                                }
                                            >
                                                <p>
                                                    <Localized
                                                        de="Aus technischen Gründen können TWINT-Transaktionen maximal 6 Tage und 16 Stunden lang offen bleiben."
                                                        fr="Pour des raisons techniques, les transactions TWINT peuvent rester ouvertes pendant un maximum de 6 jours et 16 heures."
                                                        it="Per motivi tecnici le transazioni TWINT possono rimanere aperte al massimo 6 giorni e 16 ore."
                                                        en="For technical reasons, TWINT transactions can remain open for a maximum of 6 days and 16 hours."
                                                    />
                                                </p>
                                                <p>
                                                    <Localized
                                                        de="Die Höchstparkzeit, die für diese Zone zur Verfügung steht, wurde daher entsprechend angepasst."
                                                        fr="La durée maximale disponible pour cette zone a donc été ajustée en conséquence."
                                                        it="La durata massima disponibile per questa zona à stata quindi adattata di conseguenza."
                                                        en="The maximum duration available for this zone has therefore been adjusted accordingly."
                                                    />
                                                </p>
                                            </ModalInfoBox>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {!this.props.minReached && this.props.selectedMinutes && (
                        <Clickable
                            element="div"
                            onClick={() => {
                                this.setState({
                                    currentPositionRadian: this.state
                                        .currentPositionRadian
                                        ? 0
                                        : Math.PI,
                                });
                                this.props.setMin();
                            }}
                            className={css({
                                position: 'absolute',
                                padding: '12px',
                                top: -12,
                                left: -12,
                            })}
                        >
                            <Min style={this.props.style} />
                        </Clickable>
                    )}
                    {!this.props.maxReached && (
                        <Clickable
                            element="div"
                            onClick={() => {
                                this.setState({
                                    currentPositionRadian: this.state
                                        .currentPositionRadian
                                        ? 0
                                        : Math.PI,
                                });
                                this.props.setMax();
                            }}
                            className={css({
                                position: 'absolute',
                                padding: '12px',
                                top: -12,
                                right: -12,
                            })}
                        >
                            <Max style={this.props.style} />
                        </Clickable>
                    )}
                </div>
            </div>
        );
    }

    private moveListenerArgs = (isTouch: boolean) => ({
        moveEventType: isTouch ? 'touchmove' : 'mousemove',
        moveHandler: isTouch ? this.handleTouchMove : this.handleMouseMove,
    });

    private endListenerArgs = (isTouch: boolean) => ({
        endEventType: isTouch ? 'touchend' : 'mouseup',
        endHandler: isTouch ? this.handleTouchEnd : this.handleMouseUp,
    });

    private addEventListeners = (isTouch: boolean) => {
        this.setState({ lastMoves: [] });
        const { moveEventType, moveHandler } = this.moveListenerArgs(isTouch);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        document.addEventListener(moveEventType, moveHandler as any, {
            passive: false,
        });

        const { endEventType, endHandler } = this.endListenerArgs(isTouch);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        document.addEventListener(endEventType, endHandler as any, {
            passive: false,
        });
    };

    private removeEventListeners = (isTouch: boolean) => {
        this.setState({ lastMoves: [] });
        const { moveEventType, moveHandler } = this.moveListenerArgs(isTouch);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        document.removeEventListener(moveEventType, moveHandler as any);

        const { endEventType, endHandler } = this.endListenerArgs(isTouch);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        document.removeEventListener(endEventType, endHandler as any);
    };

    private handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        this.addEventListeners(false);
        this.coordinates = null;
        this.setState({ wasTouched: true });
        this.getMovementData(absoluteMousePosition(e), true);
    };

    private handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        this.addEventListeners(true);
        this.coordinates = null;
        this.setState({ wasTouched: true });
        this.getMovementData(absoluteTouchPosition(e), true);
    };

    private handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        this.removeEventListeners(false);
        if (this.props.onMoveEnd) {
            this.props.onMoveEnd(
                this.getMovementData(absoluteMousePosition(e), false)!,
            );
        }
    };

    private handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        this.removeEventListeners(true);
        if (this.props.onMoveEnd) {
            this.props.onMoveEnd(
                this.getMovementData(absoluteTouchPosition(e), false)!,
            );
        }
    };

    private handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        requestAnimationFrame(() => {
            this.props.onMove(
                this.getMovementData(absoluteMousePosition(e), true)!,
            );
        });
    };

    private handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        requestAnimationFrame(() => {
            this.props.onMove(
                this.getMovementData(absoluteTouchPosition(e), true)!,
            );
        });
    };

    private getMovementData = (
        position: Point,
        pressed = false,
    ): MovementResponse | null => {
        if (!this.container) {
            return null;
        }
        const coordinates = calculateRadialPosition(
            this.container,
            this.center,
            this.radius,
            position,
        );

        let result: MovementResponse | null = null;
        if (this.coordinates) {
            const radianChange = calculateAngleBetweenPoints(
                this.center,
                this.coordinates,
                coordinates,
            );
            const moves = this.state.lastMoves.slice();
            moves.push({
                epoch: Date.now(),
                rad: Math.abs(radianChange),
            });
            if (moves.length > 120) {
                moves.shift();
            }
            const radianChangeWithMultiplier =
                radianChange * calculateMultiplier(moves);
            this.setState({
                // eslint-disable-next-line react/no-direct-mutation-state
                currentPositionRadian: (this.state.currentPositionRadian +=
                    radianChange),
                lastMoves: moves,
            });
            result = {
                coordinates,
                pressed,
                radianChange: radianChangeWithMultiplier,
            };
        }

        this.coordinates = coordinates;
        return result;
    };
}

function minutesToDurationString(
    start: DateTime,
    currentTimeRef: DateTime,
    minutes: number | null,
): JSX.Element | null {
    if (minutes === null) {
        return null;
    }
    const duration = start
        .plus({ minutes: minutes })
        .diff(currentTimeRef, ['minute', 'hour']);

    return getFormattedDuration(duration);
}

export function getFormattedDuration(duration: Duration): JSX.Element | null {
    if (duration.hours && duration.hours >= 48) {
        return (
            <span>
                {`> 2 `}
                <Localized {...durationTexts.daysShort} />
            </span>
        );
    }

    const hours = duration.hours ? (
        <span>
            {duration.hours}
            {'\u00A0'}
            <Localized {...durationTexts.hoursShort} />
        </span>
    ) : (
        <span />
    );
    const minute = duration.minutes ? (
        <span>
            {Math.round(duration.minutes)}
            {'\u00A0'}
            <Localized {...durationTexts.minutesShort} />
        </span>
    ) : (
        <span />
    );

    return (
        <span>
            {hours} {minute}
        </span>
    );
}

const calculateMultiplier = (moves: Move[]): number => {
    if (moves.length <= 2) {
        return 1;
    }
    const thisMove = moves[moves.length - 1];
    const lastMove = moves[moves.length - 2];
    if (speed(thisMove.rad, lastMove.epoch) < 1.5) {
        return 1;
    }

    let totalMove = 0;
    for (let i = moves.length; i--; ) {
        const m = moves[i];
        totalMove += m.rad;
        if (Date.now() - m.epoch > 1500) {
            if (speed(totalMove, m.epoch) > 5) {
                return 3;
            }
            break;
        }
    }
    return 1;
};

const speed = (change: number, startEpoch: number): number => {
    return Math.abs(change) / ((new Date().getTime() - startEpoch) / 1000);
};

interface SvgProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onMouseDown: (e: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTouchStart: (e: any) => void;
    rotateRadian: number;
    wasTouched: boolean;
    recentlyMounted: boolean;
    onButtonClick?: () => void;
    arrowType: ArrowType;
    style: WheelStyle;
}

const WheelCircle = (p: SvgProps) => (
    <svg viewBox="0 0 224 224" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient
                x1="50%"
                y1="100%"
                x2="50%"
                y2="0%"
                id="linearGradient-3"
            >
                <stop stopColor={Style.gradient3Offset0(p.style)} offset="0%" />
                <stop
                    stopColor={Style.gradient3Offset100(p.style)}
                    offset="100%"
                />
            </linearGradient>
            <linearGradient
                x1="50%"
                y1="100%"
                x2="50%"
                y2="0%"
                id="linearGradient-4"
            >
                <stop stopColor={Style.gradient4Offset0(p.style)} offset="0%" />
                <stop
                    stopColor={Style.gradient4Offset100(p.style)}
                    offset="100%"
                />
            </linearGradient>
            <linearGradient
                x1="50%"
                y1="100%"
                x2="50%"
                y2="0%"
                id="linearGradient-5"
            >
                <stop stopColor={Style.gradient5Offset0(p.style)} offset="0%" />
                <stop
                    stopColor={Style.gradient5Offset41(p.style)}
                    offset="41.2558066%"
                />
                <stop
                    stopColor={Style.gradient5Offset100(p.style)}
                    stopOpacity="0"
                    offset="100%"
                />
            </linearGradient>
        </defs>
        <g
            id="Comp/Time-Wheel/Control/Gradient_Mask_NEG"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            onMouseDown={p.onMouseDown}
            onTouchStart={p.onTouchStart}
            transform={`rotate(${(p.rotateRadian * 180) / Math.PI} 112 112)`}
        >
            <mask id="mask-2" fill="white">
                <path
                    d="M112,224 C50.144108,224 0,173.855892 0,112 C0,50.144108 50.144108,0 112,0 C173.855892,0 224,50.144108 224,112 C224,173.855892 173.855892,224 112,224 Z M111.941176,176.882353 C147.807198,176.882353 176.882353,147.807198 176.882353,111.941176 C176.882353,76.0751551 147.807198,47 111.941176,47 C76.0751551,47 47,76.0751551 47,111.941176 C47,147.807198 76.0751551,176.882353 111.941176,176.882353 Z"
                    id="path-1"
                />
            </mask>
            <use id="Combined-Shape" fill="#D8D8D8" href="#path-1" />
            <g id="Group-10-Copy" mask="url(#mask-2)">
                <g id="Group-9">
                    <rect
                        id="Rectangle"
                        fill="url(#linearGradient-3)"
                        x="0"
                        y="0"
                        width="112"
                        height="224"
                    />
                    <rect
                        id="Rectangle-Copy"
                        fill="url(#linearGradient-4)"
                        x="112"
                        y="0"
                        width="112"
                        height="224"
                    />
                    <rect
                        id="Rectangle"
                        fill="url(#linearGradient-5)"
                        transform="translate(112.000000, 168.000000) scale(-1, 1) translate(-112.000000, -168.000000) "
                        x="0"
                        y="112"
                        width="224"
                        height="112"
                    />
                </g>
            </g>
            {!p.recentlyMounted && !p.onButtonClick && !p.wasTouched && (
                <Arrow type={p.arrowType} style={p.style} />
            )}
        </g>
    </svg>
);

interface SvgSubCompProps {
    style: WheelStyle;
}

const Max = (p: SvgSubCompProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="29"
            viewBox="0 0 23 29"
        >
            <g fill={Style.primaryTextColor(p.style)} fillRule="evenodd">
                <path
                    fillRule="nonzero"
                    d="M11.274.819a8.456 8.456 0 0 1 8.456 8.455h2.818l-3.654 3.655-.066.131-3.796-3.786h2.819a6.577 6.577 0 1 0-6.577 6.577 6.524 6.524 0 0 0 4.641-1.936l1.335 1.335a8.358 8.358 0 0 1-5.976 2.48 8.456 8.456 0 0 1 0-16.911zm-.94 4.697v4.698l4.022 2.386.676-1.137-3.288-1.954V5.516h-1.41z"
                />
                <text
                    {...Typo.robotoMedium}
                    fontSize="9.395"
                    letterSpacing=".47"
                    transform="translate(0 -2)"
                >
                    <tspan x=".317" y="30.609">
                        MAX
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

const Min = (p: SvgSubCompProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="29"
            viewBox="0 0 21 29"
        >
            <g fill={Style.primaryTextColor(p.style)} fillRule="evenodd">
                <path
                    fillRule="nonzero"
                    d="M12.153.819a8.456 8.456 0 0 0-8.455 8.455H.879l3.655 3.655.066.131 3.795-3.786H5.577a6.577 6.577 0 1 1 6.576 6.577 6.524 6.524 0 0 1-4.641-1.936L6.178 15.25a8.358 8.358 0 0 0 5.975 2.48 8.456 8.456 0 1 0 0-16.911zm-.94 4.697v4.698l4.022 2.386.676-1.137-3.288-1.954V5.516h-1.41z"
                />
                <text
                    {...Typo.robotoMedium}
                    fontSize="9.395"
                    letterSpacing=".47"
                    transform="translate(-1 -2)"
                >
                    <tspan x="2.022" y="31">
                        MIN
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

enum ArrowType {
    clockwise,
    counterClockwise,
    both,
}

export const Arrow = (p: { type: ArrowType; style: WheelStyle }) => {
    return (
        <g fill={Style.backgroundColor(p.style)} fillRule="nonzero">
            <path
                id="Oval"
                className={css({
                    animation: `${getRotation(p.type)} 4s ease infinite`,
                    transformOrigin: '112px 112px',
                })}
                d={getArrow(p.type)}
            />
        </g>
    );
};

function getRotation(type: ArrowType) {
    switch (type) {
        case ArrowType.clockwise:
            return keyframes`
                0% {
                    transform: rotate(0deg);
                }
                60% {
                    opacity: 1;
                }
                75% {
                    opacity: 0;
                    transform: rotate(300deg);
                }
                95% {
                    transform: rotate(0deg);
                    opacity: 0;
                }
                100% {
                    transform: rotate(0deg);
                    opacity: 1;
                }
            `;
        case ArrowType.counterClockwise:
            return keyframes`
                0% {
                    transform: rotate(0deg);
                }
                60% {
                    opacity: 1;
                }
                75% {
                    opacity: 0;
                    transform: rotate(-300deg);
                }
                95% {
                    transform: rotate(0deg);
                    opacity: 0;
                }
                100% {
                    transform: rotate(0deg);
                    opacity: 1;
                }
            `;
        default:
            break;
    }
}

function getArrow(type: ArrowType) {
    switch (type) {
        case ArrowType.clockwise:
            return 'M174.3,173.2l6.9-2.3l0.3,0.9l-8.6,2.9l-0.8,0.3l0.2-0.8l2.2-8.8l0.9,0.2l-1.8,7.1l4.1-4.5 c29-34.3,27-85.5-5-117.4l0.7-0.7c32.3,32.3,34.4,84,5.1,118.7L174.3,173.2L174.3,173.2z';
        case ArrowType.counterClockwise:
            return 'M176.4,52.7l1.8,7.1l-0.9,0.2l-2.2-8.8l-0.2-0.8l0.8,0.3l8.6,2.9l-0.3,0.9l-6.9-2.3l4.2,4.5 c29.3,34.7,27.3,86.4-5.1,118.7l-0.7-0.7c32-32,34-83.1,5-117.4L176.4,52.7L176.4,52.7z';
        case ArrowType.both:
            return 'M175.4,174.4l6.9-2.3l0.3,0.9l-8.6,2.9l-0.8,0.3l0.2-0.8l2.2-8.8l0.9,0.2l-1.8,7.1l4.1-4.5 c27.2-32.3,27.2-79.7,0-112l-4.1-4.5l1.8,7.1l-0.9,0.2l-2.2-8.8l-0.2-0.8l0.8,0.3l8.6,2.9l-0.3,0.9l-6.9-2.3l4.2,4.5 c27.5,32.6,27.5,80.6,0,113.2L175.4,174.4L175.4,174.4z';
    }
}

namespace Style {
    export const backgroundColor = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return Colors.darkblue;
            default:
                return Colors.white;
        }
    };

    export const primaryTextColor = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return Colors.white;
            case WheelStyle.parkingpay:
                return Colors.darkblue;
        }
    };

    export const secondaryTextColor = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return Colors.white;
            case WheelStyle.parkingpay:
                return Colors.darkblue;
        }
    };

    export const gradient3Offset0 = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return '#88CAF4';
            case WheelStyle.parkingpay:
                return '#006DCD';
        }
    };

    export const gradient3Offset100 = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return '#0595EB';
            case WheelStyle.parkingpay:
                return '#007DD2';
        }
    };

    export const gradient4Offset0 = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return '#7FC1EC';
            case WheelStyle.parkingpay:
                return '#0056A0';
        }
    };

    export const gradient4Offset100 = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return '#FFFFFF';
            case WheelStyle.parkingpay:
                return '#092E6D';
        }
    };

    export const gradient5Offset0 = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return '#4FA5E0';
            case WheelStyle.parkingpay:
                return '#005BA7';
        }
    };

    export const gradient5Offset41 = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return '#4FA5E0';
            case WheelStyle.parkingpay:
                return '#0055A0';
        }
    };
    export const gradient5Offset100 = (style: WheelStyle): string => {
        switch (style) {
            case WheelStyle.parkingpay_inverted:
                return '#4FA5E0';
            case WheelStyle.parkingpay:
                return '#00559F';
        }
    };
}
