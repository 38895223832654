import { Translation } from './Texts';
import { Language } from 'dg-web-shared/lib/Localized.ts';

const LOCALE: string =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.navigator as any).userLanguage || window.navigator.language;

export function getTwintLanguage(): Language {
    switch (LOCALE.slice(0, 2).toLowerCase()) {
        case 'de':
            return Language.DE;
        case 'fr':
            return Language.FR;
        case 'it':
            return Language.IT;
        default:
            return Language.EN;
    }
}

export function Lang(p: Translation): JSX.Element {
    return <>{p[getTwintLanguage()]}</>;
}
