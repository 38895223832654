import { portalSlideIn } from '../../../account/root/components/PortalSlidein';
import {
    BadgeEntity,
    EntitySelectionDropinProps,
} from './EntitySelectionDropin';
import { vehicleTexts } from '../../../account/vehicles/VehicleTexts';
import { selectState } from 'dg-web-shared/lib/Flux';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { Vehicle, Vehicles } from '../../../account/vehicles/VehicleState';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { LicensePlateEntityComp } from './Entities';
import { ParkCreateAddVehicleState } from '../ParkCreate';
import { css } from '@emotion/css';
import {
    AddBadgeSlideIn,
    AddVehicleSlideIn,
} from './AddIdentificationEntitySlideIns';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { MaterialButton } from '../../../ui/inputs/MaterialButton';
import { Localized } from '../../../common/components/Localized';
import { Icon } from 'dg-web-shared/ui/icons/Icon';
import { SingleSelection } from '../../../common/components/SingleSelection.tsx';

export const BadgeSelectionSlideIn = portalSlideIn<EntitySelectionDropinProps>(
    selectState(
        store => ({
            vehicles: Vehicles.get(store),
        }),
        p => {
            const vTexts = vehicleTexts[p.language];
            const vehicleEntities: BadgeEntity[] = [];
            const vehiclesWithoutBadges: Vehicle[] = p.vehicles.data.filter(
                v => v.badgeId === null,
            );

            const badgeSelectionDividerStyle = css({
                marginTop: '20px',
                color: Colors.action_w,
                ...Typo.caption,
            });
            const licensePlateItemStyle = css({
                display: 'flex',
                height: '48px',
                padding: '0 16px',
                color: Colors.action_w,
                alignItems: 'baseline',
                paddingTop: '10px',
                marginLeft: '-16px',
                marginRight: '-16px',
            });

            p.entities.forEach(entity => {
                if (
                    p.vehicles.data.some(
                        vehicle => vehicle.customerCarId === entity.vehicleId,
                    )
                ) {
                    vehicleEntities.push(entity as BadgeEntity);
                }
            });

            return (
                <div className={css({ padding: '0 16px' })}>
                    <SingleSelection
                        labelText={vTexts.addVehicle()}
                        context={InputContext.form}
                        onClick={() =>
                            p.update(store =>
                                ParkCreateAddVehicleState.stateWrite(store, {
                                    addVehicle: true,
                                }),
                            )
                        }
                        selection={null}
                    />
                    {vehicleEntities.length > 0 && (
                        <div className={badgeSelectionDividerStyle}>
                            {vTexts.vehicles()}
                        </div>
                    )}
                    {vehicleEntities.map(e => {
                        const itemSelected =
                            p.selectedEntityIds.indexOf(e.id) > -1;

                        const disabled = p.disableUnselected && !itemSelected;

                        const vehicle: Vehicle = p.vehicles.data.find(
                            v => v.customerCarId === e.vehicleId,
                        )!;

                        return (
                            <Clickable
                                key={`badgeEntity-${e.id}-${e.vehicleId}`}
                                element="div"
                                className={licensePlateItemStyle}
                                onClick={() => p.onSelect(e.id)}
                                data-selection-mode={p.selectionMode}
                                data-selected={itemSelected}
                                data-disabled={disabled}
                                disabled={disabled}
                            >
                                {p.selectionMode === 'multiple' && (
                                    <div className={css({ width: '24px' })}>
                                        <div
                                            className={css({
                                                marginBottom: '-7px',
                                            })}
                                        >
                                            <Icon
                                                icon={
                                                    itemSelected
                                                        ? 'checkbox'
                                                        : 'checkboxOutline'
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                <LicensePlateEntityComp
                                    licensePlate={{
                                        licensePlateNr: vehicle.licensePlateNr,
                                        countryId: vehicle.country,
                                        remarks: vehicle.description,
                                        type: vehicle.type,
                                    }}
                                />
                            </Clickable>
                        );
                    })}
                    {vehiclesWithoutBadges.length > 0 && (
                        <div className={badgeSelectionDividerStyle}>
                            {vTexts.vehiclesWithoutBadge()}
                        </div>
                    )}
                    {vehiclesWithoutBadges.map(vehicle => {
                        return (
                            <Clickable
                                key={`badgeEntity-${vehicle.customerCarId}`}
                                element="div"
                                className={licensePlateItemStyle}
                                onClick={() => {
                                    p.update(store =>
                                        ParkCreateAddVehicleState.stateWrite(
                                            store,
                                            {
                                                addBadge: true,
                                                selectedVehicleId:
                                                    vehicle.customerCarId,
                                            },
                                        ),
                                    );
                                }}
                            >
                                <LicensePlateEntityComp
                                    licensePlate={{
                                        licensePlateNr: vehicle.licensePlateNr,
                                        countryId: vehicle.country,
                                        remarks: vehicle.description,
                                        type: vehicle.type,
                                    }}
                                />
                                <Icon
                                    icon="chevronRight"
                                    className={css({ top: '7px' })}
                                />
                            </Clickable>
                        );
                    })}
                    <div
                        className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '20px',
                        })}
                    >
                        <MaterialButton
                            primary
                            label={
                                <Localized
                                    de="Zurück"
                                    fr="Retour"
                                    it="Indietro"
                                    en="Back"
                                />
                            }
                            onClick={p.onClose}
                        />
                    </div>
                    <AddVehicleSlideIn
                        mode={p.mode}
                        permitTypeState={p.permitTypeState}
                    />
                    <AddBadgeSlideIn
                        mode={p.mode}
                        permitTypeState={p.permitTypeState}
                    />
                </div>
            );
        },
    ),
);
