import { Store, useStore } from 'dg-web-shared/lib/Flux';
import {
    getLicensePlateStates,
    licensePlateErrorText,
} from 'dg-web-shared/lib/LicensePlateValidation';
import { RequestStatus } from 'dg-web-shared/lib/ServerRequestStateSlices';
import { stripNonNumericAndNoLetterCharactersAllowUmlauts } from 'dg-web-shared/lib/StringConversions';
import { BackendTranslation } from 'dg-web-shared/lib/Text';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import {
    DropdownSlideIn,
    SlideInDisplay,
} from '../root/components/DropdownSlideIn';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { VehicleSlideInForm } from './VehicleSlideInForm';
import { UpdateVehicle, Vehicle, VehiclesMeta } from './VehicleState';
import { VehicleTexts } from './VehicleTexts';
import { getLisencePlateTypeOptions } from './VehicleTypeDisplay';
import {
    ArrowPosition,
    InlineAlertBox,
    ModalErrorBox,
} from '../../ui/modals/Confirmable';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType';
import { useEffect, useState } from 'react';
import { ChangeableLicensePlateContent } from './VehicleDetail';
import { updateTransactionlistWarnings } from '../../transactions-list/state/TransactionsListState';
import { Language, Message } from 'dg-web-shared/lib/Localized';
import { useTransactionListRefetch } from '../../transactions-list/TransactionsListContext';
import { errors } from 'dg-web-shared/common/components/translations/LicensePlateErrorTexts.ts';
import { SingleSelection } from '../../common/components/SingleSelection.tsx';

export enum LprDisabledReason {
    MOTORCYCLE = 'MOTORCYCLE',
    TOO_SHORT = 'TOO_SHORT',
    DIPLOMATIC = 'DIPLOMATIC',
}

interface Props {
    texts: VehicleTexts;
    vehicle: Vehicle;
    setLprDisabledModal: (reason: LprDisabledReason) => void;
}

export interface EditableLicensePlate {
    licensePlateNr: string;
    licensePlateType: LicensePlateType;
    licensePlateCountryId: string;
}

export const VehicleLicensePlateEdit = portalSlideIn<Props>(props => {
    const [editableLicensePlate, setEditableLicensePlate] =
        useState<EditableLicensePlate>({
            licensePlateCountryId: props.vehicle.country,
            licensePlateNr: props.vehicle.licensePlateNr,
            licensePlateType: props.vehicle.type,
        });
    const { storeState, update } = useStore(store => {
        return {
            settings: new SettingsState.StateSlice(store).state,
            meta: VehiclesMeta.get(store),
            selectedVehicle: UpdateVehicle.get(store),
        };
    });
    const refetchTransactionList = useTransactionListRefetch();
    return (
        <VehicleSlideInForm
            onCancel={props.onClose}
            disabledPrimary={
                storeState.selectedVehicle.status === RequestStatus.ERROR ||
                getLicensePlateStates(editableLicensePlate).length > 0 ||
                editableLicensePlate.licensePlateNr.length === 0
            }
            onSave={() => {
                update((store: Store) => {
                    return UpdateVehicle.put(
                        store,
                        {
                            ...props.vehicle,
                        },
                        {
                            ...props.vehicle,
                            licensePlateNr: editableLicensePlate.licensePlateNr,
                            type: editableLicensePlate.licensePlateType,
                            country: editableLicensePlate.licensePlateCountryId,
                        },
                        res => {
                            refetchTransactionList();
                            updateTransactionlistWarnings(update);
                            props.setLprDisabledModal(
                                res.body.lprDisabledReason,
                            );
                            props.onClose();
                        },
                    );
                });
            }}
            selectedVehicle={storeState.selectedVehicle}
        >
            <LicensePlateEditFields
                portal={props.portal}
                lang={storeState.settings.language}
                licensePlateCountries={
                    storeState.meta.data.licensePlateCountries
                }
                licensePlateTypes={storeState.meta.data.licensePlateTypes}
                licensePlate={editableLicensePlate}
                stateWrite={setEditableLicensePlate}
                updateVehicle={storeState.selectedVehicle}
                onClose={() => {
                    update(store => {
                        UpdateVehicle.reset(store);
                        props.onClose();
                        return 'reset-vehicle';
                    });
                }}
            />
            {props.vehicle &&
                props.vehicle.runningLpContractOperatorNames.length > 0 && (
                    <LicensePlateIsInUseWarning
                        operatorNames={
                            props.vehicle.runningLpContractOperatorNames
                        }
                    />
                )}
        </VehicleSlideInForm>
    );
});

function LicensePlateIsInUseWarning(props: { operatorNames: string[] }) {
    const namesText = props.operatorNames.join(', ');

    return (
        <InlineAlertBox
            titleCaption={
                <Localized
                    de="Betreiber kontaktieren"
                    fr="Contacter l'exploitant"
                    it="Contattare il gestore"
                    en="Contact the operator"
                />
            }
            arrowPosition={ArrowPosition.left}
        >
            <p>
                <Localized
                    de={
                        <div>
                            Dieses Kennzeichen ist bei folgenden Betreibern
                            registriert (z.B. für eine Bewilligung oder einen
                            reduzierten Tarif): <b>{namesText}</b>.
                            <br />
                            <br />
                            Die Änderung des Kennzeichens hier in der App wird{' '}
                            <b>
                                NICHT automatisch an den Betreiber übermittelt
                            </b>
                            .
                            <br />
                            <br />
                            Wir bitten Sie daher, sich mit den oben genannten
                            Betreibern in Verbindung zu setzen, um die Änderung
                            in Auftrag zu geben.
                        </div>
                    }
                    fr={
                        <div>
                            Cette immatriculation est enregistrée (par exemple
                            pour une autorisation ou pour un tarif réduit)
                            auprès des exploitants suivants: <b>{namesText}</b>.
                            <br />
                            <br />
                            La modification de l&apos;immatriculation ici dans
                            l&apos;appli{' '}
                            <b>
                                n&apos;est PAS automatiquement transmise aux
                                exploitants
                            </b>
                            .
                            <br />
                            <br /> Nous vous prions donc de contacter les
                            exploitants mentionnés ci-dessus, en leur indiquant
                            le changement en question.
                        </div>
                    }
                    it={
                        <div>
                            Questo numero di targa è registrato (p.es. per
                            un&apos;autorizzazione o per una tariffa agevolata)
                            presso i seguenti gestori: <b>{namesText}</b>.
                            <br />
                            <br />
                            La modifica del numero di targa qui nell&apos;app{' '}
                            <b>
                                NON viene trasmessa automaticamente ai gestori
                            </b>
                            .
                            <br />
                            <br />
                            La preghiamo quindi di contattare i gestori
                            sopraindicati, segnalando la modifica in questione.
                        </div>
                    }
                    en={
                        <div>
                            This license plate is registered (e.g. for a permit
                            or for a reduced tariff) by the following operators:{' '}
                            <b>{namesText}</b>.
                            <br />
                            <br />
                            Changing the number plate here in the app is{' '}
                            <b>
                                NOT automatically transmitted to the operators
                            </b>
                            .
                            <br />
                            <br />
                            Please contact the above mentioned operators and
                            notify them of the change.
                        </div>
                    }
                />
            </p>
        </InlineAlertBox>
    );
}

function lpRemoteErrorText(updateVehicle: UpdateVehicle.State): Message | null {
    if (
        updateVehicle.status === RequestStatus.ERROR &&
        updateVehicle.data !== null
    ) {
        switch (updateVehicle.data.code) {
            case -126:
                return {
                    de: 'Ein Fahrzeug mit diesem Kennzeichen existiert schon.',
                    fr: 'Un véhicule avec cette immatriculation existe déjà.',
                    it: 'Esiste già un veicolo con questo numero di targa.',
                    en: 'There is already a vehicle with this license plate.',
                };
            default:
                return null;
        }
    }
    return null;
}

interface LicensePlateEditFieldsProps {
    portal: SlideInPortalId;
    lang: string;
    licensePlateCountries: BackendTranslation[];
    licensePlateTypes: BackendTranslation[];
    licensePlate: EditableLicensePlate;
    stateWrite: (
        values: (prevState: EditableLicensePlate) => EditableLicensePlate,
    ) => void;
    updateVehicle: UpdateVehicle.State;
    readOnly?: boolean;
    onLicensePlateBlur?: () => void;
    showLicensePlateErrors?: boolean;
    onClose: () => void;
}

export function LicensePlateEditFields(p: LicensePlateEditFieldsProps) {
    const [showLicensePlateInUseWarning, setShowLicensePlateInUseWarning] =
        useState(false);
    useEffect(() => {
        if (
            p.updateVehicle.status === RequestStatus.ERROR &&
            p.updateVehicle.data !== null &&
            p.updateVehicle.data.code === -126
        ) {
            setShowLicensePlateInUseWarning(true);
        }
    }, [p.updateVehicle]);
    const [countrySelect, setCountrySelect] = useState(false);
    const [typeSelect, setTypeSelect] = useState(false);

    const country = p.licensePlateCountries?.find(
        country => country.id === p.licensePlate.licensePlateCountryId,
    );

    const countryName =
        typeof country === 'undefined' ? '' : country.name[p.lang];

    const plateType = p.licensePlateTypes.find(
        licensePlateTranslation =>
            licensePlateTranslation.id === p.licensePlate.licensePlateType,
    );

    const plateTypeName =
        typeof plateType === 'undefined' ? '' : plateType.name[p.lang];

    const requiredFieldError: Message | null =
        !!p.showLicensePlateErrors &&
        p.licensePlate.licensePlateNr.trim().length === 0
            ? {
                  de: 'Das Kennzeichen darf nicht leer sein.',
                  fr: "L'immatriculation ne peut pas être vide.",
                  it: 'Il numero di targa non può essere vuoto.',
                  en: 'License plate number cannot be empty.',
              }
            : null;

    const licensePlateError =
        licensePlateErrorText(p.licensePlate, errors[Language.DE]) === ''
            ? null
            : {
                  de: licensePlateErrorText(
                      p.licensePlate,
                      errors[Language.DE],
                  ),
                  fr: licensePlateErrorText(
                      p.licensePlate,
                      errors[Language.FR],
                  ),
                  it: licensePlateErrorText(
                      p.licensePlate,
                      errors[Language.IT],
                  ),
                  en: licensePlateErrorText(
                      p.licensePlate,
                      errors[Language.EN],
                  ),
              };

    const remoteLpErrors = lpRemoteErrorText(p.updateVehicle);
    const errorText = requiredFieldError || licensePlateError || remoteLpErrors;

    const mostUsedCountries = p.licensePlateCountries.slice(0, 6);
    const restCountries = p.licensePlateCountries
        .filter(n => !mostUsedCountries.includes(n))
        .sort((a, b) => {
            return a.id.localeCompare(b.id);
        });

    return (
        <div>
            <TextField
                labelText={
                    <Localized
                        de="Kennzeichen"
                        fr="Immatriculation"
                        it="Targa"
                        en="License plate"
                    />
                }
                value={p.licensePlate.licensePlateNr}
                autoCapitalize="characters"
                autoCorrect="off"
                errorText={errorText ? <Localized {...errorText} /> : null}
                onChange={value => {
                    p.stateWrite(prevState => ({
                        ...prevState,
                        licensePlateNr:
                            stripNonNumericAndNoLetterCharactersAllowUmlauts(
                                value,
                            ),
                    }));
                }}
                readOnly={p.readOnly}
                onBlur={p.onLicensePlateBlur}
            />
            {showLicensePlateInUseWarning && (
                <ChangeableLicensePlateNotAllowed
                    onClose={() => {
                        p.onClose();
                        setShowLicensePlateInUseWarning(false);
                    }}
                />
            )}
            <SingleSelection
                labelText={{
                    de: 'Land',
                    fr: 'Pays',
                    it: 'Nazione',
                    en: 'Country',
                }}
                selection={countryName}
                onClick={() => {
                    setCountrySelect(true);
                }}
                context={InputContext.form}
                readOnly={p.readOnly}
            />
            <SingleSelection
                labelText={{
                    de: 'Schildart',
                    fr: 'Type de plaque',
                    it: 'Tipo di targa',
                    en: 'Category',
                }}
                selection={plateTypeName}
                onClick={() => {
                    setTypeSelect(true);
                }}
                context={InputContext.form}
                readOnly={p.readOnly}
            />

            <DropdownSlideIn
                portal={p.portal}
                open={countrySelect}
                title={<Localized de="Land" fr="Land" it="Land" en="Land" />}
                onClose={() => {
                    setCountrySelect(false);
                }}
                selected={p.licensePlate.licensePlateCountryId}
                onSelected={(country: string) => {
                    setCountrySelect(false);
                    p.stateWrite(prevState => ({
                        ...prevState,
                        licensePlateCountryId: country,
                    }));
                }}
                topOptions={mostUsedCountries.map(country => {
                    return {
                        id: country.id,
                        display: (
                            <SlideInDisplay
                                firstRowText={country.id}
                                secondRowText={country.name[p.lang]}
                            />
                        ),
                    };
                })}
                topOptionsHeader={
                    <Localized
                        de="Nachbarländer"
                        fr="Pays voisins"
                        it="Nazioni limitrofe"
                        en="Neighboring countries"
                    />
                }
                options={restCountries.map(country => {
                    return {
                        id: country.id,
                        display: (
                            <SlideInDisplay
                                firstRowText={country.id}
                                secondRowText={country.name[p.lang]}
                            />
                        ),
                    };
                })}
                optionsHeader={
                    <Localized
                        de="Andere Länder"
                        fr="Autres pays"
                        it="Altre nazioni"
                        en="Other countries"
                    />
                }
            />
            <DropdownSlideIn
                portal={p.portal}
                open={typeSelect}
                title={
                    <Localized
                        de="Schildart"
                        fr="Type de plaque"
                        it="Tipo di targa"
                        en="Category"
                    />
                }
                onClose={() => {
                    setTypeSelect(false);
                }}
                selected={p.licensePlate.licensePlateType}
                onSelected={(type: LicensePlateType) => {
                    setTypeSelect(false);
                    p.stateWrite(prevState => ({
                        ...prevState,
                        licensePlateType: type,
                    }));
                }}
                options={getLisencePlateTypeOptions(
                    p.lang,
                    p.licensePlateTypes,
                )}
            />
        </div>
    );
}

function ChangeableLicensePlateNotAllowed({
    onClose,
}: {
    onClose: () => void;
}) {
    return (
        <ModalErrorBox
            confirmCallback={onClose}
            titleCaption={
                <Localized
                    de="Kennzeichen bereits registriert"
                    fr="Immatriculation déjà enregistrée"
                    it="Targa già registrata"
                    en="License plate already registered"
                />
            }
        >
            <p>
                <Localized
                    de="Das eingegebene Kennzeichen ist bereits in diesem Parkingpay-Konto registriert."
                    fr="Le numéro d'immatriculation saisi est déjà enregistré dans ce compte Parkingpay."
                    it="Il numero di targa inserito è già registrato in questo conto Parkingpay."
                    en="The licence plate number entered is already registered in this Parkingpay account."
                />
            </p>
            <ChangeableLicensePlateContent />
        </ModalErrorBox>
    );
}
