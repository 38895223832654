import { css } from '@emotion/css';

import { useEffect, useState } from 'react';
import { useStore } from 'dg-web-shared/lib/Flux';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { MaterialButton } from '../../ui/inputs/MaterialButton';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { Localized } from '../../common/components/Localized';
import { logAction } from '../../utils/ActionLog';
import {
    portalSlideIn,
    PortalSlideInProps,
} from '../root/components/PortalSlidein';
import { RemoteRequestFailed } from './RemoteRequestFailed';
import { Vehicle, Vehicles } from './VehicleState';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { SlideInForm } from '../root/components/SlideInForm';
import {
    ArrowPosition,
    InlineErrorBox,
    InlineInfoBox,
} from '../../ui/modals/Confirmable';
import { PrivateLprInlineInfo, SurchargeText } from './LprSettingsSlideIn';
import { BarrierGateLicensePlateCheckInOption } from 'dg-web-shared/common/models/Vehicle';

export const BarrierGateLicensePlateCheckInSlideIn = portalSlideIn(
    BarrierGateLicensePlateCheckIn,
);

function BarrierGateLicensePlateCheckIn(
    props: {
        customerCarId: number;
        vehicle: Vehicle;
        currentOptionValue: BarrierGateLicensePlateCheckInOption;
        onNavigateToBadgeOrder: () => void;
        onUnmount?: () => void;
    } & PortalSlideInProps,
) {
    const [active, setActive] = useState(
        props.currentOptionValue ===
            BarrierGateLicensePlateCheckInOption.ACTIVE,
    );

    const { store, update } = useStore(() => null);

    useEffect(() => {
        return () => {
            if (props.onUnmount) {
                props.onUnmount();
            }
        };
    }, []);

    const [lprActiveUpdateState, putLprActiveUpdate] = useServerWrite<
        { active: boolean },
        null
    >(() => ({
        url: `/ui-api/customer-account/vehicle/${props.customerCarId}/license-plate-recognition`,
        method: RequestMethod.PUT,
    }));

    useServerSuccessEffect(lprActiveUpdateState, () => {
        logAction(store, 'vehicle-update-lpr-flag', {
            vehicleId: props.customerCarId,
            licensePlateNr: props.vehicle.licensePlateNr,
            description: props.vehicle.description,
            newFlagValue: active,
        });
        update(s => {
            Vehicles.forceRefetch(s);
            return 'barrier-gate-license-plate-checking-vehicle-refresh';
        });
        props.onClose();
    });

    const isAllowedLp =
        props.currentOptionValue ===
            BarrierGateLicensePlateCheckInOption.INACTIVE ||
        props.currentOptionValue ===
            BarrierGateLicensePlateCheckInOption.ACTIVE;

    const showBadgeAdvice = isAllowedLp && !props.vehicle.badgeId;
    const showBadgeText = isAllowedLp && props.vehicle.badgeId;

    return (
        <SlideInForm
            secondaryButton={{
                label: <Localized {...GeneralTexts.cancel} />,
                onClick: props.onClose,
            }}
            primaryButton={{
                disabled:
                    !isAllowedLp ||
                    lprActiveUpdateState.status === RequestStatus.PENDING ||
                    (props.currentOptionValue ===
                        BarrierGateLicensePlateCheckInOption.ACTIVE &&
                        active) ||
                    (props.currentOptionValue ===
                        BarrierGateLicensePlateCheckInOption.INACTIVE &&
                        !active),
                label:
                    lprActiveUpdateState.status === RequestStatus.PENDING ? (
                        <Localized
                            de="Speichern..."
                            fr="Enregistrer..."
                            it="Salva..."
                            en="Saving..."
                        />
                    ) : (
                        <Localized {...GeneralTexts.save} />
                    ),
                onClick: () => putLprActiveUpdate({ active: active }),
            }}
        >
            {showBadgeAdvice && (
                <BadgeAsBackupAdvice
                    onNavigateToBadgeOrder={props.onNavigateToBadgeOrder}
                />
            )}

            <h3 className={css({ marginBottom: 0 })}>
                <Localized
                    de="ÖFFENTLICHE PARKINGS"
                    fr="PARKINGS PUBLICS"
                    it="PARCHEGGI PUBBLICI"
                    en="PUBLIC PARKINGS"
                />
            </h3>

            <LabeledToggle
                type={ToggleType.checkbox}
                disabled={
                    props.currentOptionValue ===
                        BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_TOO_SHORT ||
                    props.currentOptionValue ===
                        BarrierGateLicensePlateCheckInOption.OCCUPIED
                }
                label={
                    <>
                        <Localized
                            de="Kennzeichen aktivieren"
                            fr="Activer l'immatriculation"
                            it="Attiva la targa"
                            en="Enable license plate"
                        />
                        {` (${props.vehicle.licensePlateNr})`}
                    </>
                }
                selected={active}
                onClick={() => {
                    setActive(!active);
                }}
            />

            {isAllowedLp && (
                <p>
                    <Localized
                        de="Durch die Aktivierung dieses Kennzeichens erklären Sie sich damit einverstanden, dass in den aktivieren Parkings die Parkgebühr und der damit verbundene Zuschlag von diesem Parkingpay-Konto automatisch abgebucht werden."
                        fr="En activant cette immatriculation, vous acceptez que, pour les parkings activés, le frais de stationnement et la surtaxe associée soient débités automatiquement de ce compte Parkingpay."
                        it="Attivando questa targa accetta che, nei parcheggi abilitati, la tariffa di parcheggio e il relativo supplemento vengano addebitati automaticamente su questo conto Parkingpay."
                        en="By activating this plate you agree that, in activated parking spaces, the parking fee and the associated surcharge will be charged automatically to this Parkingpay account."
                    />
                </p>
            )}

            {showBadgeText && (
                <p>
                    <Localized
                        de="Falls die Kennzeichenerkennung nicht funktionieren sollte, haben Sie weiterhin die Möglichkeit, den Badge zu verwenden."
                        fr="Si la reconnaissance de plaque d'immatriculation ne fonctionne pas, vous avez toujours la possibilité d'utiliser le badge."
                        it="Se il riconoscimento della targa non dovesse funzionare, avete comunque la possibilità di utilizzare il badge."
                        en="If license plate recognition does not work, you still have the option of using the badge."
                    />
                </p>
            )}

            {props.currentOptionValue ===
                BarrierGateLicensePlateCheckInOption.NOT_ALLOWED_TOO_SHORT && (
                <LprTooShortInfo />
            )}

            {props.currentOptionValue ===
                BarrierGateLicensePlateCheckInOption.OCCUPIED && (
                <LprOccupiedInfo />
            )}

            {isAllowedLp && <SurchargeText />}

            <PrivateLprInlineInfo />

            {lprActiveUpdateState.status === RequestStatus.ERROR && (
                <RemoteRequestFailed
                    httpStatusCode={lprActiveUpdateState.httpStatusCode}
                />
            )}
        </SlideInForm>
    );
}

function LprTooShortInfo() {
    return (
        <InlineErrorBox
            arrowPosition={ArrowPosition.left}
            titleCaption={
                <Localized
                    de="Aktivierung nicht möglich"
                    fr="Activation pas possible"
                    it="Attivazione non possibile"
                    en="Activation not possible"
                />
            }
        >
            <p>
                <Localized
                    de="Diese Funktion kann nicht aktiviert werden, da Ihr Kennzeichen zu kurz ist."
                    fr="Cette fonction ne peut pas être activée car le numéro de votre plaque d'immatriculation est trop court."
                    it="Questa funzione non può essere attivata perché il numero di targa è troppo corto."
                    en="This function cannot be activated because your licence plate number is too short."
                />
            </p>
            <p>
                <Localized
                    de="Bei Kennzeichen mit weniger als 6 Zeichen (Buchstaben/Ziffern) wäre die Wahrscheinlichkeit, dass ein anderes Kennzeichen fälschlicherweise als Ihres erkannt wird, zu gross."
                    fr="Pour les plaques d'immatriculation comportant moins de 6 caractères (lettres/chiffres), la probabilité qu'une autre plaque soit reconnue à tort comme étant la vôtre serait trop élevée."
                    it="Per le targhe con meno di 6 caratteri (lettere/cifre), la probabilità che un'altra targa venga erroneamente riconosciuta come la sua sarebbe troppo alta."
                    en="For licence plates with less than 6 characters (letters/digits), the likelihood of another licence plate being mistakenly recognised as yours would be too big."
                />
            </p>
            <p>
                <Localized
                    de="Dies würde zu ungerechtfertigten Belastungen Ihres Parkingpay-Kontos führen."
                    fr="Cela provoquerait des débits injustifiés sur votre compte Parkingpay."
                    it="Ciò comporterebbe addebiti ingiustificati sul suo conto Parkingpay."
                    en="This would result in unjustified charges to your Parkingpay account."
                />
            </p>
        </InlineErrorBox>
    );
}

function LprOccupiedInfo() {
    return (
        <InlineErrorBox
            titleCaption={
                <Localized
                    de="Aktivierung nicht möglich"
                    fr="Activation pas possible"
                    it="Attivazione non possibile"
                    en="Activation not possible"
                />
            }
            arrowPosition={ArrowPosition.left}
        >
            <p>
                <Localized
                    de="Diese Funktion kann für dieses Kennzeichen nicht aktiviert werden, da sie bereits auf einem anderen Parkingpay-Konto aktiviert wurde."
                    fr="Cette fonction ne peut pas être activée pour cette plaque d'immatriculation car elle a déjà été activée sur un autre compte Parkingpay."
                    it="Questa funzione non può essere attivata per questa targa perché è già stata attivata su un altro conto Parkingpay."
                    en="This function cannot be activated for this license plate because it has already been activated on another Parkingpay account."
                />
            </p>
        </InlineErrorBox>
    );
}

function BadgeAsBackupAdvice(props: { onNavigateToBadgeOrder: () => void }) {
    return (
        <div>
            <InlineInfoBox
                titleCaption={
                    <Localized
                        de="Badge als Backup"
                        fr="Badge comme alternative"
                        it="Badge come alternativa"
                        en="Badge as a backup"
                    />
                }
            >
                <p>
                    <Localized
                        de="Falls die Kennzeichenerkennung nicht funktionieren sollte, haben Sie die Möglichkeit mit dem Badge sorgenlos ein- und auszufahren."
                        fr="Si la reconnaissance des plaques d'immatriculation ne devait pas fonctionner, vous auriez la possibilité d'entrer et de sortir sans souci avec le badge."
                        it="Se il riconoscimento della targa non dovesse funzionare, avrebbe la possibilità di entrare e uscire senza problemi con il badge."
                        en="If the license plate recognition should not work, you have the option to drive in and out carefree with the badge."
                    />
                </p>
                <p>
                    <Localized
                        de="Ausserdem würde der Badge Ihnen erlauben, auch bei den Parkings ohne Kennzeichenerkennung, von Parkingpay zu profitieren."
                        fr="De plus, le badge vous permettrait de bénéficier du Parkingpay même dans le parking sans reconnaissance de la plaque d'immatriculation."
                        it="Inoltre, il badge vi consentirebbe di beneficiare del Parkingpay anche nel parcheggio senza il riconoscimento della targa."
                        en="Moreover, the badge would allow you to benefit from Parkingpay even in a parking without license plate recognition."
                    />
                </p>
                <p
                    className={css({
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        paddingTop: '16px',
                    })}
                >
                    <MaterialButton
                        label={
                            <Localized
                                de="Badge hinzufügen"
                                fr="Ajouter badge"
                                it="Aggiungi badge"
                                en="Add badge"
                            />
                        }
                        negative
                        onClick={props.onNavigateToBadgeOrder}
                    />
                </p>
            </InlineInfoBox>
        </div>
    );
}
