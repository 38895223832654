import { Maybe, thenElse } from 'dg-web-shared/lib/MaybeV2';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../../account/root/components/PortalSlidein';
import { SlideInContentItem, SlideInContentItemText } from './SlideIn';

export type DisplayText = string | JSX.Element;
interface Option<I> {
    id: I;
    displayText: DisplayText;
}

interface SingleSelectionSlideinProps<I> {
    open: boolean;
    label: string;
    portalId: SlideInPortalId;
    selection: Maybe<I>;
    options: Option<I>[];
    onClose: () => void;
    onSelect: (value: I) => void;
    contentHeader?: JSX.Element;
}

const SlideIn = portalSlideIn<object>(p => {
    return <div>{p.children}</div>;
});

export const SingleSelectionSlidein = <I extends object>(
    p: SingleSelectionSlideinProps<I>,
) => (
    <SlideIn {...p} title={p.label} portal={p.portalId}>
        {p.options.map((o, i) => (
            <SlideInContentItem
                onClick={() => p.onSelect(o.id)}
                key={i}
                selected={thenElse(p.selection, s => o.id === s, false)}
            >
                {typeof o.displayText === 'string' ? (
                    <SlideInContentItemText>
                        {o.displayText}
                    </SlideInContentItemText>
                ) : (
                    o.displayText
                )}
            </SlideInContentItem>
        ))}
    </SlideIn>
);

type SlideinComponent<I> = (p: SingleSelectionSlideinProps<I>) => JSX.Element;
export const SingleSelectionSlideinString =
    SingleSelectionSlidein as SlideinComponent<string>;
export const SingleSelectionSlideinNumber =
    SingleSelectionSlidein as SlideinComponent<number>;

export const getSelectionText = (
    options: Option<string>[],
    selectedId: Maybe<string>,
    genSelectedText: (o: Option<string>) => DisplayText = o => o.displayText,
) =>
    thenElse(
        selectedId,
        id =>
            thenElse(
                options.find(o => o.id === id),
                o => genSelectedText(o),
                null,
            ),
        null,
    );
