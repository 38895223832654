import { generateState, SliceGenFuns, Store } from './Flux';
import { LocalStorageBase } from './LocalStorageBase';

export function generatePersistentStateSlice<S extends object>(
    key: string,
    initialState: S | (() => S),
): SliceGenFuns<S> {
    const slice = generateState<S>(key, () => {
        const localStorageValue = LocalStorageBase.getSerializableItem<S>(key);

        if (localStorageValue) {
            return localStorageValue;
        }

        return typeof initialState === 'function'
            ? (initialState as () => S)()
            : clone(initialState);
    });

    const originalStateWrite = slice.stateWrite.bind(slice);

    slice.stateWrite = function (store: Store, state: Partial<S>) {
        const result = originalStateWrite(store, state);
        LocalStorageBase.setSerializableItem(key, slice.get(store));
        return result;
    };

    return slice;
}

function clone(x: object) {
    return JSON.parse(JSON.stringify(x));
}
