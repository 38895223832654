import * as Sentry from '@sentry/browser';
import * as superagent from 'superagent';
import { Store } from 'dg-web-shared/lib/Flux';
import { isDefined } from 'dg-web-shared/lib/MaybeV2';
import {
    generateServerReadStateSlice,
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import { Gender } from '../../account-setup/state/AccountSetupState';
import { apiPost } from '../../api/Http';
import { requestV2, Response } from '../../AsyncRequest';
import { device, DEVICE_UUID } from '../../utils/ActionLog';
import { FCMTokenState } from './FCMTokenState';
import { PendingTwintPairing } from 'dg-web-shared/common/utils/TwintPairing';

export namespace CurrentLoginState {
    export type State = ServerRequestState<CurrentLogin>;

    export enum LoginRole {
        ADMIN = 0,
        RESTRICTED = 1,
    }
    export interface Device {
        deviceId: number;
        fcmToken: string | null;
        status: boolean | null;
        autoTerminationWarning: boolean;
        autoTerminationPreWarning: boolean;
        autoTerminationPreWarningMinutes: number;
    }

    export interface CurrentLogin {
        email: string | null;
        requestedEmail: string | null;
        gender: Gender | null;
        firstName: string | null;
        lastName: string | null;
        loginId: number;
        customerId: number;
        loginRole: LoginRole;
        balanceWarningEnabled: boolean;
        balanceWarningAmount: number | null;
        newsletterStatus: string;
        runningTransactionsSummaryEnabled: boolean;
        runningTransactionsWarningHour: number;
        sendEmailNotifications: boolean;
        sendRunningTransactionsAutoTerminate: boolean;
        device: Device | null;
        canChooseEnv: boolean;
        // Can only be undefined until all clients updated
        pendingTwintTransaction: PendingTwintTransaction | null;
        isCompany: boolean;
        showRecents: boolean;
        numberOfEnabledLprZones: number;
    }

    interface PendingTwintTransaction {
        paymentAttemptId: number;
        mode: PendingTwintPairing.TwintOrderMethodType;
    }

    const clearTimeouts = (refreshIds: number[]) => {
        refreshIds.forEach(id => window.clearTimeout(id));
        refreshIds.length = 0;
    };

    export const { get, setResponse, reset, recheckFcmToken, use } = (() => {
        const refreshIds: number[] = [];
        const generated = generateServerReadStateSlice<CurrentLogin>({
            key: 'common-CurrentLoginState',
            sideEffects(store, state) {
                if (state.shouldFetch) {
                    store.update(
                        requestV2(
                            () =>
                                superagent
                                    .get(
                                        '/ui-api/customer-account/current-login-v2',
                                    )
                                    .query({
                                        uuid: DEVICE_UUID,
                                        browser: device.browser.name,
                                        os: device.os.name,
                                    }),
                            (store: Store, res: Response) => {
                                if (res.statusCode.cls.success) {
                                    Sentry.getCurrentScope().setUser({
                                        id: `${res.body.customerId}`,
                                        email: `${res.body.email}`,
                                        uuid:
                                            res.body.device &&
                                            res.body.device.uuid,
                                    });

                                    recheckFcmToken(store, true);

                                    const pendingTwintState =
                                        PendingTwintPairing.get(store);
                                    if (
                                        pendingTwintState.pairingToken ===
                                            null &&
                                        pendingTwintState.paymentAttemptId ===
                                            null &&
                                        res.body.pendingTwintTransaction !==
                                            null
                                    ) {
                                        PendingTwintPairing.set(store, () => ({
                                            pairingToken: null,
                                            paymentAttemptId:
                                                res.body.pendingTwintTransaction
                                                    .paymentAttemptId,
                                            clientState: null,
                                            mode: res.body
                                                .pendingTwintTransaction.mode,
                                        }));
                                    }
                                }

                                setResponse(store, res);
                            },
                        ),
                    );
                }
            },
            parseBody: body => ({
                email: body.email,
                requestedEmail: body.requestedEmail,
                gender: body.gender,
                firstName: body.firstName,
                lastName: body.lastName,
                loginRole: parseLoginRole(body.role),
                loginId: body.loginId,
                customerId: body.customerId,
                balanceWarningEnabled: isDefined(body.balanceWarningAmount),
                balanceWarningAmount: body.balanceWarningAmount,
                newsletterStatus: body.newsletterStatus,
                runningTransactionsSummaryEnabled: isDefined(
                    body.runningTransactionsWarningHour,
                ),
                runningTransactionsWarningHour:
                    body.runningTransactionsWarningHour,
                sendEmailNotifications: body.sendEmailNotifications,
                sendSmsNotifications: body.sendSmsNotifications,
                sendRunningTransactionsAutoTerminate:
                    body.sendRunningTransactionsAutoTerminate,
                device: body.device,
                canChooseEnv: body.canChooseEnv,
                pendingTwintTransaction: body.pendingTwintTransaction,
                isCompany: body.isCompany,
                showRecents: body.showRecents,
                numberOfEnabledLprZones: body.numberOfEnabledLprZones,
            }),
        });

        function recheckFcmToken(store: Store, instant = false) {
            clearTimeouts(refreshIds);
            refreshIds.push(
                window.setTimeout(
                    () => {
                        const fcmState = FCMTokenState.get(store);
                        const loginState = generated.get(store);

                        if (
                            loginState.status === RequestStatus.SUCCESS &&
                            fcmState.status !== null &&
                            loginState.data.device &&
                            (fcmState.token !==
                                loginState.data.device.fcmToken ||
                                fcmState.status !==
                                    loginState.data.device.status)
                        ) {
                            apiPost('fcm-token')
                                .send({
                                    uuid: DEVICE_UUID,
                                    fcmToken: fcmState.token,
                                    status: fcmState.status,
                                })
                                .end((err: unknown) => {
                                    if (!err) {
                                        store.update(store => {
                                            generated.refetch(store);
                                            return 'Reset login device';
                                        });
                                    }
                                });
                        }
                        recheckFcmToken(store);
                    },
                    instant ? 0 : 5000,
                ),
            );
        }

        return {
            get: generated.get,
            setResponse: generated.setResponse,
            reset: generated.refetch,
            recheckFcmToken: recheckFcmToken,
            use: generated.use,
        };
    })();

    const parseLoginRole = (str: string): LoginRole => {
        if (str === 'ADMIN') {
            return LoginRole.ADMIN;
        } else {
            return LoginRole.RESTRICTED;
        }
    };
}
