import { css } from '@emotion/css';

import * as superagent from 'superagent';
import * as Flux from 'dg-web-shared/lib/Flux';
import {
    RequestStatus,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { Gender } from '../../account-setup/state/AccountSetupState';
import { Localized } from '../../common/components/Localized';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import * as Text from '../../common/i18n/Text';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import * as SettingsState from '../../common/state/SettingsState';
import { ResponseCode } from '../../common/state/WriteStateSlice';
import * as Fields from '../../utils/Fields';
import { Field } from 'dg-web-shared/model/Iban.ts';
import { ErrorBlock } from '../root/components/ErrorBlock';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import * as LoginsState from './state/LoginsState';
import { ModalInfoBox } from '../../ui/modals/Confirmable';
import { UnderlineStatus } from 'dg-web-shared/tb-ui/inputs/internal/Underline.tsx';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { PasswordStrengthIndicator } from '../current-login/PasswordEditForm';
import { Spinner } from 'dg-web-shared/ui/Spinner';
import { useState } from 'react';
import { GenderSelect } from './GenderSelect.tsx';

export const LoginCreateFormSlideIn = portalSlideIn(LoginCreateForm);

function LoginCreateForm(props: {
    portal: SlideInPortalId;
    onClose: () => void;
}) {
    const { storeState } = Flux.useStore(s => {
        const loginsStateSlice = new LoginsState.StateSlice(s);

        return {
            resetLogins: loginsStateSlice.reset.bind(loginsStateSlice),
        };
    });

    const [form, setForm] = useState(initialFormState);

    const [httpPostState, doHttpPost] = useServerWrite<
        FormState,
        object,
        { code: number }
    >({
        req: () =>
            superagent.post('/ui-api/customer-account/logins-v2').send(form),
    });

    const fields = fieldsFromState(form);

    const isSaveable =
        Fields.noStates(fields.email, fields.password) &&
        !fields.email.isEmpty &&
        !fields.password.isEmpty &&
        !fields.firstName.isEmpty &&
        !fields.lastName.isEmpty;

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: props.onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled: !isSaveable,
                onClick: () => {
                    doHttpPost();
                },
            }}
        >
            {((): JSX.Element => {
                switch (httpPostState.status) {
                    case RequestStatus.NEVER_EXECUTED:
                        return (
                            <FormFields
                                fields={fields}
                                form={form}
                                setForm={setForm}
                            />
                        );

                    case RequestStatus.PENDING:
                        return <Spinner />;

                    case RequestStatus.ERROR:
                        return (
                            <>
                                <FormFields
                                    fields={fields}
                                    form={form}
                                    setForm={setForm}
                                />
                                {httpPostState.data?.code ===
                                ResponseCode.EMAIL_NOT_UNIQUE_EDIT ? (
                                    <EmailAddressOccupiedError />
                                ) : (
                                    <RemoteRequestError />
                                )}
                            </>
                        );

                    case RequestStatus.SUCCESS:
                        return (
                            <>
                                <FormFields
                                    fields={fields}
                                    form={form}
                                    setForm={setForm}
                                />

                                <LoginCreatedMessageBox
                                    onClose={() => {
                                        storeState.resetLogins();
                                        props.onClose();
                                    }}
                                />
                            </>
                        );
                }
            })()}
        </SlideInForm>
    );
}

function FormFields(props: {
    fields: FormFields;
    form: FormState;
    setForm: (v: FormState) => void;
}): JSX.Element {
    const { storeState } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    }));

    const t = CreateTexts.texts[storeState.settings.language];

    return (
        <>
            <TextField
                labelText={
                    <Localized
                        de="E-Mail"
                        fr="E-mail"
                        it="E-mail"
                        en="E-mail"
                    />
                }
                value={props.fields.email.value}
                maxChars={100}
                onChange={email => {
                    props.setForm(Object.assign({}, props.form, { email }));
                }}
                errorText={((): JSX.Element | null => {
                    if (props.fields.email.isEmpty) {
                        return <MandatoryFieldTextLocalized />;
                    }

                    if (!Fields.noStates(props.fields.email)) {
                        return (
                            <Localized
                                de="Diese Email-Adresse ist ungültig"
                                fr="L'adresse e-mail n'est pas valide"
                                it="L'indirizzo e-mail non é valido"
                                en="The e-mail address is not valid"
                            />
                        );
                    }

                    return null;
                })()}
            />

            <GenderSelect
                gender={props.fields.gender.value}
                underlineStatus={UnderlineStatus.none}
                errorText={''}
                styleContext={InputContext.form}
                writeGender={(gender: 'm' | 'f') => {
                    props.setForm(Object.assign({}, props.form, { gender }));
                }}
            />

            <TextField
                labelText={t.FirstName()}
                value={props.fields.firstName.value}
                onChange={firstName => {
                    props.setForm(
                        Object.assign({}, props.form, {
                            firstName,
                        }),
                    );
                }}
                errorText={
                    props.fields.firstName.isEmpty ? (
                        <MandatoryFieldTextLocalized />
                    ) : null
                }
            />

            <TextField
                labelText={t.LastName()}
                value={props.fields.lastName.value}
                onChange={lastName => {
                    props.setForm(
                        Object.assign({}, props.form, {
                            lastName,
                        }),
                    );
                }}
                errorText={
                    props.fields.lastName.isEmpty ? (
                        <MandatoryFieldTextLocalized />
                    ) : null
                }
            />

            <LabeledToggle
                label={t.Restricted()}
                type={ToggleType.checkbox}
                selected={
                    props.fields.role === CurrentLoginState.LoginRole.RESTRICTED
                }
                onClick={() => {
                    props.setForm(
                        Object.assign({}, props.form, {
                            roleId:
                                props.form.roleId ===
                                CurrentLoginState.LoginRole.ADMIN
                                    ? CurrentLoginState.LoginRole.RESTRICTED
                                    : CurrentLoginState.LoginRole.ADMIN,
                        }),
                    );
                }}
            />

            <TextField
                inputType="password"
                labelText={t.Password()}
                value={props.fields.password.value}
                onChange={password => {
                    props.setForm(
                        Object.assign({}, props.form, {
                            password,
                        }),
                    );
                }}
                errorText={
                    props.fields.password.isEmpty ? (
                        <MandatoryFieldTextLocalized />
                    ) : null
                }
            />
            <PasswordStrengthIndicator password={props.fields.password.value} />
        </>
    );
}

function EmailAddressOccupiedError() {
    return (
        <div className={css({ margin: '24px 0' })}>
            <ErrorBlock
                text={
                    <Localized
                        de="Diese E-Mail-Adresse ist bereits in einem Konto registriert"
                        fr="Cette adresse e-mail est déjà enregistrée dans un compte"
                        it="Questo indirizzo e-mail è già registrato in un conto"
                        en="This e-mail address is already registered in an account"
                    />
                }
            />
        </div>
    );
}

function RemoteRequestError() {
    return (
        <div className={css({ margin: '24px 0' })}>
            <ErrorBlock
                text={
                    <Localized
                        de="Es ist ein Fehler aufgetreten"
                        fr="Une erreur est survenue"
                        it="Si è verificato un errore"
                        en="An error has occurred"
                    />
                }
            />
        </div>
    );
}

function MandatoryFieldTextLocalized() {
    return (
        <Localized
            de="Pflichtfeld"
            fr="Ce champ est obligatoire"
            it="Campo obbligatorio"
            en="Mandatory field"
        />
    );
}

function LoginCreatedMessageBox(props: { onClose: () => void }) {
    return (
        <ModalInfoBox
            confirmCallback={props.onClose}
            titleCaption={
                <Localized
                    de="Bestätigungsmail"
                    fr="E-mail de confirmation"
                    it="E-mail di conferma"
                    en="Confirmation e-mail"
                />
            }
        >
            <p>
                <Localized
                    de="Eine E-Mail mit einem Bestätigungslink wurde an den neuen Benutzer gesendet."
                    fr="Un e-mail avec un lien de confirmation a été envoyé au nouvel utilisateur."
                    it="Al nuovo utente è stato inviato un e-mail con un link di conferma."
                    en="An e-mail message with an confirmation link has been sent to the new user."
                />
            </p>
            <p>
                <Localized
                    de="Nach der Bestätigung der E-Mail-Adresse kann sich der Benutzer anmelden."
                    fr="Après la confirmation de l'adresse e-mail, l'utilisateur pourra se connecter."
                    it="Dopo la conferma dell'indirizzo e-mail, l'utente potrà effettuare l'accesso."
                    en="After the e-mail address confirmation, the user will be able to sign in."
                />
            </p>
        </ModalInfoBox>
    );
}

function initialFormState(): FormState {
    return {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        gender: 'm',
        roleId: CurrentLoginState.LoginRole.ADMIN,
    };
}

function fieldsFromState(state: FormState): FormFields {
    return {
        email: new Fields.Email(state.email),
        password: new Fields.Password(state.password),
        firstName: new Fields.GenericField(state.firstName),
        lastName: new Fields.GenericField(state.lastName),
        gender: new GenderField(state.gender),
        role: state.roleId,
    };
}

interface FormState {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    roleId: CurrentLoginState.LoginRole;
}

interface FormFields {
    email: Fields.Email;
    password: Fields.Password;
    firstName: Fields.GenericField;
    lastName: Fields.GenericField;
    gender: GenderField;
    role: CurrentLoginState.LoginRole;
}

class GenderField implements Field<void> {
    constructor(value: Gender) {
        this._value = value;
    }

    private _value: Gender;

    get value(): Gender {
        return this._value;
    }

    get isEmpty(): boolean {
        return this.value !== 'm' && this.value !== 'f';
    }

    get states(): void[] {
        return [];
    }
}

namespace CreateTexts {
    export const texts: Text.Translations<Texts> = {
        de: {
            FirstName: () => GeneralTexts.addressTexts.de.FirstName(),
            LastName: () => GeneralTexts.addressTexts.de.LastName(),
            Gender: () => 'Anrede',
            Male: () => 'Herr',
            Female: () => 'Frau',
            Restricted: () => 'Kein Administrator',
            Password: () => GeneralTexts.texts.de.Password(),
        },

        fr: {
            FirstName: () => GeneralTexts.addressTexts.fr.FirstName(),
            LastName: () => GeneralTexts.addressTexts.fr.LastName(),
            Gender: () => 'Titre',
            Male: () => 'Monsieur',
            Female: () => 'Madame',
            Restricted: () => 'Pas d‘administrateur',
            Password: () => GeneralTexts.texts.fr.Password(),
        },

        it: {
            FirstName: () => GeneralTexts.addressTexts.it.FirstName(),
            LastName: () => GeneralTexts.addressTexts.it.LastName(),
            Gender: () => 'Titolo',
            Male: () => 'Signor',
            Female: () => 'Signora',
            Restricted: () => 'Non amministratore',
            Password: () => GeneralTexts.texts.it.Password(),
        },

        en: {
            FirstName: () => GeneralTexts.addressTexts.en.FirstName(),
            LastName: () => GeneralTexts.addressTexts.en.LastName(),
            Gender: () => 'Gender',
            Male: () => 'Mr',
            Female: () => 'Ms',
            Restricted: () => 'Not administrator',
            Password: () => GeneralTexts.texts.en.Password(),
        },
    };

    export interface Texts {
        FirstName: Text.Translation;
        LastName: Text.Translation;
        Gender: Text.Translation;
        Male: Text.Translation;
        Female: Text.Translation;
        Restricted: Text.Translation;
        Password: Text.Translation;
    }
}
