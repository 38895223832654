import * as Flux from 'dg-web-shared/lib/Flux';
import { getOrElse, isDefinedAndDiffersFrom } from 'dg-web-shared/lib/MaybeV2';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField.ts';
import {
    LabeledToggle,
    ToggleType,
} from 'dg-web-shared/tb-ui/toggle/LabeledToggle.tsx';
import { LabeledText } from '../../ui/typo/LabeledText.tsx';
import { CustomerAccountType } from '../../api/CustomerAccountType';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import * as Fields from '../../utils/Fields';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import { updateMetaData } from './actions/MetaServerActions';
import { AddressFormError } from './AddressFormError';
import * as MetaTexts from './i18n/MetaTexts';
import { AccountMeta } from './state/MetaServerState';
import * as MetaState from './state/MetaState';
import { State, StateSlice } from './state/ShippingAddressState';
import * as GeneralTexts from '../../common/i18n/GeneralTexts';
import { DropdownSlideIn } from '../root/components/DropdownSlideIn';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { countriesAsOptions } from './AddressEditForm';
import * as PaymentTypeState from '../../common/state/PaymentTypeState';
import { AddressCountry } from 'dg-web-shared/dto/AddressCountry.ts';
import { Field } from 'dg-web-shared/model/Iban.ts';
import { SingleSelection } from '../../common/components/SingleSelection.tsx';

export const ShippingAddressEditFormSlideIn = portalSlideIn(
    ShippingAddressEditForm,
);

function ShippingAddressEditForm(props: {
    addressCountries: AddressCountry[];
    accountMeta: AccountMeta;
    onClose: () => void;
    portal: SlideInPortalId;
}) {
    const { storeState, store } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        meta: MetaState.getMetaState(s),
        local: new StateSlice(s).state,
    }));

    const texts = MetaTexts.shippingTexts[storeState.settings.language];
    const form = makeForm(props.accountMeta, storeState.local);
    const [showAddressError, setShowAddressError] = useState(false);

    const isSaveable =
        !storeState.meta.response.pending &&
        formValuesChanged(props.accountMeta, storeState.local) &&
        formIsValid(form);

    function onClose() {
        new MetaState.LocalStateSlice(store).reset();
        setShowAddressError(false);
        store.legacyUpdater(cancelForm);
        props.onClose();
    }

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled: !isSaveable,
                onClick: () => {
                    store.legacyUpdater(state => updateMetaData(state!), {
                        payload: {
                            ...props.accountMeta,
                            shippingAddress: form.likeAddress
                                ? null
                                : {
                                      line1: form.line1.value,
                                      line2: form.line2.value,
                                      zipCode: form.zipCode.value,
                                      city: form.city.value,
                                      countryId: form.countryId.value,
                                      invoiceOnly: form.invoiceOnly,
                                  },
                        },
                        origin: MetaState.Origin.SHIPPING_ADDRESS,
                        onSuccess: onClose,
                    });
                },
            }}
        >
            <LabeledToggle
                type={ToggleType.checkbox}
                label={texts.LikeAddress()}
                selected={form.likeAddress}
                onClick={() => {
                    store.legacyUpdater(
                        state => editForm(state!),
                        (s: State) => {
                            s.likeAddress = !form.likeAddress;
                            return s;
                        },
                    );
                }}
            />

            <InputFields
                addressCountries={props.addressCountries}
                accountMeta={props.accountMeta}
                form={form}
                portal={props.portal}
                showAddressError={showAddressError}
                setShowAddressError={setShowAddressError}
            />
        </SlideInForm>
    );
}

function InputFields(props: {
    portal: SlideInPortalId;
    addressCountries: AddressCountry[];
    accountMeta: AccountMeta;
    form: FormValues;
    showAddressError: boolean;
    setShowAddressError: Dispatch<SetStateAction<boolean>>;
}) {
    const { storeState } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        paymentType: new PaymentTypeState.StateSlice(s).state,
        legacyUpdater: s.legacyUpdater,
        meta: MetaState.getMetaState(s),
    }));

    const [countrySelect, setCountrySelect] = useState(false);

    useEffect(() => {
        if (
            !storeState.meta.response.pending &&
            storeState.meta.source === MetaState.Origin.SHIPPING_ADDRESS &&
            storeState.meta.response.apiError ===
                'COUNTRY_CHANGE_BLOCKED_BY_PAYMENT_METHOD'
        ) {
            props.setShowAddressError(true);
        }
    }, [storeState.meta.response]);

    if (props.form.likeAddress) {
        return null;
    }

    const texts = MetaTexts.shippingTexts[storeState.settings.language];

    const country = props.addressCountries?.find(
        country => country.id === props.form.countryId.value,
    );

    const countryName =
        typeof country === 'undefined'
            ? ''
            : country.name[storeState.settings.language];

    function required(field: Field<void>): string {
        return field.isEmpty
            ? MetaTexts.generalTexts[
                  storeState.settings.language
              ].MandatoryField()
            : '';
    }

    return (
        <>
            <MainAddressReference accountMeta={props.accountMeta} />

            <TextField
                context={InputContext.form}
                value={props.form.line1.value}
                labelText={texts.Street()}
                errorText={required(props.form.line1)}
                onChange={(value: string): void => {
                    storeState.legacyUpdater(
                        state => editForm(state!),
                        (s: State) => {
                            s.line1 = value;
                            return s;
                        },
                    );
                }}
            />

            <TextField
                context={InputContext.form}
                value={props.form.line2.value}
                hintText={texts.StreetAdditionalInfo()}
                onChange={(value: string): void => {
                    storeState.legacyUpdater(
                        state => editForm(state!),
                        (s: State) => {
                            s.line2 = value;
                            return s;
                        },
                    );
                }}
            />

            <TextField
                context={InputContext.form}
                value={props.form.zipCode.value}
                labelText={texts.ZipCode()}
                errorText={required(props.form.zipCode)}
                onChange={(value: string): void => {
                    storeState.legacyUpdater(
                        state => editForm(state!),
                        (s: State) => {
                            s.zipCode = value;
                            return s;
                        },
                    );
                }}
            />

            <TextField
                context={InputContext.form}
                value={props.form.city.value}
                labelText={texts.City()}
                errorText={required(props.form.city)}
                onChange={(value: string): void => {
                    storeState.legacyUpdater(
                        state => editForm(state!),
                        (s: State) => {
                            s.city = value;
                            return s;
                        },
                    );
                }}
            />

            <SingleSelection
                labelText={GeneralTexts.country}
                selection={countryName}
                onClick={() => {
                    setCountrySelect(true);
                }}
                context={InputContext.form}
            />

            <DropdownSlideIn
                portal={props.portal}
                open={countrySelect}
                title={
                    <Localized de="Land" fr="Pays" it="Nazione" en="Country" />
                }
                onClose={() => {
                    setCountrySelect(false);
                }}
                selected={props.form.countryId.value}
                onSelected={(countryId: string) => {
                    setCountrySelect(false);
                    storeState.legacyUpdater(
                        state => editForm(state!),
                        (s: State) => {
                            s.countryId = countryId;
                            return s;
                        },
                    );
                }}
                topOptions={countriesAsOptions(
                    props.addressCountries.filter(c => c.id === 'CH'),
                    storeState.settings.language,
                )}
                options={countriesAsOptions(
                    props.addressCountries.filter(c => c.id !== 'CH'),
                    storeState.settings.language,
                )}
            />

            <LabeledToggle
                type={ToggleType.checkbox}
                label={texts.InvoiceOnly()}
                selected={props.form.invoiceOnly}
                onClick={() => {
                    storeState.legacyUpdater(
                        state => editForm(state!),
                        (s: State): State => {
                            s.invoiceOnly = !props.form.invoiceOnly;
                            return s;
                        },
                    );
                }}
            />

            {props.showAddressError && (
                <AddressFormError
                    onClose={() => props.setShowAddressError(false)}
                    paymentMethod={
                        storeState.paymentType.data.activePaymentMethod
                            .activePaymentType
                    }
                />
            )}
        </>
    );
}

function MainAddressReference(props: { accountMeta: AccountMeta }) {
    const { storeState } = Flux.useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
    }));

    const texts = MetaTexts.addressTexts[storeState.settings.language];

    if (props.accountMeta.customerAccountType === CustomerAccountType.PRIVATE) {
        return (
            <>
                <LabeledText label={texts.FirstName()}>
                    {props.accountMeta.address.firstName}
                </LabeledText>

                <LabeledText label={texts.LastName()}>
                    {props.accountMeta.address.lastName}
                </LabeledText>
            </>
        );
    }

    return (
        <>
            <LabeledText label={texts.Company1()}>
                {props.accountMeta.address.company1}
            </LabeledText>

            {props.accountMeta.address.company1 && (
                <LabeledText label="">
                    {props.accountMeta.address.company2}
                </LabeledText>
            )}
        </>
    );
}

function makeForm(accountMeta: AccountMeta, local: State): FormValues {
    const serverShipping = accountMeta.shippingAddress;

    return {
        likeAddress: getOrElse(
            local.likeAddress,
            noShippingAddress(accountMeta),
        ),
        line1: new Fields.GenericField(
            getOrElse(local.line1, serverShipping.line1),
        ),
        line2: new Fields.GenericField(
            getOrElse(local.line2, serverShipping.line2),
        ),
        zipCode: new Fields.NumberField(
            getOrElse(local.zipCode, serverShipping.zipCode),
        ),
        city: new Fields.GenericField(
            getOrElse(local.city, serverShipping.city),
        ),
        invoiceOnly: getOrElse(local.invoiceOnly, serverShipping.invoiceOnly),
        countryId: new Fields.GenericField(
            getOrElse(local.countryId, serverShipping.countryId || 'CH'),
        ),
    };
}

function formIsValid(form: FormValues): boolean {
    return Fields.noStatesAndNotEmpty(
        form.line1,
        form.zipCode,
        form.city,
        form.countryId,
    );
}

function formValuesChanged(accountMeta: AccountMeta, local: State): boolean {
    const serverShipping = accountMeta.shippingAddress;

    return (
        isDefinedAndDiffersFrom(
            local.likeAddress,
            noShippingAddress(accountMeta),
        ) ||
        isDefinedAndDiffersFrom(local.line1, serverShipping.line1) ||
        isDefinedAndDiffersFrom(local.line2, serverShipping.line2) ||
        isDefinedAndDiffersFrom(local.zipCode, serverShipping.zipCode) ||
        isDefinedAndDiffersFrom(local.city, serverShipping.city) ||
        isDefinedAndDiffersFrom(local.countryId, serverShipping.countryId) ||
        isDefinedAndDiffersFrom(local.invoiceOnly, serverShipping.invoiceOnly)
    );
}

function editForm(w: (s: State) => State): Flux.Write {
    return (store: Flux.Store): void => {
        new StateSlice(store).set(w);
    };
}

function cancelForm(): Flux.Write {
    return (store: Flux.Store): void => {
        new StateSlice(store).reset();
    };
}

function noShippingAddress(accountMeta: AccountMeta): boolean {
    return accountMeta.shippingAddress.city === '';
}

interface FormValues {
    likeAddress: boolean;
    line1: Fields.GenericField;
    line2: Fields.GenericField;
    zipCode: Fields.NumberField;
    city: Fields.GenericField;
    invoiceOnly: boolean;
    countryId: Fields.GenericField;
}
