import { useEffect, useState } from 'react';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import * as ParkCreateTexts from '../../i18n/ParkCreateTexts';
import * as Text from '../../../common/i18n/Text';
import * as Flux from 'dg-web-shared/lib/Flux';
import { useUpdate } from 'dg-web-shared/lib/Flux';
import * as PermitPurchaseState from '../../state/permit/PermitPurchaseState';
import { TypeData } from '../../state/permit/PermitPurchaseState';
import * as EntitySelectionState from '../../state/EntitySelectionState';
import * as LicensePlateState from '../../../common/state/LicensePlateState';
import { CurrentLoginState } from '../../../common/state/CurrentLoginState';
import * as GeneralTexts from '../../../common/i18n/GeneralTexts';
import { PermitType } from '../../state/permit/PermitTypeModels';
import * as ParkCreateActions from '../../actions/ParkCreateActions';
import { CounterPermit } from '../license-plate/InfoBoxes';
import {
    PermitEntitySelection,
    PermitEntitySelectionDropin,
    resetStates,
    setSingleLicensePlate,
} from '../license-plate/PermitEntitySelection';
import { DurationSlidein, FromDateSlidein } from './DateSelectionSlideins';
import { PermitTypeSlideIn } from './PermitTypeDropin';
import { Formatter, Parser } from '../../../utils/Date';
import { LabeledText } from '../../../ui/typo/LabeledText.tsx';
import { PermitConfig } from './PermitConfig';
import { useParkingpayLanguage } from '../../../utils/UseParkingpayLanguage';
import {
    RequestStatus,
    ServerRequestState,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { PermitPayload, usePermitPayloadContext } from './PermitPayloadContext';
import { ParkCreateAddVehicleState } from '../ParkCreate';
import { WithPermitTypes } from './WithPermitTypes';
import { setSentryScope } from '../../../utils/SentryScope.ts';
import { ParkingpayAsyncLoadedSection } from '../../../common/components/ParkingpayAsyncLoadedSection';
import { DateTime } from 'luxon';
import { SlideInPortalId } from '../../../account/root/components/PortalSlidein';
import { ButtonText, InlineQuestionBox } from '../../../ui/modals/Confirmable';
import { Localized } from '../../../common/components/Localized';
import { logAction } from '../../../utils/ActionLog';
import { useServerFetch } from '../../../hooks/ServerStateHooks';
import { SingleSelection } from '../../../common/components/SingleSelection.tsx';

export interface Texts {
    Permit: Text.Translation;
}

export interface PermitTypeState {
    permitTypeData: ServerRequestState<TypeData.Type>;
    refetchPermitTypeData: () => void;
}

interface PermitProps {
    permitTypeId: number;
    language: string;
    typeData: ServerRequestState<PermitPurchaseState.TypeData.Type>;
    onFromDateSlideInOpen: () => void;
    onDurationSlideInOpen: () => void;
    silentlyRefetchTypeData: () => void;
    onEntitySelectionSlideInOpen: () => void;
    onToggleMultipleZones: (arg: { id: number; max: 1 | null }) => void;
    selectedEntityIds: number[];
    licensePlates: LicensePlateState.State;
    permitDateValidation: ServerRequestState<PermitPurchaseState.DateValidation.Data>;
    permitConfig: PermitPayload;
    forcePurchase: boolean;
    update: Flux.Updater;
    setUnableToPurchase: (unableToPurchase: boolean) => void;
}

function Permit(p: PermitProps) {
    const update = useUpdate();
    const [acceptedValidityWarning, setAcceptedValidityWarning] =
        useState(false);
    return (
        <ParkingpayAsyncLoadedSection
            state={p.typeData}
            showSpinnerOnRefetch
            renderSuccess={data => {
                if (data.purchaseState === 'onlyCounter') {
                    return (
                        <CounterPermit
                            operatorName={data.operatorName}
                            operatorContactDetails={data.operatorContactDetails}
                        />
                    );
                }

                if (
                    data.zones.filter(z => z.offstreet).length > 0 &&
                    !acceptedValidityWarning
                ) {
                    return (
                        <PermitNotValidForOngoingTransaction
                            onConfirm={() => {
                                update(store => {
                                    logAction(
                                        store,
                                        'acknowledged-permit-validity-warning',
                                        { permitTypeId: p.permitTypeId },
                                    );
                                    return 'acknowledged-permit-validity-warning';
                                });
                                setAcceptedValidityWarning(true);
                            }}
                        />
                    );
                }

                return (
                    <PermitEntitySelection
                        onEntitySelectionSlideInOpen={
                            p.onEntitySelectionSlideInOpen
                        }
                        language={p.language}
                        typeData={data}
                        refetchTypeData={p.silentlyRefetchTypeData}
                        selectedEntityIds={p.selectedEntityIds}
                        update={p.update}
                        licensePlates={p.licensePlates.data}
                        setUnableToPurchase={p.setUnableToPurchase}
                    >
                        <LabeledText
                            context={InputContext.inverted}
                            label={{
                                de: GeneralTexts.texts.de.Operator(),
                                fr: GeneralTexts.texts.fr.Operator(),
                                it: GeneralTexts.texts.it.Operator(),
                                en: GeneralTexts.texts.en.Operator(),
                            }}
                        >
                            {data.operatorName}
                        </LabeledText>
                        <PermitConfig
                            language={p.language}
                            type={data}
                            entityIdSelection={p.selectedEntityIds}
                            onFromDateSlideInOpen={p.onFromDateSlideInOpen}
                            onDurationSlideInOpen={p.onDurationSlideInOpen}
                            dateValidationState={p.permitDateValidation}
                            permitConfig={p.permitConfig}
                            onToggleMultipleZones={p.onToggleMultipleZones}
                            forcePurchase={p.forcePurchase}
                        />
                    </PermitEntitySelection>
                );
            }}
            portal={SlideInPortalId.PARK_CREATE}
        />
    );
}

function useDateValidation() {
    return useServerWrite<
        {
            permitTypeId: number;
            entityIds: number[];
            fromDate: string | null;
        },
        | PermitPurchaseState.DateValidation.WithDuration
        | PermitPurchaseState.DateValidation.WithFromTo,
        null
    >(({ permitTypeId, entityIds, fromDate }) => ({
        url: `/ui-api/customer-account/permits/purchase/${permitTypeId}/date-validation`,
        body: {
            entityIds,
            fromDate,
        },
    }));
}

function useTypeData(
    context: {
        permitTypeId: number;
    } | null,
) {
    return useServerFetch<
        PermitPurchaseState.TypeData.Type,
        {
            permitTypeId: number;
        },
        null
    >(
        ({ permitTypeId }) => ({
            url: `/ui-api/customer-account/permits/purchase/${permitTypeId}/type-data`,
        }),
        context,
    );
}

function handleSelectionArrayToggle(
    selected: number[],
    selection: number,
    max: number | null,
): number[] {
    max = max || 10000;
    const i = selected.indexOf(selection);
    if (i > -1) {
        selected.splice(i, 1);
    } else {
        if (selected.length >= max) {
            selected.splice(0, 1);
        }
        selected.push(selection);
    }

    return selected.slice();
}

const toggleLicensePlate = (store: Flux.Store, id: number) => {
    const selectedLicensePlates =
        EntitySelectionState.Selection.Permit.get(
            store,
        ).selectedEntityIds.slice();
    const i = selectedLicensePlates.indexOf(id);
    if (i > -1) {
        selectedLicensePlates.splice(i, 1);
    } else {
        selectedLicensePlates.push(id);
    }
    EntitySelectionState.Selection.Permit.stateWrite(store, {
        selectedEntityIds: selectedLicensePlates,
    });
    resetStates(store);
    return 'toggleLicensePlate';
};

export function PermitBridge({
    selectedZipCode,
    selectedZoneId,
}: {
    selectedZipCode: string;
    selectedZoneId: number | null;
}) {
    const language = useParkingpayLanguage();
    const {
        payload: permitPayload,
        setPayload: setPermitPayload,
        resetPermitPayload,
        selectedPermitTypeId,
        setSelectedPermitTypeId,
    } = usePermitPayloadContext();
    const [permitSlideInOpen, setPermitSlideInOpen] = useState(false);
    const [fromDateSlideInOpen, setFromDateSlideInOpen] = useState(false);
    const [durationSlideInOpen, setDurationSlideInOpen] = useState(false);
    const [vehiclesSlideInOpen, setVehiclesSlideInOpen] = useState(false);

    const [dateValidationState, fetchDateValidation] = useDateValidation();

    useEffect(() => {
        return () => {
            resetPermitPayload();
        };
    }, []);

    const {
        storeState: {
            allState,
            selectedEntityIds,
            licensePlates,
            currentLogin,
            infoBlock,
        },
        update,
    } = Flux.useStore(allState => {
        return {
            allState,
            selectedEntityIds:
                EntitySelectionState.Selection.Permit.get(allState)
                    .selectedEntityIds,
            licensePlates: new LicensePlateState.StateSlice(allState).state,
            currentLogin: CurrentLoginState.get(allState),
            infoBlock: PermitPurchaseState.InfoBlock.get(allState),
        };
    });

    useEffect(() => {
        if (selectedPermitTypeId && selectedEntityIds.length > 0) {
            fetchDateValidation({
                permitTypeId: selectedPermitTypeId,
                entityIds: selectedEntityIds,
                fromDate: permitPayload.fromDate
                    ? Formatter.isoString(permitPayload.fromDate)
                    : null,
            });
        }
    }, [selectedPermitTypeId, selectedEntityIds]);
    const [typeDataState, refetchTypeData] = useTypeData(
        selectedPermitTypeId ? { permitTypeId: selectedPermitTypeId } : null,
    );
    const [unableToPurchase, setUnableToPurchase] = useState(false);
    const [preventAutoOpen, setPreventAutoOpen] = useState(false);

    useEffect(() => {
        if (typeDataState.status === RequestStatus.SUCCESS) {
            if (typeDataState.data.entityData.length === 1) {
                const singleEntity = typeDataState.data.entityData[0];
                if (!selectedEntityIds.includes(singleEntity.id)) {
                    update(store => {
                        EntitySelectionState.Selection.Permit.stateWrite(
                            store,
                            {
                                selectedEntityIds:
                                    typeDataState.data.entityData.map(
                                        e => e.id,
                                    ),
                            },
                        );

                        return 'autoselect-vehicle-for-permit-purchase';
                    });
                }
                const canContinue = singleEntity.mode === 'purchase';
                if (
                    !permitPayload.fromDate &&
                    typeDataState.data.purchaseState !== 'onlyCounter' &&
                    canContinue &&
                    !unableToPurchase
                ) {
                    setFromDateSlideInOpen(true);
                }
            } else if (typeDataState.data.purchaseState !== 'onlyCounter') {
                // unselect any entity that is not currently available
                // this prevents badges to stay selected when changing to a LP-based permit
                const filteredEntitiesIds = selectedEntityIds.filter(e =>
                    typeDataState.data.entityData.some(
                        available => available.id === e,
                    ),
                );

                // renewal have vehicle/s already preselected
                // but in case there're none eligible for the permit we still
                // want to open the slide in
                if (filteredEntitiesIds.length > 0) {
                    if (!preventAutoOpen && selectedEntityIds.length === 0) {
                        setVehiclesSlideInOpen(true);
                    }

                    const differentEntities = filteredEntitiesIds.filter(e => {
                        selectedEntityIds.includes(e);
                    });
                    if (differentEntities.length > 0) {
                        update(store => {
                            EntitySelectionState.Selection.Permit.stateWrite(
                                store,
                                {
                                    selectedEntityIds: filteredEntitiesIds,
                                },
                            );

                            return 'cleanup-vehicle-for-permit-purchase';
                        });
                    }
                }
            }
        }
    }, [typeDataState]);

    useServerSuccessEffect(dateValidationState, data => {
        const validatedFromDate = data.validFrom
            ? DateTime.fromISO(data.validFrom)
            : DateTime.now();
        if (
            data?.selectionType === 'duration' &&
            data.allowedDurations?.length === 1
        ) {
            setPermitPayload(p => ({
                ...p,
                fromDate: validatedFromDate,
                duration: data.allowedDurations[0].quantity,
                durationType: data.allowedDurations[0].type,
            }));
        }
        if (data?.selectionType === 'from_to') {
            const toDate = Parser.rangeFromIsoString(data.toRange);
            const parsedEndDate = toDate?.end
                ? DateTime.fromISO(toDate.end.toISOString())
                : null;
            setPermitPayload(p => ({
                ...p,
                fromDate: validatedFromDate,
                toDate: parsedEndDate,
            }));
        } else if (permitPayload.fromDate && !permitPayload.duration) {
            setDurationSlideInOpen(true);
        }
    });

    const dateValidation =
        dateValidationState.status === RequestStatus.SUCCESS
            ? dateValidationState.data
            : null;
    const allowedDurations =
        (dateValidation?.selectionType === 'duration' &&
            dateValidation?.allowedDurations) ||
        undefined;
    const optionTexts = ParkCreateTexts.optionTexts[language];
    const allInPurchaseMode = selectedEntityIds.every(
        id =>
            typeDataState.data?.entityData.find(entity => entity.id === id)
                ?.mode === undefined || 'purchase',
    );

    function updateSelectedPermitType(permitTypeId: number) {
        allState.update(store => {
            ParkCreateActions.onPotentialOptionItemSelect(store);

            return 'select-permit-type';
        });
        setSelectedPermitTypeId(permitTypeId);
        setPermitSlideInOpen(false);
    }

    function updateDuration(id: string) {
        // this comes form an event triggered by elements rendered only if allowedDurations is valid
        const selectedDuration = allowedDurations!.filter(
            d => d.uniqueId === id,
        )[0];
        setPermitPayload(p => ({
            ...p,
            duration: selectedDuration.quantity,
            durationType: selectedDuration.type,
        }));
        setDurationSlideInOpen(false);
    }

    function autoSelectPermitType(permitTypes: PermitType[]) {
        if (permitTypes.length === 1) {
            setSelectedPermitTypeId(permitTypes[0].id);
        } else if (!selectedPermitTypeId) {
            // permit type was not autoselected, open the slidein unless it's renewal
            setPermitSlideInOpen(true);
        }
        if (selectedZoneId) {
            setPermitPayload(p => ({
                ...p,
                selectedZoneIds: [selectedZoneId],
            }));
        }
        allState.update(store => {
            ParkCreateActions.onPotentialOptionItemSelect(store);

            return 'auto-select-permit-type';
        });
    }

    function updateFromMultipleSelection({
        max,
        id,
    }: {
        max: 1 | null;
        id: number;
    }) {
        setPermitPayload(p => ({
            ...p,
            selectedZoneIds: handleSelectionArrayToggle(
                p.selectedZoneIds,
                id,
                max,
            ),
        }));
    }

    function onVehicleSelectionClose() {
        allState.update(store => {
            ParkCreateAddVehicleState.reset(store);
            return 'permit-entity-selection-close';
        });
        setVehiclesSlideInOpen(false);

        if (
            EntitySelectionState.Selection.Permit.get(allState)
                .selectedEntityIds.length > 0 &&
            !permitPayload.fromDate &&
            typeDataState.data?.purchaseState !== 'onlyCounter' &&
            allInPurchaseMode &&
            !unableToPurchase
        ) {
            setFromDateSlideInOpen(true);
        }
    }

    function handleToggleLicensePlate(id: number) {
        if (!typeDataState.data) {
            return;
        }

        if (typeDataState.data.maxEntities > 1) {
            allState.update(store => toggleLicensePlate(store, id));
        } else {
            allState.update(store => setSingleLicensePlate(store, id));
            setVehiclesSlideInOpen(false);
            const selectedEntity = typeDataState.data.entityData.find(
                lp => lp.id === id,
            );
            const canPurchase = selectedEntity?.mode === 'purchase';
            if (!permitPayload.fromDate && canPurchase) {
                setFromDateSlideInOpen(true);
            }
        }
    }

    function handleVehicleAdded() {
        // with single selection => close dropin
        // with multiple selection => only refresh the list
        // NOTE: this relies on the fact that the vehicleAdd is already selecting
        // the newly created entity already.
        if (typeDataState.data?.maxEntities === 1) {
            onVehicleSelectionClose();
            setPreventAutoOpen(true);
        }
    }

    function silentlyRefetchTypeData() {
        refetchTypeData();
        setPreventAutoOpen(true);
    }

    return (
        <WithPermitTypes
            zoneId={selectedZoneId}
            zipCode={selectedZipCode}
            onSuccess={autoSelectPermitType}
            render={permitTypes => {
                const selectedPermitType = permitTypes.filter(t => {
                    return t.id === selectedPermitTypeId;
                })[0];
                setSentryScope('selectedPermit', selectedPermitType);

                return (
                    <div>
                        <PermitTypeSlideIn
                            selectedPermitTypeId={selectedPermitTypeId || null}
                            permitTypes={permitTypes}
                            onSelect={updateSelectedPermitType}
                            open={permitSlideInOpen}
                            onClose={() => setPermitSlideInOpen(false)}
                            language={language}
                        />
                        <PermitEntitySelectionDropin
                            onClose={onVehicleSelectionClose}
                            open={vehiclesSlideInOpen}
                            permitTypeState={{
                                permitTypeData: typeDataState,
                                refetchPermitTypeData: refetchTypeData,
                            }}
                            update={allState.update}
                            currentLogin={currentLogin}
                            selectedLicensePlates={selectedEntityIds}
                            onSelect={handleToggleLicensePlate}
                            onVehicleAddSuccess={handleVehicleAdded}
                        />
                        <FromDateSlidein
                            language={language}
                            selectedDate={permitPayload.fromDate}
                            open={fromDateSlideInOpen}
                            onClose={() => setFromDateSlideInOpen(false)}
                            onSelect={date => {
                                setFromDateSlideInOpen(false);
                                if (
                                    selectedPermitTypeId &&
                                    selectedEntityIds.length > 0
                                ) {
                                    fetchDateValidation({
                                        permitTypeId: selectedPermitTypeId,
                                        entityIds: selectedEntityIds,
                                        fromDate: date.toISO(),
                                    });
                                }
                                setPermitPayload(p => ({
                                    ...p,
                                    fromDate: date,
                                }));
                            }}
                            data={dateValidation}
                        />
                        <DurationSlidein
                            allowedDurations={allowedDurations}
                            duration={permitPayload.duration}
                            durationType={permitPayload.durationType}
                            open={durationSlideInOpen}
                            onClose={() => setDurationSlideInOpen(false)}
                            onSelect={updateDuration}
                            language={language}
                        />
                        <SingleSelection
                            labelText={optionTexts.Permit()}
                            onClick={() => setPermitSlideInOpen(true)}
                            selection={
                                selectedPermitType
                                    ? selectedPermitType.description
                                    : null
                            }
                            context={InputContext.inverted}
                        />
                        {selectedPermitTypeId && (
                            <Permit
                                permitTypeId={selectedPermitTypeId}
                                typeData={typeDataState}
                                silentlyRefetchTypeData={
                                    silentlyRefetchTypeData
                                }
                                language={language}
                                onFromDateSlideInOpen={() =>
                                    setFromDateSlideInOpen(true)
                                }
                                onDurationSlideInOpen={() =>
                                    setDurationSlideInOpen(true)
                                }
                                onEntitySelectionSlideInOpen={() =>
                                    setVehiclesSlideInOpen(true)
                                }
                                selectedEntityIds={selectedEntityIds}
                                licensePlates={licensePlates}
                                permitDateValidation={dateValidationState}
                                permitConfig={permitPayload}
                                setUnableToPurchase={setUnableToPurchase}
                                update={allState.update}
                                onToggleMultipleZones={
                                    updateFromMultipleSelection
                                }
                                forcePurchase={infoBlock.forcePurchase}
                            />
                        )}
                    </div>
                );
            }}
        />
    );
}

function PermitNotValidForOngoingTransaction({
    onConfirm,
}: {
    onConfirm: () => void;
}) {
    return (
        <InlineQuestionBox
            titleCaption={
                <Localized
                    de="Wichtiger Hinweis"
                    fr="Remarque importante"
                    it="Avviso importante"
                    en="Important notice"
                />
            }
            confirmCaption={ButtonText.CONTINUE}
            confirmCallback={onConfirm}
        >
            <Localized
                de={
                    <p>
                        Diese Bewilligung ist erst gültig, wenn Sie{' '}
                        <b>das nächste Mal in das Parking</b> einfahren.
                    </p>
                }
                fr={
                    <p>
                        Cette autorisation ne sera valable que{' '}
                        <b>
                            la prochaine fois que vous entrerez dans le parking
                            avec barrières.
                        </b>
                    </p>
                }
                it={
                    <p>
                        Questa autorizzazione sarà valida unicamente{' '}
                        <b>
                            al momento della prossima entrata nel parcheggio con
                            barriere.
                        </b>
                    </p>
                }
                en={
                    <p>
                        This permit is only valid{' '}
                        <b>
                            the next time you enter the car park with barriers.
                        </b>
                    </p>
                }
            />
            <Localized
                de={
                    <p>
                        Wenn Sie sich jetzt bereits im Parking befinden, wird{' '}
                        <b>die aktuelle Transaktion zum normalen Tarif </b>{' '}
                        berechnet.
                    </p>
                }
                fr={
                    <p>
                        Si vous vous trouvez déjà dans le parking maintenant,{' '}
                        <b>la transaction en cours</b> sera facturée au{' '}
                        <b>tarif normal.</b>
                    </p>
                }
                it={
                    <p>
                        Se adesso siete già dentro il parcheggio,{' '}
                        <b>la transazione attuale</b> verrà conteggiata con{' '}
                        <b>la tariffa normale.</b>
                    </p>
                }
                en={
                    <p>
                        If you are already in the car park now,{' '}
                        <b>the current transaction</b> will be charged at{' '}
                        <b>the normal rate.</b>
                    </p>
                }
            />
        </InlineQuestionBox>
    );
}
