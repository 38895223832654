import React, { MutableRefObject, ReactNode } from 'react';

import * as ReactDOM from 'react-dom';
import { css } from '@emotion/css';
import { CSSInterpolation, CSSObject } from '@emotion/css/create-instance';

type ContainerProps<P> = P & { open: boolean };
type ContainerState<P> = ContainerProps<P> & { shouldRender: boolean };

interface SlideInProps {
    open: boolean;
    onClosed?: () => void;
    children?: ReactNode;
}

export function generatePortalSlideInComponent(
    containerId: string,
    styles: CSSObject | CSSInterpolation,
    slideInAnimationTime: number,
) {
    return function <P extends SlideInProps>(Comp: React.ComponentType<P>) {
        const component: React.ComponentType<ContainerProps<P>> =
            class extends React.Component<
                ContainerProps<P>,
                ContainerState<P>
            > {
                private ongoingCloseTransition: MutableRefObject<
                    boolean | null
                >;

                constructor(props: ContainerProps<P>) {
                    super(props);
                    this.state = {
                        ...props,
                        shouldRender: props.open,
                    };
                    this.ongoingCloseTransition = React.createRef();
                }

                UNSAFE_componentWillReceiveProps(nextProps: ContainerProps<P>) {
                    if (nextProps.open) {
                        this.setState({
                            ...nextProps,
                            shouldRender: true,
                        });
                    }

                    if (
                        !nextProps.open &&
                        this.state.shouldRender &&
                        !this.ongoingCloseTransition.current
                    ) {
                        this.setState({ open: false });
                        this.ongoingCloseTransition.current = true;
                        setTimeout(() => {
                            /*
                        generics and strict set state typing do not work
                        */
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            this.setState<any>({ shouldRender: false });
                            if (this.props.onClosed) {
                                this.props.onClosed();
                            }
                            this.ongoingCloseTransition.current = false;
                        }, slideInAnimationTime);
                    }
                }

                render(): JSX.Element | null {
                    return this.state.shouldRender
                        ? ReactDOM.createPortal(
                              <SelfOpeningSlideIn
                                  {...this.state}
                                  styles={styles}
                              >
                                  <Comp {...this.state} />
                              </SelfOpeningSlideIn>,
                              document.getElementById(
                                  containerId,
                              ) as HTMLElement,
                          )
                        : null;
                }
            };
        component.displayName = 'portalSlideIn';
        return component;
    };
}

interface SelfOpeningSlideInState {
    open: boolean;
}

class SelfOpeningSlideIn extends React.Component<
    SlideInProps & { styles: CSSObject | CSSInterpolation },
    SelfOpeningSlideInState
> {
    state: SelfOpeningSlideInState = { open: false };

    componentDidMount() {
        if (this.props.open) {
            setTimeout(() => {
                this.setState({ open: true });
            }, 0);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: SlideInProps) {
        this.setState({ open: nextProps.open });
    }

    render() {
        return (
            <div
                className={css(this.props.styles, {
                    transform: !this.state.open
                        ? 'translateX(100%)'
                        : 'translateX(0)',
                })}
            >
                {this.props.children}
            </div>
        );
    }
}
