import * as Flux from 'dg-web-shared/lib/Flux';
import * as ParkOptionListState from './park-create/state/ParkOptionListState';
import * as Sentry from '@sentry/browser';

function afterUpdateHandler(store: Flux.Store): void {
    const optionList = ParkOptionListState.Selection.get(store);
    Sentry.getCurrentScope().setContext('selection state', {
        value: {
            selectedCity: optionList.selectedZipCode,
            selectedParkOption: optionList.selectedParkOptionId,
        },
    });
}

export const flux = new Flux.Store(afterUpdateHandler);
