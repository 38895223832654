import { generateState, SliceGenFuns, Store, useStore } from './Flux';
import * as superagent from 'superagent';
import { Response } from './HttpResponse';
import debounce from 'debounce';
import { shallowEqual } from './Object';

export type ServerReadState<D, ED = null> =
    | NeverExecutedRead
    | PendingRead<D>
    | SuccessRead<D>
    | ErrorRead<ED>;

type ShouldFetchProp = { shouldFetch: boolean };
export type NeverExecutedRead = NeverExecutedState & ShouldFetchProp;
export type PendingRead<D> = PendingState<D> & ShouldFetchProp;
export type SuccessRead<D> = SucessState<D> & ShouldFetchProp;
export type ErrorRead<ED> = ErrorState<ED> & ShouldFetchProp;

export type ServerRequestState<D, ED = null> =
    | NeverExecutedState
    | PendingState<D>
    | SucessState<D>
    | ErrorState<ED>;

interface NeverExecutedState {
    readonly request: null;
    readonly status: RequestStatus.NEVER_EXECUTED;
    readonly lastSuccessfulResponse: null;
    data: null;
}

interface PendingState<D> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly request: superagent.Request<any, any>;
    readonly status: RequestStatus.PENDING;
    readonly lastSuccessfulResponse: null | number;
    data: Readonly<D> | null;
}

interface SucessState<D> {
    readonly request: null;
    readonly status: RequestStatus.SUCCESS;
    readonly lastSuccessfulResponse: number;
    data: Readonly<D>;
}

interface ErrorState<ED> {
    readonly request: null;
    readonly status: RequestStatus.ERROR;
    readonly type: RequestErrorType;
    readonly httpStatus: number;
    readonly lastSuccessfulResponse: null | number;
    data: Readonly<ED> | null;
}

/**
 * @deprecated replacedWith useServerWrite
 */
export function generateWriteStateSlice<D, ED = null>(config: {
    key: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody?: (body: any) => D;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseErrorBody?: (body: any) => ED;
}) {
    type State = ServerRequestState<D, ED>;
    const slice = generateState<State>(
        config.key,
        () => {
            return {
                request: null,
                data: null,
                lastSuccessfulResponse: null,
                status: RequestStatus.NEVER_EXECUTED,
            };
        },
        () => {
            return;
        },
    );
    const gen = makeServerSliceFunctions(slice, config);
    return {
        get: gen.get,
        reset: gen.reset,
        setResponse: gen.setResponse,
        use: () => {
            const { storeState, update } = useStore(s => ({
                state: gen.get(s),
            }));
            return [storeState.state, () => update(gen.reset)];
        },
    };
}

/**
 * @deprecated replacedWith useServerFetch
 */
export function generateServerReadStateSlice<D, ED = null>(config: {
    key: string;
    sideEffects: (store: Store, state: ServerReadState<D, ED>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody?: (body: any) => D;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseErrorBody?: (body: any) => ED;
}) {
    type State = ServerReadState<D, ED>;
    const slice = generateState<State>(
        config.key,
        () => {
            return {
                request: null,
                shouldFetch: true,
                data: null,
                lastSuccessfulResponse: null,
                status: RequestStatus.NEVER_EXECUTED,
            };
        },
        config.sideEffects,
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sliceFunctions = makeServerSliceFunctions(slice, config);
    const refetch = (store: Store) => {
        const name = slice.stateWrite(store, { shouldFetch: true });
        slice.get(store); // trigger side-effects
        return name;
    };
    return {
        get: sliceFunctions.get,
        setResponse: (store: Store, res: Response) => {
            slice.stateWrite(store, { shouldFetch: false });
            sliceFunctions.setResponse(store, res);
        },
        refetch,
        use: (): [ServerRequestState<D, ED>, () => void] => {
            const { storeState, update } = useStore(s => ({
                state: sliceFunctions.get(s),
            }));
            return [storeState.state, () => update(sliceFunctions.reset)];
        },
    };
}

/**
 * @deprecated replacedWith useServerFetch
 */
export function generateContextualReadStateSlice<
    C extends object,
    D,
    ED = null,
>(config: {
    key: string;
    onShouldFetch: (
        s: Store,
        context: C,
        setResponse: (store: Store, res: Response) => void,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody?: (body: any) => D;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseErrorBody?: (body: any) => ED;
    debounceTime?: number;
}) {
    type State = ServerReadState<D, ED> & { context: C | null };
    const slice = generateState<State>(
        config.key,
        () => {
            return {
                request: null,
                shouldFetch: false,
                data: null,
                lastSuccessfulResponse: null,
                context: null,
                status: RequestStatus.NEVER_EXECUTED,
            };
        },
        (store, state) => {
            if (state.context && state.shouldFetch) {
                config.onShouldFetch(
                    store,
                    state.context,
                    (store: Store, res: Response) => {
                        slice.stateWrite(store, { shouldFetch: false });
                        sliceFunctions.setResponse(store, res);
                    },
                );
            }
        },
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sliceFunctions = makeServerSliceFunctions(slice, config);
    const refetchStateIfNecessarySync = (
        store: Store,
        state: State,
        context: C | null,
    ) => {
        const shouldFetch =
            context &&
            (!state.context || !shallowEqual(context, state.context));

        const shouldDiscard = !!state.context && !context;

        if (shouldFetch) {
            store.update(store =>
                slice.stateWrite(store, {
                    shouldFetch: true,
                    context,
                }),
            );
        } else if (shouldDiscard) {
            store.update(sliceFunctions.reset);
        }
        return;
    };
    const refetchStateIfNecessary = debounce(
        refetchStateIfNecessarySync,
        config.debounceTime || 10,
    );
    const get = (
        store: Store,
        newContext: C | null,
    ): ServerRequestState<D, ED> => {
        slice.get(store);
        refetchStateIfNecessary(store, slice.get(store), newContext);
        return slice.get(store);
    };
    const refetchSameContext = (store: Store, clearData: boolean): string => {
        slice.stateWrite(store, { shouldFetch: true });
        if (clearData) {
            slice.stateWrite(store, { data: null });
        }
        return config.key + '-refetchSameContext';
    };
    return {
        get,
        refetchSameContext,
        getDataOfCurrentContext__unsafe: (
            store: Store,
        ): ServerRequestState<D, ED> => {
            return slice.get(store);
        },
        use: (context: C | null) => {
            const { storeState, update } = useStore(s => ({
                state: get(s, context),
            }));
            return [
                storeState.state,
                (clearData: boolean) =>
                    update(store => refetchSameContext(store, clearData)),
            ];
        },
    };
}

export enum RequestErrorType {
    SERVER_ERROR = 'serverError',
    CLIENT_ERROR = 'clientError',
    TIMEOUT = 'timeout',
}

interface BodyParsers<D, ED> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody?: (body: any) => D;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseErrorBody?: (body: any) => ED;
}

/**
 * @deprecated
 */
function makeServerSliceFunctions<D, ED>(
    slice: SliceGenFuns<ServerRequestState<D, ED>>,
    parsers: BodyParsers<D, ED>,
): {
    get: (store: Store) => ServerRequestState<D, ED>;
    reset: (store: Store) => string;
    setResponse: (store: Store, res: Response) => void;
} {
    return {
        get: (store: Store): ServerRequestState<D, ED> => {
            return slice.get(store);
        },
        reset(store: Store) {
            const currentState = slice.get(store);
            if (currentState.request) {
                currentState.request.abort();
            }
            return slice.reset(store);
        },
        setResponse: (store: Store, res: Response): void => {
            const currentState = slice.get(store);
            writeResponse(
                currentState,
                res,
                store,
                slice.stateWrite,
                parsers.parseBody,
                parsers.parseErrorBody,
            );
        },
    };
}

function writeResponse<D, ED>(
    currentState: ServerRequestState<D, ED>,
    res: Response,
    store: Store,
    stateWrite: (
        store: Store,
        update: Partial<ServerRequestState<D, ED>>,
    ) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseBody?: (body: any) => D,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseErrorBody?: (body: any) => ED,
) {
    if (res.pending) {
        if (res.pending && currentState.request) {
            currentState.request.abort();
        }
        stateWrite(store, {
            status: RequestStatus.PENDING,
            request: res.request,
        });
    } else if (res.statusCode.cls.success) {
        stateWrite(store, {
            status: RequestStatus.SUCCESS,
            request: null,
            lastSuccessfulResponse: new Date().getTime(),
            data: parseBody ? parseBody(res.body) : res.body || null,
        });
    } else if (res.statusCode.cls.error) {
        stateWrite(store, {
            status: RequestStatus.ERROR,
            request: null,
            type: res.statusCode.timeout
                ? RequestErrorType.TIMEOUT
                : res.statusCode.cls.serverError
                  ? RequestErrorType.SERVER_ERROR
                  : RequestErrorType.CLIENT_ERROR,
            httpStatus: res.statusCode.statusCode || 0,
            data: parseErrorBody ? parseErrorBody(res.body) : res.body || null,
        });
    }
}

export enum RequestStatus {
    NEVER_EXECUTED = 'neverExecuted',
    PENDING = 'pending',
    ERROR = 'error',
    SUCCESS = 'success',
}
