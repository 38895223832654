import {
    localState,
    selectState,
    Store,
    Updater,
} from 'dg-web-shared/lib/Flux';
import { Translation } from 'dg-web-shared/lib/Text';
import { TextField } from 'dg-web-shared/tb-ui/inputs/TextField';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import {
    EditableLicensePlate,
    LicensePlateEditFields,
} from '../../account/vehicles/VehicleLicensePlateEdit';
import {
    UpdateVehicle,
    VehiclesMeta,
} from '../../account/vehicles/VehicleState';
import * as SettingsState from '../../common/state/SettingsState';
import { addLicensePlateTexts } from '../i18n/AddLicensePlateTexts';
import { navigationTextsFor } from '../i18n/NavigationTexts';
import * as AccountSetupState from '../state/AccountSetupState';
import { AccountSetupBlock } from './AccountSetupBlock';
import { Infobox, InfoboxText } from './Infobox';
import * as Validation from './Validation';
import { Validators } from './Validation';
import { logAction } from '../../utils/ActionLog';
import { Localized } from '../../common/components/Localized';

export interface AccountSetupAddLicensePlateTexts {
    addLicensePlateTitle: Translation;
    licensePlateNrLabel: Translation;
    licensePlateCountryLabel: Translation;
    licensePlateTypeLabel: Translation;
    licensePlateTypeDescription: Translation;
    licensePlateTypeMore: Translation;
}

const texts = (s: { settings: SettingsState.State }) =>
    addLicensePlateTexts[s.settings.language];

interface AccountSetupAddLicensePlateState {
    settings: SettingsState.State;
    accountSetupLicensePlate: AccountSetupState.LicensePlate.State;
    accountSetupNavigation: AccountSetupState.Navigation.State;
    accountSetupConfiguration: AccountSetupState.Configuration.State;
    selectedVehicle: UpdateVehicle.State;
    meta: VehiclesMeta.State;
}

const validators = (
    p: AccountSetupAddLicensePlateState,
): Validation.Form<AccountSetupState.LicensePlate.State> => ({
    licensePlateCountryId: [Validators.NotEmpty],
    licensePlateType: [Validators.NotEmpty],
    remark: [Validators.NotEmpty],
    licensePlateNr: [
        Validators.NotEmpty,
        Validators.LicensePlate({
            licensePlateCountryId:
                p.accountSetupLicensePlate.licensePlateCountryId,
            licensePlateNr: p.accountSetupLicensePlate.licensePlateNr,
        }),
    ],
});
const validateForm = (p: AccountSetupAddLicensePlateState) =>
    Validation.validate(
        p.accountSetupLicensePlate,
        validators(p),
        p.settings.language,
    );

interface State {
    showDescriptionErrors: boolean;
}

function goToNextStep(
    update: Updater,
    lp: AccountSetupState.LicensePlate.State,
) {
    update(store =>
        AccountSetupState.Navigation.stateWrite(store, {
            currentStep: 'Summary',
        }),
    );

    update(store => logAction(store, 'accountSetup-licenseplate', lp));
}

export const AccountSetupAddLicensePlate = localState<object, State>(
    {
        showDescriptionErrors: false,
    },
    selectState(
        store => ({
            settings: new SettingsState.StateSlice(store).state,
            accountSetupLicensePlate: AccountSetupState.LicensePlate.get(store),
            accountSetupNavigation: AccountSetupState.Navigation.get(store),
            accountSetupConfiguration:
                AccountSetupState.Configuration.get(store),
            meta: VehiclesMeta.get(store),
            selectedVehicle: UpdateVehicle.get(store),
        }),

        p => {
            return (
                <AccountSetupBlock
                    title={navigationTextsFor(p).headerLabel()}
                    nextLabel={navigationTextsFor(p).next()}
                    onNext={() => {
                        p.setState({ showDescriptionErrors: true });
                        if (!validateForm(p).isValid) {
                            p.update(store =>
                                AccountSetupState.Navigation.stateWrite(store, {
                                    showErrorsAddLicensePlate: true,
                                }),
                            );
                        } else {
                            goToNextStep(p.update, p.accountSetupLicensePlate);
                        }
                    }}
                    previousLabel={navigationTextsFor(p).previous()}
                    onPrevious={() =>
                        p.update(store =>
                            AccountSetupState.Navigation.stateWrite(store, {
                                currentStep: 'Address',
                            }),
                        )
                    }
                >
                    <TextField
                        labelText={texts(p).licensePlateTypeDescription()}
                        value={p.accountSetupLicensePlate.remark}
                        onBlur={() =>
                            p.setState({ showDescriptionErrors: true })
                        }
                        errorText={
                            p.state.showDescriptionErrors &&
                            p.accountSetupLicensePlate.remark.length === 0 ? (
                                <Localized
                                    de="Die Beschreibung darf nicht leer sein."
                                    fr="La description ne peut pas être vide."
                                    it="La descrizione non può essere vuota."
                                    en="The description cannot be empty."
                                />
                            ) : (
                                ''
                            )
                        }
                        onChange={value => {
                            p.update(store =>
                                AccountSetupState.LicensePlate.stateWrite(
                                    store,
                                    {
                                        remark: value,
                                    },
                                ),
                            );
                        }}
                    />
                    <LicensePlateEditFields
                        portal={SlideInPortalId.PARK_CREATE}
                        lang={p.settings.language}
                        licensePlateCountries={
                            p.meta.data.licensePlateCountries
                        }
                        licensePlateTypes={p.meta.data.licensePlateTypes}
                        licensePlate={p.accountSetupLicensePlate}
                        stateWrite={(
                            values: (
                                prevState: EditableLicensePlate,
                            ) => EditableLicensePlate,
                        ) => {
                            p.update(store =>
                                AccountSetupState.LicensePlate.stateWrite(
                                    store,
                                    values(p.accountSetupLicensePlate),
                                ),
                            );
                        }}
                        updateVehicle={p.selectedVehicle}
                        readOnly={false}
                        onClose={() => {
                            p.update((store: Store) => {
                                UpdateVehicle.reset(store);
                                return 'reset-vehicle';
                            });
                        }}
                    />

                    <Infobox>
                        <InfoboxText>
                            {texts(p).licensePlateTypeMore()}
                        </InfoboxText>
                    </Infobox>
                </AccountSetupBlock>
            );
        },
    ),
);
