export const envIsProduction = () =>
    !envIsTest() && !envIsDev() && !envIsLocalDev();
export const envIsTest = () =>
    window.location.host.indexOf('test.digitalparking.ch') > -1;
export const envIsDev = () =>
    window.location.host.match(
        /.*\.dev[1-4]\.digitalparking\.ch.*|.*\.digitalparking\.dev/,
    );
export const envIsLocalDev = () =>
    window.location.host.match(
        /(localhost|0\.0\.0\.0|127\.0\.0\.1|.*(customer|operator|admin|enforcement|parkingabo)\..*\.digitalparking\.dev)/,
    );
export const envHost = () => window.location.host;

export const envName = () => {
    if (envIsProduction()) {
        return 'PROD';
    } else if (envIsTest()) {
        return 'TEST';
    } else {
        return window.location.host.split('.')[1].toUpperCase();
    }
};
