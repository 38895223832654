import { UAParser } from 'ua-parser-js';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { Items } from 'dg-web-shared/lib/LocalStorage';
import { v1 as uuidv1 } from 'uuid';
import { Store } from 'dg-web-shared/lib/Flux';
import { GeolocationState } from '../common/state/GeolocationState';

const agent = new UAParser().getResult();
export const device = {
    ...agent,
    width:
        typeof window !== 'undefined' && typeof window.screen !== 'undefined'
            ? window.screen.width
            : 0,
    height:
        typeof window !== 'undefined' && typeof window.screen !== 'undefined'
            ? window.screen.height
            : 0,
};

function getOrSetUUID() {
    const token = LocalStorage.getStringItem(Items.deviceUuid);
    if (token) {
        return token;
    }
    const uuid = uuidv1();
    LocalStorage.setStringItem(Items.deviceUuid, uuid);
    return uuid;
}

export const DEVICE_UUID = getOrSetUUID();

export const logAction = (
    store: Store | null,
    name: string,
    payload: unknown = null,
): string => {
    try {
        if (
            navigator.sendBeacon &&
            typeof navigator.sendBeacon === 'function'
        ) {
            const geo = store != null ? GeolocationState.get(store) : null;
            const sent = navigator.sendBeacon.bind(navigator)(
                '/ui-api/customer-account/flux-action',
                JSON.stringify({
                    action: name,
                    payload: payload,
                    location: geo != null ? geo.currentLocation : null,
                    device: device,
                    appVersion: SENTRY_RELEASE.id,
                    uuid: DEVICE_UUID,
                }),
            );
            if (!sent) {
                console.error(`flux action '${name}' too large`);
            }
        } else {
            console.error(
                `send beacon not available and no fallback implemented.`,
            );
        }
    } catch (e) {
        console.error(e);
    }
    return 'logged-action';
};
