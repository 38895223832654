import {
    portalSlideIn,
    SlideInPortalId,
} from '../../root/components/PortalSlidein';

import { Localized } from '../../../common/components/Localized';
import { SlideInForm } from '../../root/components/SlideInForm';
import { FieldItemBlock } from '../../root/components/ItemBlock';
import { ClickHandler } from 'dg-web-shared/ui/Clickable';

interface Props {
    caption: React.ReactNode;
    text: React.ReactNode;
    contentElement: JSX.Element | null;
    expanded: boolean;
    toggle: null | (() => void);
    onSave: ClickHandler;
    disabled: boolean;
    pending?: boolean;
}

export function NotificationsBlock(props: Props) {
    return (
        <>
            <FieldItemBlock
                onClick={props.toggle}
                label={props.caption}
                content={props.text}
            />

            <NotificationsContentBlockSlideIn
                portal={SlideInPortalId.USER_ACCOUNT}
                open={props.expanded}
                onClose={() => {
                    if (props.toggle) {
                        props.toggle();
                    }
                }}
                title={props.caption}
                expanded={props.expanded}
                contentElement={props.contentElement}
                toggle={props.toggle}
                onSave={props.onSave}
                disabled={props.disabled}
                pending={props.pending}
            />
        </>
    );
}

export const NotificationsContentBlockSlideIn = portalSlideIn(
    NotificationsContentBlock,
);

interface ReminderContentSlideInProps {
    expanded: boolean;
    contentElement: JSX.Element | null;
    toggle: null | ClickHandler;
    onSave: ClickHandler;
    disabled: boolean;
    pending?: boolean;
}

function NotificationsContentBlock(props: ReminderContentSlideInProps) {
    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: props.toggle,
            }}
            primaryButton={{
                label: props.pending ? (
                    <Localized
                        de="Speichern..."
                        fr="Enregistrer..."
                        it="Salva..."
                        en="Saving..."
                    />
                ) : (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled: props.disabled || props.pending,
                onClick: props.onSave,
            }}
        >
            {props.expanded ? props.contentElement : null}
        </SlideInForm>
    );
}
