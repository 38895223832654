import { useStore } from 'dg-web-shared/lib/Flux';
import { InputContext } from 'dg-web-shared/tb-ui/inputs/InputContext.ts';
import { Localized } from '../../common/components/Localized';
import * as SettingsState from '../../common/state/SettingsState';
import { ErrorBlock } from '../root/components/ErrorBlock';
import {
    portalSlideIn,
    SlideInPortalId,
} from '../root/components/PortalSlidein';
import { SlideInForm } from '../root/components/SlideInForm';
import { updateMetaData } from './actions/MetaServerActions';
import * as MetaTexts from './i18n/MetaTexts';
import { AccountMeta } from './state/MetaServerState';
import * as MetaState from './state/MetaState';
import {
    DropdownSlideIn,
    SlideInDisplay,
} from '../root/components/DropdownSlideIn';

import { useState } from 'react';
import { Language } from 'dg-web-shared/dto/Language.ts';
import { SingleSelection } from '../../common/components/SingleSelection.tsx';

export const CorrespondenceLanguageEditFormSlideIn = portalSlideIn(
    CorrespondenceLanguageEditForm,
);

function CorrespondenceLanguageEditForm(props: {
    languages: Language[];
    accountMeta: AccountMeta;
    onClose: () => void;
    portal: SlideInPortalId;
}) {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        meta: MetaState.getMetaState(s),
        allState: s,
    }));

    const [correspondenceLanguageSelect, setCorrespondenceLanguageSelect] =
        useState(false);

    const [correspondenceLanguageId, setCorrespondenceLanguageId] = useState(
        props.accountMeta.correspondenceLanguageId,
    );

    const texts = MetaTexts.correspondenceTexts[storeState.settings.language];

    const correspondenceLanguage = props.languages?.find(
        correspondenceLanguage =>
            correspondenceLanguage.shortcut === correspondenceLanguageId,
    );

    const correspondenceLanguageName =
        typeof correspondenceLanguage === 'undefined'
            ? ''
            : correspondenceLanguage.name[storeState.settings.language];
    const isSaveable =
        !storeState.meta.response.pending &&
        correspondenceLanguageId !== props.accountMeta.correspondenceLanguageId;

    function onClose() {
        new MetaState.LocalStateSlice(storeState.allState).reset();
        props.onClose();
    }

    return (
        <SlideInForm
            secondaryButton={{
                label: (
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                ),
                onClick: onClose,
            }}
            primaryButton={{
                label: (
                    <Localized
                        de="Speichern"
                        fr="Enregistrer"
                        it="Salva"
                        en="Save"
                    />
                ),
                disabled: !isSaveable,
                onClick: () => {
                    storeState.allState.legacyUpdater(
                        args => updateMetaData(args!),
                        {
                            payload: {
                                ...props.accountMeta,
                                correspondenceLanguageId,
                            },
                            origin: MetaState.Origin.CORRESPONDENCE_LANGUAGE,
                            onSuccess: onClose,
                        },
                    );
                },
            }}
        >
            <SingleSelection
                labelText={texts.CorrespondenceLanguage()}
                selection={correspondenceLanguageName}
                onClick={() => {
                    setCorrespondenceLanguageSelect(true);
                }}
                context={InputContext.form}
            />

            <DropdownSlideIn
                portal={props.portal}
                open={correspondenceLanguageSelect}
                title={
                    <Localized
                        de="Korrespondenzsprache"
                        fr="Langue de correspondance"
                        it="Lingua di corrispondenza"
                        en="Correspondence language"
                    />
                }
                onClose={() => {
                    setCorrespondenceLanguageSelect(false);
                }}
                selected={correspondenceLanguageId}
                onSelected={(correspondenceId: string) => {
                    setCorrespondenceLanguageSelect(false);
                    setCorrespondenceLanguageId(correspondenceId);
                }}
                options={props.languages.map(languages => {
                    return {
                        id: languages.shortcut,
                        display: (
                            <SlideInDisplay
                                firstRowText={
                                    languages.name[storeState.settings.language]
                                }
                            />
                        ),
                    };
                })}
            />
            <RemoteRequestError />
        </SlideInForm>
    );
}

function RemoteRequestError() {
    const { storeState } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        meta: MetaState.getMetaState(s),
    }));

    if (
        storeState.meta.response.statusCode.cls.error &&
        storeState.meta.source === MetaState.Origin.CORRESPONDENCE_LANGUAGE
    ) {
        return (
            <ErrorBlock
                text={MetaTexts.generalTexts[
                    storeState.settings.language
                ].HttpError()}
            />
        );
    }

    return null;
}
