import { css } from '@emotion/css';
import React from 'react';

export enum ColumnPaddingTop {
    Authenticated,
    UnAuthenticated,
}
export enum ColumnPaddingRight {
    TwoColumns,
}

interface ColumnBlockProps {
    isEnabled: boolean;
    hasSpacing: boolean;
    width: number;
    responsiveVariant?: string;
    paddingTop?: ColumnPaddingTop;
    paddingRight?: ColumnPaddingRight;
    children?: React.ReactNode;
}
export class ColumnBlock extends React.Component<ColumnBlockProps> {
    static displayName = 'Column';

    render() {
        if (!this.props.isEnabled) {
            return null;
        }

        let columnPaddingTop = '';
        if (this.props.paddingTop === ColumnPaddingTop.Authenticated) {
            columnPaddingTop = 'authenticated';
        } else if (this.props.paddingTop === ColumnPaddingTop.UnAuthenticated) {
            columnPaddingTop = 'unauthenticated';
        }

        let columnPaddingRight = '';
        if (this.props.paddingRight === ColumnPaddingRight.TwoColumns) {
            columnPaddingRight = '2cols';
        }
        return (
            <div
                className={css([
                    {
                        float: 'left',
                        height: '100%',
                        overflow: 'visible',
                    },
                    this.props.hasSpacing && {
                        marginRight: '32px',
                    },
                    columnPaddingTop === 'unauthenticated' && {
                        paddingTop: '80px',
                    },
                    columnPaddingTop === 'authenticated' && {
                        paddingTop: '56px',
                    },
                    columnPaddingRight === '2cols' && {
                        paddingRight: '80px',
                    },
                ])}
                style={{
                    width: this.props.width,
                }}
            >
                {this.props.children}
            </div>
        );
    }
}
