// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shallowEqual(objA: any, objB: any): boolean {
    if (objA === objB) {
        return true;
    }

    const keysA = Object.keys(objA);

    if (keysA.length !== Object.keys(objB).length) {
        return false;
    }

    for (const key of keysA) {
        if (objA[key] !== objB[key]) {
            return false;
        }
    }
    return true;
}

/**
 * deletes all properties on the given object which are undefined or null
 * @param oIn
 */
export const compact = (
    oIn: Record<string, unknown>,
): Record<string, unknown> => {
    const oOut = { ...oIn };
    Object.keys(oOut).forEach(k => {
        if (oOut[k] === undefined || oOut[k] === null) {
            delete oOut[k];
        }
    });
    return oOut;
};
