import { Localized } from '../../common/components/Localized';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { portalSlideIn } from '../root/components/PortalSlidein';
import * as LoginsState from './state/LoginsState';
import { ActionBlock } from '../root/components/ActionBlock';
import { MenuItemBlock } from '../root/components/ItemBlock';

export const OtherLoginsListSlideIn = portalSlideIn(OtherLoginsList);

function OtherLoginsList(props: {
    otherLogins: LoginsState.GetLoginData[];
    onCreateLogin: () => void;
    onEditLogin: (loginId: number) => void;
}) {
    return (
        <>
            <ActionBlock
                title={
                    <Localized
                        de="Neuen Benutzer erfassen"
                        fr="Saisir nouvel utilisateur"
                        it="Registra nuovo utente"
                        en="Create new user"
                    />
                }
                icon="add"
                onClick={props.onCreateLogin}
            />
            {props.otherLogins.map(l => (
                <MenuItemBlock
                    key={l.id}
                    onClick={props.onEditLogin.bind(null, l.id)}
                    title={
                        l.firstName ? l.firstName + ' ' + l.lastName : l.email
                    }
                    subTitle={<LoginSubheader login={l} />}
                />
            ))}
        </>
    );
}

function LoginSubheader(props: {
    login: LoginsState.GetLoginData;
}): JSX.Element | null {
    if (props.login.deactivated) {
        return (
            <Localized
                de="Inaktiv"
                fr="Pas actif"
                it="Non attivo"
                en="Deactivated"
            />
        );
    }

    if (!props.login.confirmed) {
        return (
            <Localized
                de={`E-Mail nicht bestätigt`}
                fr={`E-mail pas confirmé`}
                it={`E-mail non confermato`}
                en={`Email not confirmed`}
            />
        );
    }

    if (props.login.roleId === CurrentLoginState.LoginRole.ADMIN) {
        return (
            <Localized
                de="Administrator"
                fr="Administrateur"
                it="Amministratore"
                en="Administrator"
            />
        );
    }

    return null;
}
