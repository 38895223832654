import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { Label } from '../../../../dg-web-shared/tb-ui/inputs/internal/Label.tsx';
import {
    InputContext,
    inputContextToColor,
} from '../../../../dg-web-shared/tb-ui/inputs/InputContext.ts';
import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable.tsx';
import {
    Underline,
    UnderlineStatus,
} from '../../../../dg-web-shared/tb-ui/inputs/internal/Underline.tsx';
import { getOrElse, isDefined, Maybe } from 'dg-web-shared/lib/MaybeV2.ts';
import { InputError } from '../../../../dg-web-shared/tb-ui/inputs/internal/InputError.tsx';
import { JsxMessage, Message } from 'dg-web-shared/lib/Localized.ts';
import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import { Icon } from 'dg-web-shared/ui/icons/Icon.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Localized } from './Localized.tsx';

interface SingleSelectionProps {
    onClick: Maybe<ClickHandler>;
    errorText?: string;
    labelText?: string | Message | JsxMessage;
    rightText?: Maybe<string>;
    selection: Maybe<string | JSX.Element | JSX.Element[]>;
    context: InputContext;
    readOnly?: boolean | undefined;
    compactHeight?: number;
    superCompactHeight?: number;
    iconRight?: React.ReactNode;
}

export const SingleSelection = (p: SingleSelectionProps) => {
    const label = p.labelText || '';
    const color = inputContextToColor(p.context);
    return (
        <div
            className={css({
                marginTop: '8px',
                marginBottom: '22px',
                [`@media(max-height: ${p.compactHeight || 0}px)`]: {
                    marginTop: 0,
                    marginBottom: '12px',
                },
                [`@media(max-height: ${p.superCompactHeight || 0}px)`]: {
                    marginTop: 0,
                    marginBottom: '4px',
                },
                position: 'relative',
                color: color,
            })}
        >
            <Label
                context={p.context}
                text={
                    typeof label === 'string' ? label : <Localized {...label} />
                }
                rightText={p.rightText}
                elevated={isDefined(p.selection)}
                hideHeight={p.superCompactHeight}
            />
            <Clickable
                element="div"
                onClick={p.onClick}
                className={css({
                    ...Typo.robotoMedium,
                    fontSize: '16px',
                    minHeight: '20px',
                    lineHeight: '20px',
                    position: 'relative',
                })}
                disabled={p.readOnly || !isDefined(p.onClick)}
            >
                <div
                    className={css({
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        marginRight: '24px',
                    })}
                    data-context={InputContext[p.context]}
                >
                    {getOrElse(p.selection, '')}
                </div>
                <Conditional c={!p.readOnly && isDefined(p.onClick)}>
                    <div
                        className={css({
                            position: 'absolute',
                            bottom: 0,
                            right: p.iconRight === undefined ? '-6px' : '-4px',
                        })}
                        data-context={InputContext[p.context]}
                    >
                        <Icon icon={p.iconRight ?? 'chevronRight'} />
                    </div>
                </Conditional>
            </Clickable>
            {!p.readOnly && (
                <Underline status={UnderlineStatus.blur} context={p.context} />
            )}
            {isDefined(p.errorText) ? (
                <InputError context={p.context} text={p.errorText} />
            ) : null}
        </div>
    );
};

interface CompactSingleSelectionProps {
    onClick: Maybe<ClickHandler>;
    context: InputContext;
    readOnly?: boolean | undefined;
    children: JSX.Element | string;
    veryCompactThresholdHeight?: number;
}

export const CompactSingleSelection = (p: CompactSingleSelectionProps) => {
    const color = inputContextToColor(p.context);
    return (
        <Clickable
            element="div"
            onClick={p.onClick}
            disabled={p.readOnly || !isDefined(p.onClick)}
            className={css({
                position: 'relative',
                borderBottom: `1px solid ${Colors.rgba(color, 0.6)}`,
                padding: '6px 0',
                color,
                ...Typo.robotoMedium,
                fontSize: '16px',
                marginBottom: '20px',
                [`@media(max-height:${p.veryCompactThresholdHeight || 0}px)`]: {
                    marginBottom: '10px',
                },
            })}
        >
            <div
                className={css({
                    paddingRight: '25px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                })}
            >
                {p.children}
            </div>
            <div
                className={css({
                    position: 'absolute',
                    right: -6,
                    bottom: 4,
                })}
            >
                {!p.readOnly && <Icon icon="chevronRight" />}
            </div>
        </Clickable>
    );
};
